import React from 'react'
import {NavLink} from 'reactstrap'

export default function ProfileListOptions({ data, groupIndex, handleMenuClick }) {
    return (
      <div className="settings">
        <div className="group"  >
          {data.map((groupNameData, index) =>
            <div key={index} className={"p" + index}>
              <NavLink
                key={groupNameData.name}
                active={groupIndex === index}
                onClick={e => handleMenuClick(e, index)}
              >
                {groupNameData.showName}
              </NavLink>
            </div>
          )}
        </div>
      </div>
    )
  }