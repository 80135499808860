import React, { useState } from 'react'
import { connect } from 'react-redux'
import { verifyName } from 'Actions/AdminBusinessActions'
import { Button, UncontrolledTooltip } from 'reactstrap'

function InteractiveName ({ name, verifyName }) {
  const { value, verified, objectId } = name

  const [loading, setLoading] = useState(false)

  const toggleVerified = (event) => {
    event.stopPropagation()
    setLoading(true)
    verifyName(objectId, !verified)
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Button
        className={verified ? 'text-primary p-0' : 'text-warning p-0'}
        onClick={toggleVerified}
        color='link'
        disabled={loading}
        id={`name-verification-${objectId}`}
      >{value}</Button>
      <UncontrolledTooltip
        placement='top'
        target={`name-verification-${objectId}`}
      >
        { !verified ? 'Click to mark as verified' : 'Click to undo verification'}
      </UncontrolledTooltip>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  verifyName: (id, verified) => dispatch(verifyName(id, verified))
})

export default connect(null, mapDispatchToProps)(InteractiveName)
