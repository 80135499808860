import React, { useCallback, useState } from 'react'
import { useAsync } from 'react-async'
import { Button } from 'reactstrap'
import { CSSTransition } from 'react-transition-group'
import HideCookieConsent from 'Components/General/HideCookieconsent'
import { QRDeepLink } from 'Modules/DeepLink'
import logo from 'assets/logo_green_tm.png'
import 'css/staticpage.scss'

const loadQrCodeData = ({ code }) => new Promise(resolve => {
  // Make API request to new (not implemented yet) method to get QR code state according to flow
  setTimeout(() => resolve(), 500)
})

export default ({ match }) => {
  console.log(`XXXmatch`, match)
  const code = match.params.code

  const { isLoading } = useAsync(loadQrCodeData, { code })

  const [showQrButton, setShowQrButton] = useState(false)

  const openInApp = useCallback(() => {
    // @todo on desktop implement different logic
    const url = QRDeepLink(code)
    console.log(`XXXurl`, url)
    window.location = url
  }, [code])

  return (
    <>
      <HideCookieConsent />
      <CSSTransition
        in={isLoading}
        timeout={300}
        classNames='brand-background'
        unmountOnExit
        appear
        onExited={() => setShowQrButton(true)}
      >
        <div className='full-screen brand-background '>
          <p className='primary-text'>Loading QR code data...</p>
        </div>
      </CSSTransition>
      { showQrButton && (
        <div className='full-screen'>
          <div className='d-flex flex-column align-items-center'>
            <img src={logo} alt='HeresMyInfo' width='250' />
            <p className='p-3 pt-4 text-secondary text-center'>Install HeresMyInfo to see everything this user is sharing with you.</p>
            <Button color='primary' size='lg' onClick={openInApp}>Open invitation in Mobile App</Button>
          </div>
        </div>
      )}
    </>
  )
}
