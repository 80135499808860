import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { Row } from 'reactstrap'
import Layout from 'Components/Layout'
import Overview from 'Components/Overview/Overview'
import ConnectionsPending from 'Components/Overview/ConnectionsPending'
// import ProfileNowSayMyName from 'Components/Overview/ProfileHowSayMyName'
import Businesses from 'Components/Overview/Businesses'
import PageHeader from '../Components/PageHeader/PageHeader'

const Index = ({ user }) => {
  // const howISayMyName = (user) => {
  //   const propertiesArray = user.profile.properties
  //   const profileArray = Object.keys(propertiesArray).map(i => propertiesArray[i])

  //   function getSayName(item) {
  //     return item.name === 'howisaymyname'
  //   }
  //   const howISayMyNameString = profileArray.filter(getSayName)
  //   console.log('howISayMyNameString', howISayMyNameString)

  //   if (howISayMyNameString.length === 0) {
  //     window.localStorage.setItem('myAudioBlob', null)
  //   } else {
  //     console.log('howISayMyNameString', howISayMyNameString[0].value)
  //     window.localStorage.setItem('myAudioBlob', howISayMyNameString[0].value)
  //   }
  // }

  // howISayMyName(user)

  const givenName = useMemo(
    () => (user.profile.properties.find(_ => _.name === 'givenName') || {}).value,
    [user])

  const title = 'Welcome, ' + givenName + '!'
  const description1 = 'We at HeresMyInfo would like to encourage you to fill out your profile as well as possible.'
  const description2 = 'More fields in your profile give you more flexibility you have to share in your cards.'
  return (
    <Layout>
      <PageHeader title={title} description={description1} />
      <PageHeader description={description2} />
      <Row>
        <Overview />
        {/* <ProfileNowSayMyName /> */}
        <ConnectionsPending />
        <Businesses />
      </Row>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
})

export default connect(mapStateToProps)(Index)
