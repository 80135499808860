import React, { useRef, useEffect } from 'react'
import { FormGroup, FormFeedback, Input, Label } from 'reactstrap'

export default function FAQInputGroup({ title, content, order, setTitle, setContent, setOrder, error, focus }) {
  const inputEl = useRef(null)

  useEffect(() => {
    if (focus && inputEl.current) {
      inputEl.current.focus()
    }
  }, [focus])

  return (
    <FormGroup>
      <Label for='order' className="faqLabel">Order Position: </Label>
      <Input
        type='number'
        name='order'
        placeholder='Enter the faq order position'
        value={order}
        onChange={(e) => setOrder(e.target.value)}
        invalid={!!error}
        innerRef={inputEl}
      />
      <Label for='title' className="faqLabel">Question: </Label>
      <Input
        type='string'
        name='title'
        placeholder='Enter the faq title'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        invalid={!!error}
        innerRef={inputEl}
      />
      <Label for='content' className="faqLabel">Answer: </Label>
      <Input
        type='textarea'
        className="faqContentEdit"
        name='content'
        placeholder='Enter the faq content'
        value={content}
        onChange={(e) => setContent(e.target.value)}
        invalid={!!error}
        innerRef={inputEl}
      />

      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  )
}
