import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Container, Row, Col, Label, Input, Form,
  FormGroup, FormFeedback, Button
} from 'reactstrap'
import { Link, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
// import FacebookLogin from 'react-facebook-login'

import Layout from 'Components/Layout'
import OAuthButton from 'Components/OAuth/OAuthButton'
import { login, cleanError } from 'Actions/AuthActions'
import { setIsOpen } from 'Actions/BusinessActions'
import { validateEmail } from 'Modules/Utils'

export class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      emailIsInvalid: false,
      passwordIsInvalid: false
    }
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleEmailBlur = this.handleEmailBlur.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount = () => {
    this.props.setIsOpen(false)
  }

  componentWillUnmount = () => {
    this.props.cleanError()
  }

  handlePasswordChange = (event) => {
    const value = event.target.value
    this.setState({
      password: value,
      passwordIsInvalid: value.length === 0
    })
  }

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  handleEmailBlur = (event) => {
    // Your handleEmailBlur logic here
  }

  validate() {
    const { email, password } = this.state
    if (!validateEmail(email)) {
      this.setState({ emailIsInvalid: true })
      return false
    }
    if (password.length === 0) {
      this.setState({ passwordIsInvalid: true })
      return false
    }
    return true
  }

  submit(e) {
    e.preventDefault()
    if (!this.validate()) {
      return false
    }
    const { email, password } = this.state
    this.props.login(email, password)
  }

  render() {
    const { email, password } = this.state
    const { error, loading, isAuthenticated } = this.props
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    if (isAuthenticated) return <Redirect to={from} />

    return (
      <Layout>
        <Helmet>
          <title>Log In - HeresMyInfo.com</title>
        </Helmet>
        <Container className='login'>
          {/* <Row className='py-2'>
            <Col>
              <h2>Sign In</h2>
            </Col>
          </Row> */}

          <Form className='form' onSubmit={this.submit} onFocus={() => this.props.setIsOpen(false)}>
            <Row>
              <Col xs='12'>
                <FormGroup>
                  <Label for='email'>Email</Label>
                  <Input
                    type='email'
                    name='email'
                    id='email'
                    placeholder='Enter your email'
                    value={email}
                    invalid={this.state.emailIsInvalid}
                    onChange={this.handleEmailChange}
                    onBlur={this.handleEmailBlur}

                  />
                  <FormFeedback>
                    Please input a correct email.
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs='12'>
                <FormGroup>
                  <Label for='password'>Password</Label>
                  <Link style={{ color: '#00a859', float: 'right' }} to='/resetPassword'>Forgot password?</Link>
                  <Input
                    type='password'
                    name='password'
                    id='password'
                    placeholder='Enter your password'
                    value={password}
                    invalid={this.state.passwordIsInvalid}
                    onChange={this.handlePasswordChange}

                  />
                  <FormFeedback>
                    Please input the password.
                  </FormFeedback>
                </FormGroup>
              </Col>
              {error && (
                <Col xs='12'>
                  <FormGroup>
                    <FormFeedback className='d-block'>{error.message}</FormFeedback>
                  </FormGroup>
                </Col>
              )}
              <Col xs='12'>
                <Button outline type='submit' disabled={loading} className='login-submit'>Submit</Button>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col xs='12'>
              <p className='ride-line'><span>or</span></p>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <OAuthButton provider='facebook' />

              {/* @todo for facebook login we'd better use facebook sdk
               <FacebookLogin
                appId='2746621015398152'
                callback={(resp) => {
                  console.log('FB REST', resp)
                }}
              /> */}
            </Col>
            <Col xs='12'>
              <OAuthButton provider='google' />
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
  loading: state.auth.loading
})

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(login({ email, password })),
  cleanError: () => dispatch(cleanError()),
  setIsOpen: () => dispatch(setIsOpen(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
