import React, { useState, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { Jumbotron, Row, Col, Button } from 'reactstrap'
import { parsePhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import { getName as getCountryName } from 'country-list'
import PhoneForm from '../Personal/Property/PhoneForm'
import { addPrimaryPhone, setProfileProperty, initPhoneVerification, getCountryByIP } from 'Actions/PersonalActions'
import PropertyForm from 'Components/Personal/Property/PropertyForm'
import { contactMetadata } from 'Modules/ContactModule'
import VerifyPersonalPhone from '../Personal/Property/VerifyPersonalPhone'

const PhoneNotVerified = ({ user, addPrimaryPhone, setProfileProperty, initPhoneVerification, phone, getCountryByIP }) => {
  const country = useMemo(() => {
    if (!phone || !phone.parameters[1]) {
      return undefined
    }
    const phoneNumber = parsePhoneNumber(phone.parameters[1].value)
    if (phoneNumber && phoneNumber.country) {
      return getCountryName(phoneNumber.country)
    }
    return undefined
  }, [phone])

  const [showForm, setShowForm] = useState(false)
  const closeForm = useCallback(() => setShowForm(false), [])
  const onSubmit = useCallback((name, localValue) => setProfileProperty(name, localValue, phone.objectId)
    .then(input => {
      phone.parameters = localValue
      initPhoneVerification(phone)
      setShowForm(false)
      return input
    }),
  [setProfileProperty, initPhoneVerification, phone])

  const propertyMeta = useMemo(() => (phone && {
    ...contactMetadata[phone.name],
    name: phone.name
  }), [phone])

  if (!phone) {
    return (
      <Jumbotron>
        <h1>Please enter your phone</h1>
        <PhoneForm label='' onSubmit={addPrimaryPhone} getCountryByIP={getCountryByIP} />
      </Jumbotron>
    )
  }

  return (
    <Jumbotron>
      <h1>Please verify your phone</h1>
      { !showForm && (
        <>
          <p>We've sent you the code to phone number{' '}
            <span className='text-monospace px-1' style={{
              backgroundColor: 'rgba(86, 61, 124, 0.15)',
              border: '1px solid rgba(86,61,124,.15)'
            }}>{formatPhoneNumberIntl(phone.parameters[1].value)}</span>
            {country && <span> in {country}</span>}
          </p>
          <VerifyPersonalPhone phoneObject={phone} />
          <p>Have a mistake in your number?{' '}
            <Button color='link' className='p-0' style={{ marginTop: '-3px' }}
              onClick={() => setShowForm(true)}
            >Click here</Button> to edit
          </p>
      </>
      )}
      { showForm && (
        <Row>
          <Col md='6'>
            <PropertyForm
              initValue={phone.parameters}
              property={propertyMeta}
              onSubmit={onSubmit}
              closeForm={closeForm}
            />
          </Col>
        </Row>
      )}
    </Jumbotron>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
})

const mapDispatchToProps = dispatch => ({
  addPrimaryPhone: phone => dispatch(addPrimaryPhone(phone)),
  setProfileProperty: (name, value, id) => dispatch(setProfileProperty(name, value, id)),
  initPhoneVerification: phone => dispatch(initPhoneVerification(phone)),
  getCountryByIP: () => dispatch(getCountryByIP())
})

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNotVerified)
