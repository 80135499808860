import React, { Component } from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { loadOne } from 'Actions/BusinessActions'
import Layout from 'Components/Layout'
import Loading from 'Components/Loading'
import BusinessProperties from 'Components/Business/Property/BusinessProperties'
import BusinessState from 'Components/Business/BusinessState'

class View extends Component {
  componentDidMount () {
    this.props.loadOne(this.props.match.params.businessId)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.match.params.businessId !== this.props.match.params.businessId) {
      console.log('businessid', this.props.match.params.businessId)
      this.props.loadOne(this.props.match.params.businessId)
    }
  }

  shouldShowLoading () {
    const { loading, error, business } = this.props
    const businessId = this.props.match.params.businessId
    if (!business.objectId && !error) {
      return true
    }
    if (loading && business.objectId !== businessId) {
      return true
    }
    return false
  }

  render () {
    const { error, business } = this.props
    return (
      <Layout>
        <Container>
          {this.shouldShowLoading() ? (<div className='position-relative py-5'><Loading /></div>)
            : error ? (<p>Error while loading. Please try to reload the page</p>) : (
              <>
                <Helmet>
                  <title>Manage business {business.name} - HeresMyInfo.com</title>
                </Helmet>
                <h1>{business.name}</h1>
                <h3>Status: <BusinessState business={business} /></h3>
                <BusinessProperties business={business} />
              </>
            )}
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  business: state.business.business,
  loading: state.business.loading,
  error: state.business.error
})

const mapDispatchToProps = dispatch => ({
  loadOne: (id) => dispatch(loadOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(View)
