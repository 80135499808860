import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'
import Layout from 'Components/Layout'
import DeleteAccount from 'Components/Personal/DeleteAccount/DeleteAccount'

export default () => {
  return (
    <Layout>
      <Helmet>
        <title>Delete My Account - HeresMyInfo.com</title>
      </Helmet>
      <Container>
        <Row>
          <Col>
            <h1>Delete My Account</h1>
            <DeleteAccount />
          </Col>
        </Row>

      </Container>
    </Layout>
  )
}
