import React, { useState } from 'react'
import { Row, Button, Form, FormFeedback } from 'reactstrap'
import Loading from 'Components/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { validateEmail, validateDomain } from 'Modules/Utils'
import { extractErrorMessage } from 'Modules/ParseAPI'
import { FORMAT, parseDate } from 'Components/Personal/Property/propertyForm/DateInput'
import FieldRouter from 'Components/Personal/Property/propertyForm/FieldRouter'
import Photo from '../../Photo/Photo'
import PropertyValue from 'Components/Personal/Property/PropertyValue'

export default function PropertyForm ({ initValue, property, propertyMeta, onSubmit, closeForm, className = '', isThumbnail }) {
  const [value, setValue] = useState(initValue)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})

  console.log('PropertyForm property: ', property)
  console.log('PropertyForm propertyMeta: ', propertyMeta)
  console.log('PropertyForm value: ', value)
  console.log('PropertyForm isThumbnail: ', isThumbnail)

  const validate = (property, value) => {
    console.log('VALIDATE RAN ')
    if (property.name === 'birthday') {
      if (!parseDate(value, FORMAT)) {
        setError({ [property.name]: 'Wrong date' })
        return false
      }
      return true
    }
    if (property.name === 'dates') {
      if (!parseDate(value[4].value, FORMAT)) {
        setError({ [property.name]: { [value[4].name]: 'Wrong date' } })
        return false
      }
      return true
    }
    if (property.name === 'emailAddresses') {
      if (!validateEmail(value[1].value)) {
        setError({ [property.name]: { [value[1].name]: 'Invalid email' } })
        return false
      }
      return true
    }
    if (property.name === 'urlAddresses') {
      // prefix https to domain if it is not specified
      if (!value[1].value.match(/^\w+:\/\//)) {
        value[1].value = `https://${value[1].value}`
      }
      if (!validateDomain(value[1].value)) {
        setError({ [property.name]: { [value[1].name]: 'Invalid URL' } })
        return false
      }
      return true
    }
    if (property.name === 'thumbnailImage') {
      // add current propertyValue image or
      // update to uploaded image in localStorage
      const newImage = JSON.parse(window.localStorage.getItem('thumbnailData'))
      console.log('PROPERTYFORM property: ', property)
      console.log('PROPERTYFORM thumbnail newImage: ', newImage)
      // prefix https to domain if it is not specified
    }
    if (property.name === 'phoneNumbers') {
      if (!value[1].value.trim()) {
        setError({ [property.name]: { [value[1].name]: 'Please enter phone number' } })
        return false
      }
      if (!value[2].value.trim()) {
        setError({ [property.name]: { [value[1].name]: 'Please choose country' } })
        return false
      }
      return true
    }
    if (property.name === 'postalAddresses') {
      const requiredFields = ['street', 'city', 'country']
      for (const i in value) {
        if (requiredFields.includes(value[i].name) && !value[i].value.trim()) {
          setError({ [property.name]: { [value[i].name]: `${value[i].showName} can not be empty` } })
          return false
        }
      }
      return true
    }
    if (typeof value !== 'object') {
      console.log('VALIDATE STRING ', value)
      if (!value || String(value).trim() === '') {
        setError({ [property.name]: `${property.showName} can not be empty` })
        return false
      }
      return true
    }
    return true
  }

  const updateImage = (newImage) => {
    setValue(newImage)
  }

  const submit = () => {
    console.log('SUBMIT: ', property)
    setLoading(true)
    setError({})
    if (!validate(property, value)) {
      setLoading(false)
      return false
    }
    onSubmit(property.name, value)
      .catch(err => {
        // @todo attach error to particular field when possible ('verified property exist' => email or phone)
        setError({ apiError: extractErrorMessage(err) })
        setLoading(false)
      })
  }

  // Show form
  return (
    <Form onSubmit={e => { e.preventDefault(); submit() }}>

      <Row className={className}>
        {loading && <Loading />}

        <div className='flex-grow-1 pl-2'>
          {!isThumbnail &&
            <FieldRouter value={value} setValue={setValue} row={property} error={error[property.name]} />}
          {error.apiError && <FormFeedback className='d-block'>{error.apiError}</FormFeedback>}
        </div>

        {isThumbnail && <PropertyValue propertyMeta={propertyMeta} value={value} />}

        {isThumbnail && <Photo setValue={setValue} updateImage={updateImage} value={value} />}

        <div className='align-self-end mb-2'>
          <Button
            color='link' aria-label='Save'
            disabled={loading}
            onClick={submit}
          >
            <FontAwesomeIcon icon='check' />
          </Button>
          <Button
            color='link' aria-label='Cancel'
            className='text-muted'
            onClick={closeForm}
            disabled={loading}
          >
            <FontAwesomeIcon icon='times' />
          </Button>
        </div>
      </Row>
    </Form>
  )
}
