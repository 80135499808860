import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import QRCard from './QRCard'

const QRList = ({ items }) => {
  return (

    <Container className='qr-List'>
      <Row>
        {items.map(item =>
          <Col  sm="6" md="4" lg="3" xl="2">
            <QRCard item={item} key={item.QRCode} />
          </Col>
        )}
      </Row>
    </Container>

  )
}

export default QRList
