import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { FormGroup, FormFeedback, Input, Button } from 'reactstrap'
import { setQRCircle } from 'Actions/PersonalActions'
import Loading from 'Components/Loading'
import { extractErrorMessage } from 'Modules/ParseAPI'

const QRCircle = ({ item, circles, setQRCircle }) => {
  console.log('QRCircle item', item)
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const inputEl = useRef(null)

  useEffect(() => {
    if (inputEl.current && showForm) {
      inputEl.current.focus()
    }
  }, [showForm])

  const onChange = e => {
    setError(null)
    setLoading(true)
    setQRCircle(e.target.value, item.QRCode)
      .catch(e => setError(extractErrorMessage(e)))
      .finally(() => {
        setLoading(false)
        setShowForm(false)
      })
  }

  if (showForm) {
    return (
      <FormGroup className='m-n1'>
        { loading && <Loading />}
        <Input
          type='select'
          name='property'
          value={item.circle.objectId}
          onChange={onChange}
          innerRef={inputEl}
          onBlur={() => setShowForm(false)}
          invalid={!!error}
        >
          {circles.map(_ =>
            <option key={_.objectId} value={_.objectId}>{_.name}</option>
          ) }
        </Input>
        <FormFeedback>{error}</FormFeedback>
      </FormGroup>
    )
  }
  return (
    <>
      Points to card:
      <Button color='link' className='py-0 px-1' style={{ marginTop: '-4px' }} onClick={() => setShowForm(true)}> {item.name} </Button>
    </>
  )
}

const mapStateToProps = (state) => ({
  circles: state.auth.currentUser.profile.circles
})

const mapDispatchToProps = {
  setQRCircle
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCircle)
