import React from 'react'
import { Col, Form, Button } from 'reactstrap'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { useAsync } from 'react-async'
import PhoneInput from '../../Form/PhoneInput'
import { extractErrorMessage } from 'Modules/ParseAPI'

/**
 * Used on PhoneNotVerified page to prompt user to enter his first phone number
 */
const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    phone: Yup.string()
      .required('Phone number is required.')
  }),

  mapPropsToValues: () => ({
    phone: '',
    countryCode: ''
  }),
  handleSubmit: (payload, { setSubmitting, props, setFieldError }) => {
    const phone = payload.phone.replace(/[^\d]/g, '')
    props.onSubmit(phone)
      .then(user => {
        setSubmitting(false)
      })
      .catch(e => {
        const err = extractErrorMessage(e)
        setFieldError('phone', err)
        setSubmitting(false)
      })
  },
  displayName: 'SetPhoneForm'
})

const PhoneForm = props => {
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    loading,
    label,
    getCountryByIP
  } = props

  const { data: countryCodeByIp } = useAsync(getCountryByIP)
  const defaultCountry = values.countryCode || (countryCodeByIp || {}).countryCode || ''

  return (
    <Form className='form' onSubmit={handleSubmit}>
      <Col>
        <PhoneInput
          id='phone'
          label={label !== null ? label : 'Enter phone'}
          placeholder='Enter phone number'
          error={touched.phone && errors.phone}
          value={values.phone}
          country={defaultCountry}
          onChange={val => setFieldValue('phone', val)}
          onCountryChange={country => setFieldValue('countryCode', country)}
          onBlur={handleBlur}
        />
      </Col>

      <Col className='text-center mt-3'>
        <Button color='primary' type='submit' size='lg' disabled={isSubmitting || loading} className='px-5'>Save</Button>
      </Col>
    </Form>
  )
}

export default formikEnhancer(PhoneForm)
