import React from 'react'

const PageHeader = ({ title, description }) => {
  return (
    <div className='pageHeader'>
      <h2 className='display-5'>{title}</h2>
      <p className='lead'>{description}</p>
    </div>
  )
}

export default PageHeader
