import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Applet from './Applet'
import { load as loadBusinesses } from 'Actions/BusinessActions'

const Overview = ({ user, loadBusinesses, business }) => {
  useEffect(() => {
    loadBusinesses()
  }, [loadBusinesses])

  const title = 'My Businesses'
  return (
    <Applet title={title}>
      {business.list.length ? (
        <>
          <p className='my-0'>I have <strong>{business.list.length}</strong> business{business.list.length ? 'es' : ''} set up. <Link to='/business'>Set up a business</Link></p>
          <ul className='pl-4'>
            {business.list.map(row => (
              <li key={row.objectId}><Link to={`business/${row.objectId}`}>{row.name}</Link></li>
            ))}
          </ul>
        </>
      ) : !business.loading && !business.error && (
        <p>No businesses set up. <Link to='/business'>Set up a business</Link></p>
      )}
    </Applet>
  )
}

const mapStateToProps = state => ({
  user: state.auth.currentUser,
  business: {
    list: state.business.list,
    error: state.business.listError,
    loading: state.business.listLoading
  }
})

const mapDispatchToProps = {
  loadBusinesses
}
export default connect(mapStateToProps, mapDispatchToProps)(Overview)
