import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import Switch from 'react-switch'
import PropertyValue from 'Components/Personal/Property/PropertyValue'
import { setPropertyInCircle } from 'Actions/PersonalActions'
import { extractErrorMessage } from 'Modules/ParseAPI'

function CircleProperty ({ propertyMeta, circle, setPropertyInCircle }) {
  const circleProperties = useMemo(() => {
    return new Map(circle.properties.map(property => ([property.objectId, property])))
  }, [circle])

  const [checked, setChecked] = useState(circleProperties.has(propertyMeta.value.objectId))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    // We optimistically update switcher immediately for better UX, but need to sync value on props update when loaded has been finished
    if (!loading) {
      setChecked(circleProperties.has(propertyMeta.value.objectId))
    }
  }, [circleProperties, propertyMeta.value.objectId, loading])

  const onChange = checked => {
    setError('')
    setChecked(checked)
    setLoading(true)
    setPropertyInCircle(circle.objectId, propertyMeta.value.objectId, checked)
      .catch(err => {
        setError(extractErrorMessage(err))
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className='d-flex w-100 justify-content-between'>
      <div>
        <p className='mb-1'>{propertyMeta.showName}</p>
        {propertyMeta.value && <PropertyValue propertyMeta={propertyMeta} value={propertyMeta.value} />}
      </div>
      <div className='pt-2 text-right'>
        <Switch
          onChange={onChange}
          checked={checked}
          onColor='#00A956'
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
          activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
          height={20}
          width={48}
          className='react-switch'
          disabled={propertyMeta.verifiable && !propertyMeta.value.verified}
        />
        {error && <p className='text-danger'>{error}</p>}
        {propertyMeta.verifiable && !propertyMeta.value.verified && <p className='text-danger'>Not verified</p>}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setPropertyInCircle: (circleId, propertyId, add) => dispatch(setPropertyInCircle(circleId, propertyId, add))
})

export default connect(null, mapDispatchToProps)(CircleProperty)
