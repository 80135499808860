import React, { useMemo } from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4'

import QRCodesDownload from './QRCodesDownload'

const CodesFormatter = ({ value, row }) => (
  <QRCodesDownload codes={value} filename={`qr_codes_${row.label}`} />
)

export default ({ data }) => {
  const qrStat = useMemo(() => {
    if (!data) {
      return []
    }
    let total = 0

    const groupedStat = data.reduce((codes, code) => {
      const label = code.label || '(undefined)'
      if (codes[label]) {
        codes[label].count++
        codes[label].codes.push({ QRCode: code.QRCode, label })
      } else {
        codes[label] = {
          count: 1,
          codes: [{ QRCode: code.QRCode, label }]
        }
      }
      total++
      return codes
    }, {})

    const res = Object.keys(groupedStat)
      .map(label => ({ ...groupedStat[label], label }))
      .sort((a, b) => a.label.localeCompare(b.label))

    res.push({ count: total, label: 'Total', codes: data })

    return res
  }, [data])
  return (
    <div>
      <h3>Number of free QR Codes by labels</h3>

      <Grid
        rows={qrStat}
        columns={[
          { name: 'label', title: 'Label' },
          { name: 'count', title: 'Available' },
          { name: 'codes', title: 'Download' }
        ]}>
        <DataTypeProvider
          formatterComponent={CodesFormatter}
          for={['codes']}
        />
        <Table />
        <TableHeaderRow />
      </Grid>
    </div>
  )
}
