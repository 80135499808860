import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { setProfileProperty } from 'Actions/PersonalActions'
import { extractErrorMessage } from 'Modules/ParseAPI'

function DeleteProperty ({ value, propertyMeta, deleteProfileProperty }) {
  const [modal, changeModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const toggle = () => {
    setError(null)
    setLoading(false)
    changeModal(shown => !shown)
  }

  const deleteProperty = useCallback(() => {
    setError(null)
    setLoading(true)
    deleteProfileProperty(value.objectId)
      .catch(err => {
        setLoading(false)
        setError(extractErrorMessage(err))
      })
  }, [value, deleteProfileProperty])

  return (
    <>
      <Button color='link' className='text-danger font-italic' onClick={toggle}>(Delete)</Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete {propertyMeta.showName}</ModalHeader>
        <ModalBody>
          You are going to delete {propertyMeta.showName}

          {error && <p className='text-danger'>Error: {error}</p>}
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={deleteProperty} disabled={loading}>Confirm delete</Button>{' '}
          <Button color='secondary' onClick={toggle} disabled={loading}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  deleteProfileProperty: (id) => dispatch(setProfileProperty(null, null, id))
})

export default connect(null, mapDispatchToProps)(DeleteProperty)
