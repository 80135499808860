import { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setUserFromToken } from 'Actions/AuthActions'
import { useIsMobile } from 'Hooks'

function getOAuthUrl (provider) {
  let url = `${process.env.REACT_APP_API_URL}/connect/${provider}`
  if (window.location.hostname === 'hmiqr.com') {
    url = url + '/hmiqr'
  }
  return url
}

function openPopup (provider) {
  const url = getOAuthUrl(provider)
  const width = 600
  const height = 600
  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2.5
  const windowFeatures = `toolbar=0,scrollbars=1,status=1,resizable=0,location=1,menuBar=0,width=${width},height=${height},top=${top},left=${left}`

  window.localStorage.removeItem('OAuthUrlAfterRedirect')

  return window.open(
    url,
    'Login to HeresMyInfo',
    windowFeatures
  )
}

function redirectToOAuth (provider) {
  const url = getOAuthUrl(provider)
  window.localStorage.setItem('OAuthUrlAfterRedirect', window.location.pathname)
  window.location.assign(url)
}

const useOAuthPopup = (provider) => {
  const popup = useRef(null)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const [token, setToken] = useState(false)

  const startOauth = useCallback(() => {
    if (popup.current && !popup.current.closed) {
      popup.current.close()
      popup.current = null
    }
    if (!isMobile) {
      popup.current = openPopup(provider)
    }
    if (isMobile || !popup.current) {
      redirectToOAuth(provider)
    }
  }, [provider, isMobile])

  // Close the popup on unmount
  useEffect(() => {
    return () => {
      if (popup.current && !popup.current.closed) {
        popup.current.close()
        popup.current = null
      }
    }
  }, [])

  // Listens to local storage message
  useEffect(() => {
    window.localStorage.removeItem('OAuthParseToken')

    const storageListener = () => {
      if (window.localStorage.getItem('OAuthParseToken')) {
        setToken(window.localStorage.getItem('OAuthParseToken'))
        if (popup.current) {
          popup.current.close()
        }
      }
    }

    window.addEventListener('storage', storageListener)
    return () => {
      window.removeEventListener('storage', storageListener)
    }
  }, [setToken])

  // Listen to token
  useEffect(() => {
    if (token) {
      dispatch(setUserFromToken(token))
        .then(() => {
          if (popup.current) {
            popup.current.close()
          }
        })
    }
  }, [token, dispatch])

  return { startOauth }
}

export default useOAuthPopup
