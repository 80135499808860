import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { uploadLogo } from 'Actions/BusinessActions'
import Loading from '../../Loading'

export class Logo extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      error: null
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange (event) {
    const fileObject = event.target
    const name = fileObject.files[0].name
    const file = fileObject.files[0]
    if (file.size > 1024 * 1024 * 2) {
      this.setState({ error: 'Max file size is 2Mb' })
      return
    }
    this.setState({ loading: true, error: null })
    this.props.uploadLogo(this.props.business.objectId, name, file)
      .catch(err => {
        if (err.message) {
          err = err.message
        }
        if (typeof err !== 'string') {
          err = err.message || 'Upload error'
        }
        this.setState({ error: err })
      })
      .finally(() => {
        this.setState({ loading: false })
        fileObject.value = ''
      })
  }

  render () {
    const { loading, error } = this.state
    const { business } = this.props
    return (
      <div className='position-relative'>
        { loading && (
          <Loading />
        )}
        <p className='mb-1'>Logo</p>
        { business.logo && (
          <img src={business.logo.url} width={200} className='mb-2' alt={business.title} />
        ) }
        <Input type='file' name='file' id='logo'
          onChange={this.onChange}
          disabled={loading}
          accept='image/png,image/jpeg,image/gif'
        />
        { error && <p className='text-danger pt-2'>{error}</p>}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => ({
  uploadLogo: (businessId, name, file) => dispatch(uploadLogo(businessId, name, file))
})

export default connect(mapStateToProps, mapDispatchToProps)(Logo)
