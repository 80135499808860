import React from 'react'
import { Container, Button } from 'reactstrap'
import { Helmet } from 'react-helmet'
import AdminLayout from 'Components/Admin/AdminLayout'
import SingleBusiness from 'Components/Admin/SingleBusiness'

export default function Main (props) {
  return (
    <AdminLayout>
      <Helmet>
        <title>Admin business - HeresMyInfo.com</title>
      </Helmet>
      <Container>
        <h1>Business details page</h1>
        <div className='mb-4 ml-2'>
          <Button color='link' onClick={props.history.goBack}>{'<< '}
            Back
          </Button>
        </div>
        <SingleBusiness businessId={props.match.params.id} />
      </Container>
    </AdminLayout>
  )
}
