import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { validateEmail } from 'Modules/Utils'
import {
  Row, Col, Label, Input, Form,
  FormGroup, FormFeedback, Button
} from 'reactstrap'

import { initUserDeletion } from 'Actions/AuthActions'

export default ({ onFinish }) => {
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleEmailChange = ({ target }) => {
    setEmail(target.value)
  }

  useEffect(() => {
    if (email && !isEmailValid && validateEmail(email)) {
      setIsEmailValid(true)
    }
  }, [email, isEmailValid])

  const handleValidateEmail = () => {
    setIsEmailValid(validateEmail(email))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!validateEmail(email)) {
      return false
    }

    setError(null)
    setLoading(true)

    try {
      const res = await dispatch(initUserDeletion(email))
      onFinish(res)
    } catch (error) {
      setError(error.message)
    }

    setLoading(false)
  }

  return (
    <div>
      <Form className='form' onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <Label for='email'>Enter your email to delete the account.</Label>

              <Input
                type='email'
                name='email'
                id='email'
                placeholder='Enter your email'
                value={email}
                invalid={isEmailValid === false}
                onChange={handleEmailChange}
                onBlur={handleValidateEmail}
              />

              <FormFeedback>
                  Please input a correct email.
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        {error && (
          <Row>
            <Col>
              <FormGroup>
                <FormFeedback className='d-block'>{error}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Button color='danger' type='submit' disabled={loading}>Start delete process</Button>
          </Col>
        </Row>
      </Form>

    </div>
  )
}
