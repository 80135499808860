import React, { useCallback, useState } from 'react'
import { useAsync } from 'react-async'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import HideCookieConsent from 'Components/General/HideCookieconsent'
import { getParseTokenFromOAuth, setUserFromToken } from 'Actions/AuthActions'
import logo from 'assets/logo_green_tm.png'
import 'css/staticpage.scss'

/**
 * 1. Send BE API request /login/from/session
 * 2. Get Parse token, call setUserFromToken or write it to local storage
 * 3. Redirect for mobile
 */
export default ({ match }) => {
  const dispatch = useDispatch()
  const [redirect, setRedirect] = useState(null)

  const login = useCallback(async () => {
    const res = await dispatch(getParseTokenFromOAuth())
    if (!res.token) {
      throw new Error(`Couldn't get authentication token`)
    }
    if (window.localStorage.getItem('OAuthUrlAfterRedirect')) {
      await dispatch(setUserFromToken(res.token))
      setRedirect(window.localStorage.getItem('OAuthUrlAfterRedirect'))
      window.localStorage.removeItem('OAuthUrlAfterRedirect')
    } else {
      window.localStorage.setItem('OAuthParseToken', res.token)
    }
  }, [dispatch])

  const { isLoading, error } = useAsync(login)
  const [result, showResult] = useState(false)

  return (
    <>
      <HideCookieConsent />
      <CSSTransition
        in={isLoading}
        timeout={300}
        classNames='brand-background'
        unmountOnExit
        appear
        onExited={() => showResult(true)}
      >
        <div className='full-screen brand-background '>
          <p className='primary-text'>Logging in...</p>
        </div>
      </CSSTransition>
      { result && (
        <div className='full-screen'>
          { redirect !== null && (
            <Redirect to={redirect} />
          )}
          <div className='d-flex flex-column align-items-center'>
            <img src={logo} alt='HeresMyInfo' width='250' />
            <p className='p-3 pt-4 text-secondary text-center'>
              {!error ? 'Logged in successully!' : 'Error during login. Please try again.' }
            </p>
          </div>
        </div>
      )}
    </>
  )
}
