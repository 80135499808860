import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getAllBusinesses,
  changePage,
  select,
  searchBusinesses,
  deleteBusinesses
} from 'Actions/AdminBusinessActions'
import {
  PagingState,
  SelectionState,
  IntegratedSelection,
  CustomPaging,
  DataTypeProvider
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4'
import Loading from '../Loading'
import { Row, Col } from 'reactstrap'
import BusinessState from './Business/BusinessState'
import InteractiveName from './Business/InteractiveName'
import DeleteResourcesButton from './DeleteResourcesButton'

// Show business id linking to business page
const BusinessLinkFormatter = ({ value: objectId }) => (
  <Link to={`/admin/business/${objectId}`}>{objectId}</Link>
)

// Show field value linking to user page
const UserLinkFormatter = ({ value: { objectId, fullName } }) => (
  <Link to={`/admin/user/${objectId}`}>{fullName}</Link>
)

// Show status field value and control
const StatusFormatter = ({ row }) => {
  return <BusinessState business={row} />
}

// Show domain field with validation highlight
const DomainFormatter = ({ value: domain }) => {
  if (!domain) {
    return <span className='text-danger'>-</span>
  }
  const { parameters, verified } = domain
  return <span
    className={verified ? 'text-primary' : 'text-warning'}
  >{parameters[1].value}</span>
}

// Show name field with validation control
const NameFormatter = ({ value: name }) => {
  if (!name) {
    return <span className='text-danger'>-</span>
  }
  return <InteractiveName name={name} />
}

// Main All Businesses table
export class AllBusinesses extends Component {
  constructor (props) {
    super(props)

    this.state = {
      columns: [
        { name: 'objectId', title: 'Business id' },
        { name: 'name', title: 'Name' },
        {
          name: 'domain',
          title: 'Domain',
          getCellValue: this.domain
        },
        { name: 'owner', title: 'Owner' },
        { name: 'createdAt', title: 'Created At' },
        { name: 'state', title: 'Status' }
      ]
    }

    this.deleteBusinesses = this.deleteBusinesses.bind(this)
  }

  componentDidMount () {
    this.props.getAllBusinesses()
  }

  deleteBusinesses () {
    return this.props.deleteBusinesses(this.props.data.selection)
  }

  domain (business) {
    return business.properties.find(_ => _.name === 'urlAddresses')
  }

  render () {
    const {
      loading,
      rows,
      page: currentPage,
      perPage: pageSize,
      total,
      selection,
      error,
      deleteError
    } = this.props.data
    const {
      changeCurrentPage,
      onSelectionChange
    } = this.props
    const { columns } = this.state
    return (
      <>
        <Row>
          <Col>
            <h1>List of {loading && !total ? '' : total} businesses</h1>
          </Col>
          <Col>
            {/* <Input type='text' name='search' id='filterBusinesses'
              placeholder='Filter businesses...'
              value={search}
              onChange={e => {
                onSelectionChange([])
                searchBusinesses(e.target.value)
              }}
            /> */}
          </Col>
        </Row>
        {error && (
          <Row>
            <Col>
              <p className='text-danger twice'>
                {error && error.message}
              </p>
            </Col>
          </Row>
        )}
        <div className='position-relative'>
          <Grid
            rows={rows}
            columns={columns}
            getRowId={row => row.objectId}
          >
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={changeCurrentPage}
              pageSize={pageSize}
            />
            <SelectionState
              selection={selection}
              onSelectionChange={onSelectionChange}
            />

            <CustomPaging
              totalCount={total}
            />
            <IntegratedSelection />

            <DataTypeProvider
              formatterComponent={BusinessLinkFormatter}
              for={['objectId']}
            />

            <DataTypeProvider
              formatterComponent={StatusFormatter}
              for={['state']}
            />

            <DataTypeProvider
              formatterComponent={UserLinkFormatter}
              for={['owner']}
            />

            <DataTypeProvider
              formatterComponent={DomainFormatter}
              for={['domain']}
            />

            <DataTypeProvider
              formatterComponent={NameFormatter}
              for={['name']}
            />

            <Table />

            <TableHeaderRow />
            <PagingPanel />
            <TableSelection showSelectAll highlightRow selectByRowClick />
          </Grid>
          {loading && <Loading />}
        </div>
        { selection.length > 0 && (
          <Row>
            <Col>
              <p className='twice'>Selected: {selection.length} row{selection.length !== 1 && 's'}</p>
              <DeleteResourcesButton
                resourceName='business accounts'
                rows={selection}
                deleteResources={this.deleteBusinesses}
                loading={loading}
                error={deleteError}
              />
            </Col>
          </Row>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.admin.businesses
})

const mapDispatchToProps = dispatch => ({
  getAllBusinesses: () => dispatch(getAllBusinesses()),
  changeCurrentPage: (currentPage) => dispatch(changePage(currentPage)),
  onSelectionChange: (selection) => dispatch(select(selection)),
  searchBusinesses: (search) => dispatch(searchBusinesses(search)),
  deleteBusinesses: (businessIds) => dispatch(deleteBusinesses(businessIds))
})

export default connect(mapStateToProps, mapDispatchToProps)(AllBusinesses)
