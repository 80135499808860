import React from 'react'
import ImageUploader from '../react-images-upload/index'

class Photo extends React.Component {
  constructor (props) {
    super(props)
    this.state = { pictures: [], newProfilePicture: [] }
    this.onDrop = this.onDrop.bind(this)
  }

  onDrop (picture, pictures) {
    console.log('Photo onDrop RAN ', picture)
    // onDrop runs in index.js using fileReader
    // file is serialized and stored in localStorage as 'thumbnailData'
    const newThumbnails = window.localStorage.getItem('thumbnailData')
    console.log('newThumbnailImage: ', newThumbnails)
    this.props.updateImage(newThumbnails)
    // this.props.setValue(newThumbnails)
    this.setState({
      pictures: this.state.pictures.concat(picture),
      newProfilePicture: newThumbnails
    })
  }

  imageData () {
    return this.state.pictures
  }

  render () {
    this.props.setValue(this.state.newProfilePicture)
    console.log('Photo props: ', this.props)
    console.log('Photo state: ', this.state)
    return (
      <ImageUploader
        withPreview
        singleImage
        withIcon={false}
        buttonText='Choose image'
        onChange={this.onDrop}
        label='Max size: 1Mb Valid formats: .jpg .png .gif'
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={1242880}
      />
    //   maxFileSize={5242880}
    )
  }
}

export default Photo
