import React, { Component } from 'react'
import { connect } from 'react-redux'
import { load } from 'Actions/BusinessActions'
import Loading from '../Loading'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

class BusinessList extends Component {
  componentDidMount () {
    this.props.load()
  }

  render () {
    console.log('BusinessList this.props', this.props)
    const {
      error,
      loading,
      list
    } = this.props
    if (loading) {
      return (
        <div className='position-relative py-5'>
          <Loading />
        </div>)
    }
    if (error) {
      return (
        <p className='twice'>Error while loading the page. Please try to reload.</p>
      )
    }
    return (
      <>
        <Row>
          <Col>
            {!list.length > 0
              ? <Link to='/business/new' className='btn btn-primary mb-3'>Create new business</Link>
              : <>
                <Row>Have more than one business or have employees?</Row>
                <Row style={{ marginBottom: 10 }}>HeresMyInfo can offer some great features!</Row>
                {/* <Link to='/businesspricing' className='btn btn-primary mb-3'>Subscribe</Link> */}
                </>}

            {list.length > 0 && <h3>Existing businesses:</h3>}
            {list.length > 0 &&
              <>
                <Row style={{ marginBottom: 10 }}>Free accounts are limited to one business.</Row>
                <Row>Premium (coming soon) will allow additional businesses!</Row>
              </>}

            {list.map(row => (
              <li key={row.objectId}><Link to={`business/${row.objectId}`}>{row.name}</Link></li>
            ))}
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.business.listError,
  loading: state.business.listLoading,
  list: state.business.list
})

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(load())
})

export default connect(mapStateToProps, mapDispatchToProps)(BusinessList)
