import React, { Component } from 'react'
import Frame from 'react-frame-component'
import Mustache from 'mustache'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import Loading from '../../Loading'
import { PROPERTY_PHONE, PROPERTY_EMAIL, PROPERTY_DOMAIN } from 'Constants/Properties'

class Preview extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      tpl: null,
      content: null,
      iframeStyle: {
        border: '1px solid',
        width: '100%',
        height: '100%'
      }
    }

    this.htmlParserTransform = this.htmlParserTransform.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (!this.state.content || this.props === prevProps) {
      return
    }
    if (prevProps.business.templatePath !== this.props.business.templatePath) {
      this.loadTemplate(this.props.business.templatePath)
    } else {
      const content = this.renderTemplate(this.state.tpl)
      if (content !== this.state.content) {
        this.setState({ content })
      }
    }
  }

  componentDidMount () {
    this.loadTemplate(this.props.business.templatePath)
    window.addEventListener('message', this.messageHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('message', this.messageHandler)
  }

  messageHandler (message) {
    if (message.data.source === 'business-iframe') {
      const { type, value } = message.data.payload
      let url = ''
      switch (type) {
        case 'domain':
          url = value.startsWith('http') ? value : `https://${value}`
          window.open(url, '_blank')
          break
        case 'phone':
          url = value.startsWith('tel:') ? value : `tel:${value}`
          window.open(url)
          break
        case 'email':
          url = value.startsWith('mailto:') ? value : `mailto:${value}`
          window.open(url)
          break
        default:
          break
      }
    }
  }

  loadTemplate (templatePath) {
    if (!templatePath) {
      return
    }
    this.setState({ loading: true })
    fetch(templatePath).then(res => res.text())
      .then(tpl => {
        const content = this.renderTemplate(tpl)
        this.setState({ tpl, content })
      })
      .catch(() => this.setState({ content: '' }))
      .finally(() => this.setState({ loading: false }))
  }

  renderTemplate (tpl) {
    const business = {
      ...this.props.business,
      'getPhoneNumbers': function () {
        return this.business.properties.filter(p => p.verified && p.name === PROPERTY_PHONE)
      },
      'getEmails': function () {
        return this.business.properties.filter(p => p.verified && p.name === PROPERTY_EMAIL)
      },
      'getDomains': function () {
        return this.business.properties.filter(p => p.verified && p.name === PROPERTY_DOMAIN)
      }
    }
    return Mustache.render(tpl, { business })
  }

  /**
   * Wrap template active buttons into handlers (for phone, email, domain)
   */
  htmlParserTransform (node, index) {
    if (node.type === 'tag' && node.attribs['data-type'] && node.attribs['data-value']) {
      const type = node.attribs['data-type']
      const value = node.attribs['data-value']
      return (
        <div
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            window.postMessage({ source: 'business-iframe', payload: { type, value } })
          }}
          key={`uniq-${type}-${value}`}
        >
          {convertNodeToElement(node, index, this.htmlParserTransform)}
        </div>
      )
    }
  }

  render () {
    const { loading, iframeStyle, content } = this.state
    return (
      <div className='position-relative' style={{ width: 375, height: 667 }}>
        { (loading || content === null) ? <Loading />
          : !content ? <p className='text-danger'>Error on creating preview. Please try reloading the page</p>
            : (
              <Frame style={iframeStyle} head={
                <link rel='stylesheet' href='https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css' />
              }>
                {ReactHtmlParser(content, { transform: this.htmlParserTransform }).filter(p => p instanceof Object)}
              </Frame>
            )}
      </div>
    )
  }
}

export default Preview
