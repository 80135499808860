import React from 'react'
import { useContactMetadata } from 'Modules/ContactModule'
import { Col, ListGroup } from 'reactstrap'
import PropertyItem from 'Components/Personal/Property/PropertyItem'

export default function PropertiesList ({ profile, groupIndex }) {
  const profileData = useContactMetadata(profile.properties)
  const groupData = profileData[groupIndex]
  console.log('PropertiesList profileData object: ', profileData)
  // console.log('ProfileList groupIndex', groupIndex)
  console.log('PropertiesList groupData', groupData)
  if (!groupData) return null

  return (

    <Col style={{ border: '1px solid green' }}>
      <div key={groupData.showName}>
        <h4>{groupData.showName}</h4>
        <p>{groupData.description}</p>
        <ListGroup>
          {Object.entries(groupData.properties).map(([key, property]) => (

            <PropertyItem key={key} property={{ ...property, name: key }} />
          ))}
        </ListGroup>
      </div>
    </Col>
  )
}
