import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropertyForm from 'Components/Personal/Property/PropertyForm'
import { updateQRCodeName } from 'Actions/PersonalActions'

const QRName = ({ item, updateQRCodeName }) => {
  const [showForm, setShowForm] = useState(false)
  if (showForm) {
    return <PropertyForm
      initValue={item.name}
      property={{ name: 'name', showName: 'Description' }}
      onSubmit={(_, value) => updateQRCodeName(item.objectId, value).then(() => setShowForm(false))}
      closeForm={() => setShowForm(false)}
      className='justify-content-center'
    />
  }
  return (
    <>
      {item.name} <Button color='link' className='pt-0 px-1'><FontAwesomeIcon icon='pen' onClick={() => setShowForm(true)} /></Button>
    </>
  )
}

const mapDispatchToProps = {
  updateQRCodeName
}

export default connect(null, mapDispatchToProps)(QRName)
