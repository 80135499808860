import React from 'react'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { Link, withRouter } from 'react-router-dom'
import Layout from 'Components/Layout'
import Page404 from 'Pages/Page404'
import Verification from 'Components/Personal/Property/Verification'

function OneProperty ({ property }) {
  if (!property) {
    return <Page404 />
  }
  return (
    <Layout>
      <Helmet>
        <title>Manage Property - HeresMyInfo.com</title>
      </Helmet>
      <Container>
        <h3><Link to='/personal'>{'<<'} Back to Profile data</Link></h3>
        <Verification property={property} />
      </Container>
    </Layout>
  )
}

const mapStateToProps = (state, ownProps) => ({
  property: state.auth.currentUser.profile.properties.find(property => property.objectId === ownProps.match.params.propertyId)
})

export default withRouter(connect(mapStateToProps)(OneProperty))
