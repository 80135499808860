import React from 'react'
import PhoneInput from 'Components/Form/PhoneInput'

export default function PhoneNumberInput ({ value, groupValue, setValue, error }) {
  const codeIndex = Object.entries(groupValue).find(([_, value]) => value.name === 'countryCode')[0]

  const setCountry = country => setValue(state => ({
    ...state,
    [codeIndex]: {
      ...state[codeIndex],
      value: country
    }
  }))

  return <PhoneInput
    placeholder='Enter phone number'
    id='property'
    value={value}
    onChange={value => setValue(value)}
    error={error}
    onCountryChange={country => setCountry(country)}
    focusOnMount
  />
}
