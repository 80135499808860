import React from 'react'
import countryList from 'country-list'
import DateInput from 'Components/Personal/Property/propertyForm/DateInput'
import PhoneNumberInput from 'Components/Personal/Property/propertyForm/PhoneNumberInput'
import SimpleInput from 'Components/Personal/Property/propertyForm/SimpleInput'
import SelectInput from 'Components/Personal/Property/propertyForm/SelectInput'
import ComplexInput from 'Components/Personal/Property/propertyForm/ComplexInput'

export default function FieldRouter ({ value, setValue, row, groupValue, error }) {
  let focus = true
  // Focus street in address
  if (row.parent && row.parent === 'postalAddresses' && row.name !== 'street') {
    focus = false
  }
  // Focus label in phone number
  if (row.parent && row.parent === 'phoneNumbers' && row.name !== 'label') {
    focus = false
  }

  if (row.name === 'birthday' || row.name === 'fullDate') {
    return <DateInput value={value} setValue={setValue} error={error} />
  }
  if (row.name === 'number') {
    return <PhoneNumberInput value={value} groupValue={groupValue} setValue={setValue} error={error} />
  }
  if (row.fields && typeof row.fields === 'object') {
    return <ComplexInput value={value} setValue={setValue} property={row} error={error} />
  }
  if (row.types && row.types.length) {
    return <SelectInput value={value} setValue={setValue} options={row.types} error={error} focus={focus} />
  }

  if (row.name === 'country') {
    return <SelectInput value={value} setValue={setValue} options={countryList.getNames()} error={error} />
  }

  return <SimpleInput value={value} setValue={setValue} error={error} focus={focus} />
}
