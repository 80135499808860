import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  Container, Row
} from 'reactstrap'
import { refetchUser } from 'Actions/AuthActions'
import Layout from 'Components/Layout'
import { SendAgain } from 'Components/SignUp'

export class Verified extends Component {
  componentDidMount () {
    const { isAuthenticated, emailVerified, refetchUser } = this.props
    if (isAuthenticated && !emailVerified) {
      refetchUser()
    }
  }

  render () {
    const { isAuthenticated, emailVerified, loading, error } = this.props

    if (!isAuthenticated) return <Redirect to='/login' />

    return (
      <Layout>
        <Helmet>
          <title>Email verification - HeresMyInfo.com</title>
        </Helmet>
        <Container>
          <Row className='d-block'>
            { emailVerified
              ? (<Redirect to='/' />)
              : loading
                ? (<h1>Loading verification data.</h1>)
                : error
                  ? (<h1>There was an error during verification. Please try reloading the page.</h1>)
                  : (
                  <>
                    <h1>Your email is not verified. Please check your inbox.</h1>
                    <p>Haven't received an email? <SendAgain /></p>
                  </>
                  )
            }
          </Row>
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  emailVerified: state.auth.currentUser && state.auth.currentUser.emailVerified,
  loading: state.auth.loading,
  error: state.auth.error

})

const mapDispatchToProps = dispatch => ({
  refetchUser: () => dispatch(refetchUser())
})

export default connect(mapStateToProps, mapDispatchToProps)(Verified)
