import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input } from 'reactstrap'
import { verifyPhone } from 'Actions/PersonalActions'
import InitPhoneVerification from './InitPhoneVerification'
import Loading from '../../Loading'
import { extractErrorMessage } from 'Modules/ParseAPI'

export class VerifyPersonalPhone extends Component {
  constructor (props) {
    super(props)

    this.state = {
      code: Array(4).fill(''),
      loading: false,
      error: null
    }

    for (let i of [0, 1, 2, 3]) {
      this[`code-${i}`] = React.createRef()
    }

    this.changeCode = this.changeCode.bind(this)
    this.sendCode = this.sendCode.bind(this)
  }

  componentDidMount () {
    if (this['code-0'] && this['code-0'].current) {
      this['code-0'].current.focus()
    }
  }

  changeCode (event) {
    const value = event.target.value.slice(-1)
    const id = parseInt(event.target.id)
    this.setState(({ code }) => {
      const newCode = code.map((oldValue, key) => key === id ? value : oldValue)
      return { code: newCode }
    }, () => {
      if (id < 3) {
        this[`code-${id + 1}`].current.focus()
      }
      if (this.state.code.every(value => value !== '')) {
        this.sendCode(this.state.code.join(''))
      }
    })
  }

  sendCode (code) {
    this.setState({ loading: true })
    this.props.verifyPhone(this.props.phoneObject, code)
      .then(res => {
        // If success don't update the state because the component will be unmounted
      })
      .catch(err => this.setState({
        error: extractErrorMessage(err),
        code: Array(4).fill(''),
        loading: false
      }))
  }

  render () {
    const { code, loading, error } = this.state
    const { phoneObject } = this.props

    return (
      <>
        <Row className='d-block'>
          <Col xs={12}>
            <p>Please enter the code from the SMS</p>
          </Col>
          <Col className='d-flex flex-nowrap'>
            { [0, 1, 2, 3].map(i => (
              <Input id={i}
                type='string'
                value={code[i]}
                onChange={this.changeCode}
                key={i}
                placeholder='0'
                style={{ width: '40px', height: '40px' }}
                innerRef={this[`code-${i}`]}
                disabled={loading}
              />
            )
            )}
            { loading && <div className='position-relative mx-5'><Loading /></div> }
          </Col>
        </Row>
        { error && (
          <Row>
            <Col>
              <p className='text-danger pt-2'>{error}</p>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <p className='pt-2'>Haven't received the code?{' '}
              <InitPhoneVerification phoneObject={phoneObject} />
            </p>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => ({
  verifyPhone: (phoneObject, code) => dispatch(verifyPhone(phoneObject, code))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPersonalPhone)
