const BASE_URL = 'https://hmiqr.com'

const generalDeepLink = link =>
  `https://heresmyinfo.page.link/?link=${link}&ibi=com.heresmyinfo.heresmyinfo&isi=1439939855&apn=com.heresmyinfo.client&efr=1`

export const connectionDeepLink = connectionId =>
  generalDeepLink(`${BASE_URL}/hmiconnections/${connectionId}`)

export const QRDeepLink = code =>
  generalDeepLink(`${BASE_URL}/qr/${code}`)

export const inviteDeepLink = inviteId =>
  generalDeepLink(`${BASE_URL}/invite/${inviteId}`)

export const lastLink = () =>
  generalDeepLink(`${BASE_URL}/last`) + `&ofl=${BASE_URL}`
