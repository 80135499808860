import React, { PureComponent } from 'react'
import Layout from 'Components/Layout'
import { Container, Row } from 'reactstrap'
import BusinessList from 'Components/Business/BusinessList'

export default class Main extends PureComponent {
  render () {
    return (
      <Layout>
        <Container>
          <Row>
            <h2 style={{ fontSize: '1.4rem', marginTop: 30, marginBottom: 20 }}>Your business records</h2>
          </Row>
          <Row>
            <h3 style={{ fontSize: '1.4rem' }}>A domain name is required to create a business listing. <br /> <a style={{ marginBottom: 20, color: 'green', fontSize: '1rem' }} href='/learn'>What's a domain name?</a></h3>
          </Row>
          <Row>
            <h4 style={{ fontSize: '1rem' }}>Please contact us about including your listing in the HeresMyInfo mobile application business listing. <br /> <a style={{ marginBottom: 40, color: 'green', fontSize: '1rem' }} href='/support'>Contact HeresMyInfo business representative</a></h4>
          </Row>
          <BusinessList />
        </Container>
      </Layout>
    )
  }
}
