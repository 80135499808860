import { Cloud } from 'Modules/ParseModule'
import { triggerLoading, refetchUser } from './AuthActions'
import { PROPERTY_PHONE } from 'Constants/Properties'

export const QR_CODE_LIST = 'QR_CODE_LIST'
export const QR_CODE_UPDATE = 'QR_CODE_UPDATE'
export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'

export const DELETE_CIRCLE_START = 'DELETE_CIRCLE_START'
export const DELETE_CIRCLE_SUCCESS = 'DELETE_CIRCLE_SUCCESS'
export const DELETE_CIRCLE_FAILURE = 'DELETE_CIRCLE_FAILURE'

export const CIRCLE_QUERY_START = 'CIRCLE_QUERY_START'
export const CIRCLE_QUERY_SUCCESS = 'CIRCLE_QUERY_SUCCESS'
export const CIRCLE_QUERY_FAILURE = 'CIRCLE_QUERY_FAILURE'

export const addPrimaryPhone = (phone) => dispatch => {
  return Cloud.run('addBasicPropsToProfile', { phone })
    .then((profile) => {
      // I could have updated profile in place, but Parse doesn't return real properties values here
      return dispatch(refetchUser())
    }).then(user => {
      const phone = user.toJSON().profile.properties.find(p => p.name === PROPERTY_PHONE)
      return dispatch(initPhoneVerification(phone))
    })
}

export const initPhoneVerification = (phoneObject) => (dispatch, getState) => {
  const { auth: { currentUser: user } } = getState()
  const givenName = user.profile.properties.find(p => p.name === 'givenName')
  const familyName = user.profile.properties.find(p => p.name === 'familyName')
  return Cloud.run('sendVerifyBySMS', {
    username: user.username,
    givenName: givenName ? givenName.value : '',
    familyName: familyName ? familyName.value : '',
    phone: phoneObject.parameters[1].value,
    countryCode: phoneObject.parameters[2].value
  })
}

export const testIt = () => console.log('RRRRRRRRR')

export const verifyPhone = (phoneObject, code) => (dispatch, getState) => {
  const { auth: { currentUser: user } } = getState()
  return Cloud.run('verifyInitialSMSCode', {
    username: user.username,
    phone: phoneObject.parameters[1].value,
    countryCode: phoneObject.parameters[2].value,
    code
  })
    .then((user) => {
      return dispatch(refetchUser())
    })
}

export const setProfileProperty = (name, value, id) => (dispatch) => {
  return Cloud.run('addRemoveUpdatePropertyFromProfile', {
    name,
    value,
    id
  })
    .then((user) => {
      return dispatch(refetchUser())
    })
}

export const setPropertyInCircle = (circleId, propId, add) => (dispatch) => {
  dispatch(triggerLoading())
  return Cloud.run('addRemovePropertyToCircle', {
    circleId,
    propId,
    add
  })
    .then((circles) => {
      return dispatch(refetchUser())
    })
}

export const loadQRCodes = () => dispatch => {
  return Cloud.run('getQRCodes')
    .then(data => {
      console.log(data)
      const qrCodes = data.map(code => code.toJSON())
      console.log(qrCodes)
      dispatch({ type: QR_CODE_LIST, qrCodes })
      return qrCodes
    })
}

/**
 * Change QR code name
 * @param {string} id - object Id of qr code (don't mess with QRCode)
 * @param {string} name - new name
 */
export const updateQRCodeName = (id, name) => dispatch => {
  return Cloud.run('editQRName', { id, name })
    .then(code => {
      const qrCode = code.toJSON()
      dispatch({ type: QR_CODE_UPDATE, qrCode })
      return qrCode
    })
}

/**
 * Point QR code to circle
 * @param {string} circleId - object Id of circle
 * @param {string} QRCode - QR Code (not id)
 */
export const setQRCircle = (circleId, QRCode) => dispatch => {
  return Cloud.run('setQRCircle', { circleId, QRCode })
    .then(data => {
      const qrCodes = data.map(code => code.toJSON())
      dispatch({ type: QR_CODE_LIST, qrCodes })
      return qrCodes
    })
}

const getUserSuccess = (user, connections, names) => ({
  type: GET_USER_SUCCESS,
  user,
  connections,
  names
})

export const getSingleUser = (userId) => dispatch => {
  dispatch({ type: GET_USER_REQUEST })
  console.log('userId', userId)
  let user
  return Cloud.run('getUserWithProperties', { user: userId })
    .then(res => {
      user = res.user.toJSON()
      console.log('user', user)
      return Cloud.run('getConnectionsForUsers', { users: [userId], properties: ['fn'] })
    })
    .then(res => {
      const connections = res.connections.map(c => c.toJSON())
      const names = res.properties.map(c => c.toJSON())
      dispatch(getUserSuccess(user, connections, names))
    })
    .catch(error => dispatch({ type: GET_USER_FAILURE, error }))
}

export const getConnections = () => dispatch =>
  Cloud.run('getConnectionsForUser')
    .then(connections => connections.map(_ => _.toJSON ? _.toJSON() : _))

export const getIncomingInvites = () => dispatch =>
  Cloud.run('getIncomingInvitesForUser')

export const getOutgoingInvites = () => dispatch =>
  Cloud.run('getOutGoingInvitesForUser')

export const verifyEmail = (messageId, token) => (dispatch, getState) =>
  Cloud.run('setPropertyVerified', { messageId, token })
    .then(res => {
      if (getState().auth.isAuthenticated) {
        return dispatch(refetchUser())
        // @todo send sms code here?
      } else {
        return res
      }
    })

export function deleteCard (userId, circleId) {
  // console.log('deleteCard currentCircleConnsLength: ', currentCircleConnsLength)
  // console.log('userId', userId)
  // console.log('circleId', circleId)
  return (dispatch, getState) => {
    dispatch(deleteCardRequest())
    return Cloud.run('deleteCard', { userId, circleId })
      .then(({ circleId, connections }) => {
        console.log('circleId', circleId)
        console.log('connections', connections)
        if (connections.length === 0) {
          dispatch(deleteCardSuccess(circleId))
        } else {
          const them = connections.length > 1 ? 'everyone' : 'them'
          const msg = 'This will disconnect ' + connections.length + ' user'
          const msgpt2 = ' Please remove ' + them + ' from this card before deleting.'
          console.log(msg + (connections.length > 1 ? 's.' : '.') + msgpt2)
          dispatch(deleteCardFailure(msg + (connections.length > 1 ? 's.' : '.') + msgpt2))
        }
        return circleId
      })
      .then(() => {
        dispatch(queryCircles())
      })
      .catch((error) => {
        console.error('deleteCard Failure: ', error)
        dispatch(deleteCardFailure(error))
      })
  }
}

export function queryCircles () {
  return dispatch => {
    dispatch(queryCirclesStart())
    return Cloud.run('getCirclesWithConnections')
      .then(data => {
        // console.log('queryCircles data', data)
        const connections = {}
        const circles = data.circles.map(circle => circle.toJSON ? circle.toJSON() : circle)
        Object.entries(data.connections).forEach(conn => {
          if (conn[1].length && conn[1].length !== 0) {
            connections[conn[0]] = conn[1].map(co => co.toJSON ? co.toJSON() : co)
          }
        })
        dispatch(queryCirclesSuccess(circles, connections))
      })
      .catch(error => dispatch(queryCirclesFailure(error)))
  }
}

function queryCirclesStart () {
  return {
    type: CIRCLE_QUERY_START
  }
}

function queryCirclesSuccess (circles, connections) {
  return {
    type: CIRCLE_QUERY_SUCCESS,
    circles,
    connections
  }
}

function queryCirclesFailure (error) {
  return {
    type: CIRCLE_QUERY_FAILURE,
    error
  }
}

export const getCountryByIP = () => dispatch => {
  return fetch(`${process.env.REACT_APP_API_URL}/countryByIp`)
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json())
}

function deleteCardRequest (circleId) {
  return {
    type: DELETE_CIRCLE_START,
    circleId
  }
}

function deleteCardSuccess (circleId) {
  return {
    type: DELETE_CIRCLE_SUCCESS,
    circleId
  }
}

function deleteCardFailure (error) {
  return {
    type: DELETE_CIRCLE_FAILURE,
    error
  }
}

// export function queryCircles () {
//   return dispatch => {
//     dispatch(queryCirclesStart())
//     return Cloud.run('getCirclesWithConnections')
//       .then(data => {
//         const connections = {}
//         const circles = data.circles.map(circle => circle.toJSON ? circle.toJSON() : circle)
//         Object.entries(data.connections).forEach(conn => {
//           if (conn[1].length && conn[1].length !== 0) {
//             connections[conn[0]] = conn[1].map(co => co.toJSON ? co.toJSON() : co)
//           }
//         })
//         dispatch(queryCirclesSuccess(circles, connections))
//       })
//       .catch(error => dispatch(queryCirclesFailure(error)))
//   }
// }

// ({ dispatch({ type: MENU_COLLAPSE }))
