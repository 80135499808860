import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { getSingleBusiness } from 'Actions/AdminBusinessActions'
import Loading from '../Loading'
import Preview from '../Business/Property/Preview'

function Business ({ businessId, getSingleBusiness }) {
  const [business, setBusiness] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  useEffect(() => {
    setLoading(true)
    getSingleBusiness(businessId)
      .then(business => setBusiness(business))
      .catch(error => {
        if (error.message) {
          error = error.message
        }
        if (typeof error !== 'string') {
          error = 'Internal error'
        }
        setError(error)
      })
      .finally(() => setLoading(false))
  }, [businessId, getSingleBusiness])

  if (loading || business === null) {
    return (<Loading />)
  }

  if (error) {
    return (<div>Error: {error}</div>)
  }
  return (
    <Row>
      <Col lg={6} md={12}>
        <h3>Business {business.name}</h3>
        <h4>Owner: <Link to={`/admin/user/${business.owner.objectId}`}>
          {business.owner.profile.properties.find(_ => _.name === 'fn').value}
        </Link></h4>
        <ListGroup>
          {business.properties.map(property => (
            <ListGroupItem key={property.objectId}>
              <div className='d-flex w-100 justify-content-between'>
                <p className='mb-1'>{property.name}</p>
                { property.verified
                  ? <small className='text-primary'>Verified</small>
                  : <small className='text-danger'>Not verified</small>
                }
              </div>
              <div>
                <h5 className='mb-1 d-inline'>
                  { property.parameters[1] ? property.parameters[1].value : property.value }
                </h5>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Col>
      <Col lg={6} md={12}>
        <p>Preview</p>
        <Preview business={business} />
      </Col>
    </Row>
  )
}

const mapDispatchToProps = dispatch => ({
  getSingleBusiness: businessId => dispatch(getSingleBusiness(businessId))
})

export default connect(null, mapDispatchToProps)(Business)
