import React from 'react'
import { Container, Button } from 'reactstrap'
import { Helmet } from 'react-helmet'
import AdminLayout from 'Components/Admin/AdminLayout'
import AllUsers from 'Components/Admin/AllUsers'
import { Link } from 'react-router-dom'

export default function UserList () {
  return (
    <AdminLayout>
      <Button outline color='primary'><Link to='/admin'><h4>Back</h4></Link></Button>
      <Helmet>
        <title>Admin users - HeresMyInfo.com</title>
      </Helmet>
      <Container fluid>
        <AllUsers />
      </Container>
    </AdminLayout>
  )
}
