import React from 'react'
import { Row, Col, ListGroupItem } from 'reactstrap'
import NewProperty from 'Components/Personal/Property/NewProperty'
import PropertyValueWithControl from 'Components/Personal/Property/PropertyValueWithControl'

const PropertyItem = ({ property }) => {
  // Single property (name, nickname, etc)
  if (!property.multiple && (property.name !== 'howisaymyname')) {
    // Non-multiple OnMyMind
    // Values located in .value
    // console.log('Non multiple OnMyMind: Value: ', property.value)
    // console.log('Non multiple OnMyMind: Name: ', property.name)
    return (
      <ListGroupItem>
        {property.value
          ? <PropertyValueWithControl propertyMeta={property} value={property.value} />
          : (
            <Row>
              <Col xs="12" md="3">{property.showName}</Col>
              <Col xs="12" md="9"><NewProperty property={property} /></Col>
            </Row>
          )}
      </ListGroupItem>
    )
  }
  if (!property.multiple && (property.name === 'howisaymyname')) {
    // Non-multiple howisaymyname
    // Values located in .value
    if (property.value === undefined) {
      property.value = 'NONE'
    }
    // console.log('Non multiple HOWISAYMYNAME Value: ', property.value)
    // console.log('Non multiple HOWISAYMYNAME Name: ', property.name)
    return (

      <ListGroupItem>
        {property.value
          ? <PropertyValueWithControl propertyMeta={property} value={property.value} />
          : (
            <Row>
            <Col xs="12" md="3">{property.showName}</Col>
            <Col xs="12" md="9"><NewProperty property={property} /></Col>
            </Row>
          )}
      </ListGroupItem>
    )
  }
  // console.log('Multiple Values: ', property.name, property.values)
  // Following handles multiple properties (Dates, emails, phone numbers, addresses, url addresses)
  // Values are located in parameters
  return (
    <>
      {property.values.map(value => (
        <ListGroupItem key={value.objectId}>
          <PropertyValueWithControl propertyMeta={property} value={value} />
        </ListGroupItem>
      ))}
      <ListGroupItem>
        <NewProperty property={property} />
      </ListGroupItem>
    </>
  )
}

export default PropertyItem
