import React, {useEffect} from 'react'
import Layout from 'Components/Layout'
import { Helmet } from 'react-helmet'
import {
  Container, Row, Col,
  Button, Jumbotron,
  Form, FormGroup, FormFeedback
} from 'reactstrap'
import { withFormik } from 'formik'
import TextInput from 'Components/Form/TextInput'
import * as Yup from 'yup'
import { sendSupportMessage, extractErrorMessage } from 'Modules/ParseAPI'
import { connect } from 'react-redux'
import { setIsOpen } from 'Actions/BusinessActions'

const formikEnhancer = withFormik({


  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required.'),
    message: Yup.string()
      .required('Message is required.')
  }),
  mapPropsToValues: ({ user }) => ({
    email: user && user.email ? user.email : '',
    message: ''
  }),
  handleSubmit: (payload, { setSubmitting, props, setStatus, setFieldError }) => {
    setStatus({ })
    sendSupportMessage(payload.email, payload.message)
      .then(() => setStatus({ success: true }))
      .catch(err => setStatus({ apiError: extractErrorMessage(err) }))
      .finally(() => setSubmitting(false))
  },
  displayName: 'SupportForm'
})

const Support = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    loading,
    setIsOpen
  } = props

  useEffect(() => {
    setIsOpen(false)
  }, [setIsOpen])
  
  return (
    <Layout>
      <Helmet>
        <title>Support - HeresMyInfo.com</title>
      </Helmet>
      <Container>
        <Col />
        <Row>
          <Col xs='1' />
          <Col xs='10' className='hmi-video'>
            <h1 style={{ textAlign: 'center', marginBottom: 10 }}>Support</h1>
          </Col>
          <Col xs='1' />
          <Col xs='1' />
          <Col xs='10' className='hmi-video'>
            <h3 style={{ textAlign: 'center' }}>Drop us a line!</h3>
          </Col>
          <Col xs='1' />
        </Row>
        <Col />
        {status && status.success
          ? (
            <Jumbotron>
              <h1 style={{ color: 'black' }}>We've received your message and will get back to you soon.</h1>
            </Jumbotron>
          )
          : (
            <Row>
              <Form onSubmit={handleSubmit} className='w-100' onFocus={() => setIsOpen(false)}>
                <Col>
                  <TextInput
                    id='email'
                    type='text'
                    label='Your email'
                    placeholder='Your email'
                    error={touched.email && errors.email}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

                <Col>
                  <TextInput
                    id='message'
                    type='textarea'
                    label='Your message '
                    placeholder='Your message'
                    error={touched.message && errors.message}
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ height: '200px' }}
                  />
                </Col>

                {status && status.apiError && (
                  <Col>
                    <FormGroup>
                      <FormFeedback className='d-block'>{status.apiError}</FormFeedback>
                    </FormGroup>
                  </Col>
                )}
                <Col className='text-center'>
                  <Button outline type='submit' size='lg' disabled={isSubmitting || loading} className='px-5'>Send message</Button>
                </Col>
              </Form>
            </Row>
          )}
      </Container>

    </Layout>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
})

const mapDispatchToProps = dispatch => ({
  setIsOpen : () => dispatch(setIsOpen(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(formikEnhancer(Support))
