import { Cloud } from 'Modules/ParseModule'
import debounce from 'lodash.debounce'
import { flatName } from './BusinessActions'

export const GET_ALL_BUSINESSES_REQUEST = 'GET_ALL_BUSINESSES_REQUEST'
export const GET_ALL_BUSINESSES_SUCCESS = 'GET_ALL_BUSINESSES_SUCCESS'
export const GET_ALL_BUSINESSES_FAILURE = 'GET_ALL_BUSINESSES_FAILURE'

export const DELETE_BUSINESSES_REQUEST = 'DELETE_BUSINESSES_REQUEST'
export const DELETE_BUSINESSES_SUCCESS = 'DELETE_BUSINESSES_SUCCESS'
export const DELETE_BUSINESSES_FAILURE = 'DELETE_BUSINESSES_FAILURE'

export const CHANGE_BUSINESSES_PAGE = 'CHANGE_BUSINESSES_PAGE'
export const SELECT_BUSINESSES = 'SELECT_BUSINESSES'
export const SEARCH_BUSINESSES = 'SEARCH_BUSINESSES'

export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS'

const getAllBusinessesSuccess = (businesses, total, owners) => ({
  type: GET_ALL_BUSINESSES_SUCCESS,
  businesses,
  total,
  owners
})

export const changePage = page => dispatch => {
  dispatch({ type: CHANGE_BUSINESSES_PAGE, page })

  dispatch(getAllBusinesses())
}

export const getAllBusinesses = () => (dispatch, getState) => {
  dispatch({ type: GET_ALL_BUSINESSES_REQUEST })
  const { admin: { businesses: { perPage, page, search } } } = getState()

  let businesses, total, owners

  return Cloud.run('getBusinessesWithProperties', { perPage, page, search })
    .then(res => {
      total = res.total
      businesses = res.businesses.map(_ => _.toJSON())
      owners = res.owners.map(_ => _.toJSON())
      dispatch(getAllBusinessesSuccess(businesses, total, owners))
    })
    .catch(error => dispatch({ type: GET_ALL_BUSINESSES_FAILURE, error }))
}

export const select = selection => ({
  type: SELECT_BUSINESSES,
  selection
})

const getAllBusinessesDebounced = debounce(dispatch => dispatch(getAllBusinesses()), 500)
export const searchBusinesses = search => dispatch => {
  dispatch({ type: SEARCH_BUSINESSES, search })
  // Don't make API call if search term is shorter than 3 symbols
  // If search is empty - make API call to "reset" the filter
  if (search === '' || search.length >= 3) {
    getAllBusinessesDebounced(dispatch)
  }
}

export const verifyName = (objectId, verified) => dispatch =>
  Cloud.run('verifyBusinessName', { objectId, verified })
    .then(({ business }) => {
      business = business.toJSON()
      dispatch({ type: UPDATE_BUSINESS_SUCCESS, business })
      return business
    })

export const сhangeState = (objectId, state) => dispatch =>
  Cloud.run('changeBusinessState', { objectId, state })
    .then(({ business }) => {
      business = business.toJSON()
      dispatch({ type: UPDATE_BUSINESS_SUCCESS, business })
      return business
    })

export const deleteBusinesses = businesses => dispatch => {
  dispatch({ type: DELETE_BUSINESSES_REQUEST })

  return Cloud.run('deleteBusinesses', { businesses })
    .then(() => dispatch({ type: DELETE_BUSINESSES_SUCCESS }))
    .catch(error => dispatch({ type: DELETE_BUSINESSES_FAILURE, error }))
    .then(() => dispatch(getAllBusinesses()))
}

export const getSingleBusiness = objectId => dispatch =>
  Cloud.run('adminGetBusiness', { objectId })
    .then(({ business, owner, templatePath }) => ({
      ...business.toJSON(),
      name: flatName(business),
      owner: owner.toJSON(),
      templatePath
    }))
