import React, { useState } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import OneCircle from 'Components/Personal/OneCircle'

export default function CircleList ({ profile, profileLoading }) {
  const [activeCircle, changeCircle] = useState(() => profile.circles[0].objectId)
  console.log('CircleList profile: ', profile)
  return (
    <>
          <Nav tabs className='mb-3'>
            {profile.circles.map(circle =>
              <NavItem key={circle.objectId}>
                <NavLink
                  href='#'
                  active={activeCircle === circle.objectId}
                  onClick={(e) => {
                    changeCircle(circle.objectId)
                    e.stopPropagation()
                  }}
                >{circle.name}
                </NavLink>
              </NavItem>
            )}
          </Nav> 
          <OneCircle profile={profile} circleId={activeCircle} profileLoading={profileLoading} /> 
    </>
  )
}
