import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import reduceReducers from 'reduce-reducers'
import Auth, { initialState as authInitialState } from './Auth'
import Admin from './Admin'
import Business from './Business'
import Settings from './Settings'
import Personal from './Personal'
import FAQ from './Faq'
import Parse from './Parse'

// Auth and Personal reducers works with the same state tree nodes
const AuthUserReducer = reduceReducers(authInitialState, Auth, Personal)

const settingsPersistConfig = {
  key: 'settings',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['localHideCookieConsent']
}

export default combineReducers({
  auth: AuthUserReducer,
  admin: Admin,
  business: Business,
  faq: FAQ,
  parse: Parse,
  settings: persistReducer(settingsPersistConfig, Settings)
})
