import React from 'react'
import { Container } from 'reactstrap'
import { Helmet } from 'react-helmet'
import AdminLayout from 'Components/Admin/AdminLayout'
import SingleUser from 'Components/Admin/SingleUser'

export default function Main (props) {
  return (
    <AdminLayout>
      <Helmet>
        <title>Users - HeresMyInfo.com</title>
      </Helmet>
      <Container>
        <h1>User Connections Status</h1>
        <SingleUser userId={props.match.params.userId} />
      </Container>
    </AdminLayout>
  )
}
