import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Jumbotron } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Layout from 'Components/Layout'
import Loading from 'Components/Loading'
import { loadOne } from 'Actions/BusinessActions'
import InitPropertyVerification from 'Components/Business/Property/InitPropertyVerification'
import {
  PROPERTY_NAME,
  PROPERTY_EMAIL,
  PROPERTY_PHONE,
  PROPERTY_DOMAIN,
  propTitles
} from 'Constants/Properties'
import VerifyBusinessPhone from 'Components/Business/Property/VerifyBusinessPhone'
import VerifyDomain from 'Components/Business/Property/VerifyDomain'

class PropertyStatus extends Component {
  constructor (props) {
    super(props)

    this.state = {
      property: null
    }
  }

  componentDidMount () {
    this.props.loadOne(this.props.match.params.businessId)
  }

  componentDidUpdate (prevProps, prevState) {
    const { loading, error, business } = this.props
    const { propertyId } = this.props.match.params
    if (!loading && !error && business.properties) {
      const property = business.properties.filter(p => p.objectId === propertyId)[0] || null
      const oldProperty = prevState.property
      if (oldProperty &&
        oldProperty.objectId === property.objectId &&
        oldProperty.verified === property.verified &&
        oldProperty.updatedAt === property.updatedAt
      ) {
        return
      }
      this.setState({ property })
    }
  }

  render () {
    const { loading, error, business } = this.props
    const { property } = this.state

    return (
      <Layout>
        <Container>
          {loading ? (<div className='position-relative py-5'><Loading /></div>)
            : (error || !property) ? (<p>Error while loading. Please try to reload the page</p>) : (
              <Jumbotron>
                <Helmet>
                  <title>Manage business {business.name} - HeresMyInfo.com</title>
                </Helmet>
                <h1>{propTitles.get(property.name) || property.name} of company{' '}
                  <Link to={`/business/${business.objectId}`}>{business.name}</Link>:{' '}
                  { property.parameters[1] ? property.parameters[1].value : property.value }
                </h1>
                { property.verified && (
                  <h3 className='text-primary'>Verified</h3>
                )}
                { !property.verified && (
                  <>
                    <h3 className='text-danger'>Not verified</h3>
                    { property.name === PROPERTY_EMAIL && (
                      <>
                        <p>Check the email in your inbox</p>
                        <p>(Be sure to check your spam or junk folders)</p>
                        <p>Haven't received an email?{' '}
                          <InitPropertyVerification title={'Send again'} propertyId={property.objectId} />
                        </p>
                      </>
                    )}
                    { property.name === PROPERTY_PHONE && (
                      <VerifyBusinessPhone property={property} />
                    )}
                    { property.name === PROPERTY_DOMAIN && (
                      <VerifyDomain property={property} />
                    )}
                    { property.name === PROPERTY_NAME && (
                      <>
                        <p>Your business name will be verified by our team after you verify the domain.</p>
                        <p>Any questions? Contact <Link to='/support'>Support</Link></p>
                      </>
                    )}
                  </>
                )}
              </Jumbotron>
            )}
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  business: state.business.business,
  loading: state.business.loading,
  error: state.business.error
})

const mapDispatchToProps = dispatch => ({
  loadOne: (id) => dispatch(loadOne(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertyStatus)
