import React, { PureComponent } from 'react'
import ReactPlayer from 'react-player'
import './Player.scss'

export default class Player extends PureComponent {
  render () {
    return (
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          width='100%'
          height='100%'
          {...this.props}
        />
      </div>
    )
  }
}
