import { combineReducers } from 'redux'
import AdminUsers from './AdminUsers'
import AdminOneUser from './AdminOneUser'
import AdminBusinesses from './AdminBusinesses'

export default combineReducers({
  users: AdminUsers,
  user: AdminOneUser,
  businesses: AdminBusinesses
})
