import Parse from 'parse'

const applicationId = process.env.REACT_APP_PARSE_APP_ID
const jsKey = process.env.REACT_APP_PARSE_JS_KEY
const serverURL = process.env.REACT_APP_PARSE_URL
console.log('ParseServerURL: ', serverURL)
Parse.initialize(applicationId, jsKey)
Parse.serverURL = serverURL

const localStorage = window.localStorage
const StorageController = {
  async: 0,
  lastUserString: '',
  getAllKeys () {
    return localStorage.getAllKeys()
  },
  
  getItem (path) {
    return localStorage.getItem(path)
  },

  setItem (path, value) {
    if (path === `Parse/${applicationId}/currentUser`) {
      if (value === this.lastUserString) {
        // User string might be meabytes of data and simple json parsing might take a lot of time
        // Parse JS library calls setItem two times on each user refetch internally and this is a workaround
        return
      }
      this.lastUserString = value
      const newValue = JSON.parse(value)
      // Don't need to store profile and QRcircleId (with properties) in local storage because it might be megabytes of data
      // @todo revisit it, now we can stire profile since we store images on gcp
      const { profile, QRcircleId, ...withoutProfile } = newValue
      value = JSON.stringify(withoutProfile)
    }
    try {
      localStorage.setItem(path, value)
    } catch (e) {
      // Quota exceeded, possibly due to Safari Private Browsing mode
    }
  },

  removeItem (path) {
    localStorage.removeItem(path)
  },

  clear () {
    localStorage.clear()
  }
}
Parse.CoreManager.setStorageController(StorageController)

if (process.env.NODE_ENV === 'development') {
  window.Parse = Parse
}

export const { User, Cloud, Query, Property, File } = Parse
