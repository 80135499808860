import React from 'react'
import {
  Col, Form, FormGroup, FormFeedback, Button
} from 'reactstrap'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import TextInput from '../Form/TextInput'

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required('Name is required.'),
    domain: Yup.string()
      .required('Domain is required.')
  }),

  mapPropsToValues: () => ({
    name: '',
    domain: ''
  }),
  handleSubmit: (payload, { setSubmitting, props, setStatus, setFieldError }) => {
    setStatus({ })
    props.create(payload.name, payload.domain)
      .then(business => {
        setSubmitting(false)
        return business
      })
      .catch(e => {
        const err = e.message
        if (err === 'Verified property company is already used') {
          setFieldError('name', 'Business with this name already exists.')
        } else if (err === 'Verified property domain is already used') {
          setFieldError('domain', 'This domain is already used')
        } else if (err === 'Invalid domain') {
          setFieldError('domain', err)
        } else {
          setStatus({ apiError: err })
        }
        setSubmitting(false)
      })
  },
  displayName: 'NewBusinessForm'
})

const NewBusinessForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    loading
  } = props

  return (
    <Form className='form' onSubmit={handleSubmit}>
      <Col>
        <TextInput
          id='name'
          type='text'
          label='Business Name'
          placeholder='Example Inc.'
          error={touched.name && errors.name}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Col>

      <Col>
        <TextInput
          id='domain'
          type='text'
          label='Domain '
          placeholder='www.example.org'
          error={touched.domain && errors.domain}
          value={values.domain}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Col>

      { status && status.apiError && (
        <Col>
          <FormGroup>
            <FormFeedback className='d-block'>{status.apiError}</FormFeedback>
          </FormGroup>
        </Col>
      )}
      <Col className='text-center'>
        <Button color='primary' type='submit' size='lg' disabled={isSubmitting || loading} className='px-5'>Create</Button>
      </Col>
    </Form>
  )
}

export default formikEnhancer(NewBusinessForm)
