import React, { useMemo } from 'react'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { useGroupFlatProperties } from 'Modules/PropertiesModule'
import CircleProperty from 'Components/Personal/CircleProperty'
import CirclePreview from 'Components/Personal/CirclePreview'
// import { deleteCard, testIt } from '../../Actions/PersonalActions'

export default function OneCircle ({ profile, circleId, profileLoading }) {
  const allFlatProperties = useGroupFlatProperties(profile.properties)

  const circle = useMemo(() => {
    return profile.circles.find(circle => circle.objectId === circleId)
  }, [profile, circleId])

  return (
    <Container>
      <Row>
        <Col lg={6} md={12}>
          {allFlatProperties.map(group => (
            <div key={group.name}>
              <h3>{group.showName}</h3>
              <ListGroup>
                {group.properties.map(property => property.value && (
                  <ListGroupItem key={property.value.objectId}>
                    <CircleProperty propertyMeta={property} circle={circle} />
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          ))}
        </Col>

        <Col lg={6} md={12} className='pl-5'>
          <div className='sticky-top'>

            <h1>My {circle.name} Card</h1>

            <CirclePreview circle={circle} profileLoading={profileLoading} />
            {(circle.name !== 'Work' && circle.name !== 'Personal')
              ? <div style={{ textAlign: 'center' }}>
                <h6>This is a custom card and can be deleted</h6> <div />
                <h6>Please use the app to delete this card.</h6> <div />
                {/* <Button
                  color='warning'
                  size='sm'
                  onClick={(() => testIt)}
                >Delete
                </Button> */}
                </div>
              : <div style={{ textAlign: 'center' }}><h6>This card cannnot be deleted</h6></div> }
          </div>
        </Col>
      </Row>
    </Container>
  )
}
