export const COOKIE_CONSENT = 'COOKIE_CONSENT'
export const LOCAL_FLAG_COOKIE_CONSENT = 'LOCAL_FLAG_COOKIE_CONSENT'


export const acceptCookieConsent = () => ({ type: COOKIE_CONSENT })

// Allows to turn off cookie consent on certain page (must update on page change)
export const localHideCookieConsent = hide => ({
  type: LOCAL_FLAG_COOKIE_CONSENT,
  hide
})


