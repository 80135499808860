import * as React from 'react'
import './Loading.scss'
import { Spinner } from 'reactstrap'

export const Loading = () => (
  <div className='loading-shading'>
    <div className='loading-icon'>
      <Spinner type='grow' color='primary' className='loading-spinner' />
    </div>
  </div>
)
