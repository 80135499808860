import React, { useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import Applet from './Applet'
import dateFnsParseISO from 'date-fns/parseISO'
import dateFnsFormat from 'date-fns/format'
import { getSingleUser } from 'Actions/PersonalActions'

const Overview = ({ user, getSingleUser, connections }) => {
  useEffect(() => {
    getSingleUser(user.objectId)
  }, [getSingleUser, user.objectId])

  const pendingOutgoingInvites = useMemo(
    () => !connections.outgoing || connections.outgoing.length === 0 ? 0 : connections.outgoing
      .filter(_ => _.status === 'pending')
      .length
    , [connections.outgoing])

  const pendingIncomingInvites = useMemo(
    () => !connections.incoming || connections.incoming.length === 0 ? 0 : connections.incoming
      .filter(_ => _.status === 'pending')
      .length
    , [connections.incoming])

  const inConnections = connections.incoming && connections.incoming.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter((conn) => conn.status === 'pending')
  const outConnections = connections.outgoing && connections.outgoing.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter((conn) => conn.status === 'pending')

  const title = 'My Pending Invitations'
  return (
    <Applet title={title}>

      {connections.outgoing && (
        <p>I have <strong>{pendingOutgoingInvites}</strong> outgoing invitation{pendingOutgoingInvites !== 1 ? 's' : ''}{pendingOutgoingInvites === 0 ? '.' : ':'}</p>
      )}
      <ol>{outConnections && outConnections.map(({ objectId, fullName, email, createdAt }) => {
        const isodate = dateFnsParseISO(createdAt)
        const date = `${dateFnsFormat(isodate, 'PP')}`
        return (<li key={objectId}><strong>{fullName || email}</strong> - sent {date}</li>)
      })}
      </ol>
      {connections.incoming && (
        <p>I have <strong>{pendingIncomingInvites}</strong> incoming invitation{pendingIncomingInvites !== 1 ? 's' : ''} to connect{pendingIncomingInvites === 0 ? '.' : ':'}</p>
      )}
      <ol>
        {inConnections && inConnections.map(({ objectId, fullName, email, createdAt }) => {
          const isodate = dateFnsParseISO(createdAt)
          const date = `${dateFnsFormat(isodate, 'PP')}`
          return (<li key={objectId}><strong>{fullName || email}</strong> - sent {date}</li>)
        })}
      </ol>

      <hr />
      <p>Use our mobile app to manage connections and see your invitations.</p>
    </Applet>
  )
}

const mapStateToProps = state => ({
  user: state.auth.currentUser,
  connections: state.parse.connections
})

const mapDispatchToProps = dispatch => ({
  getSingleUser: (user) => dispatch(getSingleUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
