import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { initPhoneVerification } from 'Actions/PersonalActions'
import { extractErrorMessage } from 'Modules/ParseAPI'

function InitPhoneVerification ({ phoneObject, sendCode }) {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  function sendAgain () {
    setLoading(true)
    setError(null)
    sendCode(phoneObject)
      .then(() => setSuccess(true))
      .catch(err => setError(extractErrorMessage(err)))
      .finally(() => setLoading(false))
  }

  if (success) {
    return <span className='text-primary px-1'>Sent!</span>
  }

  if (error) {
    return <span className='text-danger px-1'>{error}</span>
  }

  return (
    <span>
      <Button color='link' className='pt-0 px-1'
        disabled={loading}
        onClick={sendAgain}
      >
        {loading ? 'Sending..' : 'Send again'}
      </Button>
    </span>
  )
}

const mapDispatchToProps = dispatch => ({
  sendCode: phoneObject => dispatch(initPhoneVerification(phoneObject))
})

export default connect(null, mapDispatchToProps)(InitPhoneVerification)
