import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ListGroupItem, Button } from 'reactstrap'
import Switch from 'react-switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SinglePropertyForm from './SinglePropertyForm'
import { createProperty, editProperty } from 'Actions/BusinessActions'
import { PROPERTY_NAME, BUSINESS_DESCRIPTION, PROPERTY_THEME, PROPERTY_OPT_IN } from 'Constants/Properties'

class PropertyItem extends Component {
  constructor (props) {
    super(props)

    let checked = false
    const { business } = this.props
    const thisProp = this.getProperty()
    if (thisProp) {
      const themeProp = business && business.properties ? business.properties.filter((p) => p.name === 'theme') : []
      if (themeProp.length > 0) {
        const themes = JSON.parse(themeProp[0].value)
        console.log('themes', themes)
        checked = thisProp.name && themes.includes && themes.includes[thisProp.name]
      }
    }
    this.state = {
      showForm: false,
      checked: checked
    }

    this.onCreate = this.onCreate.bind(this)
    this.onEdit = this.onEdit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.showForm = this.showForm.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  getProperty () {
    const { propMeta, business } = this.props
    const properties =
      business && business.properties
        ? business.properties.filter((p) => p.name === propMeta.key)
        : []
    return properties.length ? properties[0] : null
  }

  onCreate (value, rest) {
    const businessId = this.props.business.objectId
    const name = this.props.propMeta.key

    console.log('property::', businessId, name, value, rest)
    return this.props
      .createProperty(businessId, name, value, rest)
      .then((property) => {
        this.setState({ showForm: false })
        return property
      })
  }

  onEdit (value, rest) {
    const id = this.getProperty().objectId

    return this.props.editProperty(id, value, rest).then((property) => {
      this.setState({
        showForm: false
      })
      return property
    })
  }

  onCancel () {
    this.setState({ showForm: false })
  }

  showForm () {
    this.setState({ showForm: true })
  }

  onChange (checked) {
    const { business } = this.props
    this.setState({ checked: checked })
    const thisProp = this.getProperty()
    const themeProp = business && business.properties ? business.properties.filter((p) => p.name === 'theme') : []
    const themes = JSON.parse(themeProp[0].value)
    themes.includes[thisProp.name] = checked

    return this.props.editProperty(themeProp[0].objectId, JSON.stringify(themes)).then((property) => {
      return property
    })
  }

  render () {
    const { propMeta } = this.props
    const businessId = this.props.business.objectId
    const { showForm, checked } = this.state

    const property = this.getProperty()
    const exists = !!property
    console.log('PropertyItem property', property)
    const value = !exists
      ? null
      : property.parameters[1]
        ? property.parameters[1].value
        : property.value
    const rest = {}
    if (propMeta.key === 'phoneNumbers' && exists) {
      if (property.parameters[2]) {
        rest.countryCode = property.parameters[2].value
      }
      if (property.parameters[3]) {
        rest.type = property.parameters[3].value
      }
    }

    if (property && property.name === PROPERTY_THEME) {
      return (<></>)
    }
    return (
      <ListGroupItem>
        <div className='d-flex w-100 justify-content-between'>
          <p className='mb-1'>{propMeta.title}</p>
          {/* Hide verified state for name property */}
          {property &&
          property.name !== PROPERTY_NAME &&
          property.name !== BUSINESS_DESCRIPTION &&
          property.name !== PROPERTY_OPT_IN &&
          property.name !== PROPERTY_THEME && (
            <>
              {property && property.verified && (
                <Link
                  to={`/business/${businessId}/status/${property.objectId}`}
                  className='text-primary'
                >
                  <small>Verified</small>
                </Link>
              )}
              {property && !property.verified && (
                <Link
                  to={`/business/${businessId}/status/${property.objectId}`}
                  className='text-danger'
                >
                  <small>Not verified</small>
                </Link>
              )}
            </>
          )}
          <div className='pt-2 text-right'>
            {property &&
            (property.verified || property.name === BUSINESS_DESCRIPTION || property.name === PROPERTY_NAME) &&
            property.name !== PROPERTY_THEME &&
            property.name !== PROPERTY_THEME && (
              <Switch
                onChange={this.onChange}
                checked={checked}
                onColor='#00A956'
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                height={20}
                width={48}
                className='react-switch'
                disabled={false}
              />
            )}
          </div>
        </div>
        {exists ? (
          // Existing
          <>
            {!showForm && (
              <div>
                <h5 className='mb-1 d-inline'>{value}</h5>
                <Button color='link'>
                  <FontAwesomeIcon icon='pen' onClick={this.showForm} />
                </Button>
              </div>
            )}
            {showForm && (
              <SinglePropertyForm
                submit={this.onEdit}
                cancel={this.onCancel}
                value={value}
                type={propMeta.key}
                rest={rest}
              />
            )}
          </>
        ) : (
          // New
          <>
            {!showForm && (
              <Button
                color='link'
                className='font-italic text-muted p-0'
                onClick={this.showForm}
              >
                (not set)
              </Button>
            )}
            {showForm && (
              <SinglePropertyForm
                submit={this.onCreate}
                cancel={this.onCancel}
                type={propMeta.key}
                rest={rest}
              />
            )}
          </>
        )}
      </ListGroupItem>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  createProperty: (businessId, name, value, rest) =>
    dispatch(createProperty(businessId, name, value, rest)),
  editProperty: (id, value, rest) => dispatch(editProperty(id, value, rest))
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertyItem)
