/* global Blob */
import React, { useState } from 'react'
import { Container, Input, Button, Form, FormGroup, Col, Row } from 'reactstrap'
import Switch from 'react-switch'
import Layout from 'Components/Layout'
import { Cloud } from 'Modules/ParseModule'
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'

export default () => {
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const [backupFlagLatest, setBackupFlag] = useState(false)
  const [error, setError] = useState(null)

  const downloadTxtFile = (data, version) => {
    const element = document.createElement('a')
    const file = new Blob([data], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = `hmi_contacts_${version}.vcf`
    document.body.appendChild(element)
    element.click()
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    setLoading(true)
    setError(null)
    try {
      const version = backupFlagLatest ? 'latest' : 'initial'
      const file = await Cloud.run('getContactsBackup', { version })
      if (!file) {
        setError('Seems like there is no backup file of this type yet')
        setLoading(false)
        return
      }
      const response = await fetch(file.url())
      if (!response) {
        setError('Error while geting backup file')
        setLoading(false)
        return
      }

      const data = await response.text()
      if (!data) {
        setError('Empty file')
        setLoading(false)
        return
      }

      try {
        const vCards = AES.decrypt(data, password).toString(Utf8)
        if (vCards) {
          downloadTxtFile(vCards, version)
        } else {
          setError('Wrong password')
        }
      } catch (e) {
        setError('Wrong password')
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
      setError('Unexpected error while loading backup data. Please try again later')
      setLoading(false)
    }
  }

  return (
    <Layout>
      <Container>
        <h1>User Contacts</h1>
        <p>
          Here you can download your backup contacts that you've created using the mobile app.
          You must provide the password you used when creating the backup in order to restore the data.
          Your backup was encrypted  when ity was created and can only be decrypted by you.
          We don't store your backup password and only you can access the backup data.
        </p>
        <Form className='form' onSubmit={handleFormSubmit}>
          <h5> Enter your backup password</h5>
          <FormGroup>
            <Input type='password'
              placeholder='password'
              value={password}
              onChange={event => {
                setPassword(event.target.value)
              }}
            />
          </FormGroup>
          <h5> Choose the backup type to recover (Initial or Most Recent)</h5>
          <Row >
            <Col xs='12'>
              <label className='d-flex'>
                <h5 className='mr-3'> Initial</h5>
                <Switch
                  onChange={flag => setBackupFlag(flag)}
                  checked={backupFlagLatest}
                  onColor='#00A956'
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  height={10}
                  width={38}
                  className='react-switch'
                />
                <h5 className='ml-3'> Most Recent</h5>
              </label>
            </Col>
          </Row>
          <Button
            color='primary'
            type='submit'
            disabled={loading || password === ''}
          >
              Get My Contacts
          </Button>
          {error && <p className='text-danger'>{error}</p>}
        </Form>
      </Container>
    </Layout>
  )
}
