import React from 'react'
import { useGroupFlatProperties } from 'Modules/PropertiesModule'
import Loading from 'Components/Loading'

function NamePreview ({ values }) {
  const mappedValues = new Map(values.map(value => [value.name, value]))
  let image = null
  if (mappedValues.has('thumbnailImage')) {
    image = mappedValues.get('thumbnailImage').value.value
    if (!image.startsWith('data:image') && !image.startsWith('http')) {
      image = `data:image/jpeg;base64,${image}`
    }
  }

  return (
    <div className='bg-dark w-100 d-flex p-3'>
      {image && (
        <div className='mr-3'><img src={image} alt='Profile' style={{ maxWidth: '100px' }} className='rounded-circle' /></div>
      )}
      <div>
        <p className='font-weight-bold'>
          {mappedValues.has('prefix') && mappedValues.get('prefix').value.value + ' '}
          {mappedValues.has('givenName') && mappedValues.get('givenName').value.value + ' '}
          {mappedValues.has('middleName') && mappedValues.get('middleName').value.value + ' '}
          {mappedValues.has('familyName') && mappedValues.get('familyName').value.value + ' '}
          {mappedValues.has('nameSuffix') && mappedValues.get('nameSuffix').value.value + ' '}
        </p>
        <p className={mappedValues.has('department') ? 'mb-0' : ''}>
          {mappedValues.has('jobTitle') && mappedValues.get('jobTitle').value.value + (mappedValues.has('company') ? ', ' : ' ')}
          {mappedValues.has('company') && mappedValues.get('company').value.value}
        </p>
        {mappedValues.has('department') && <p>{mappedValues.get('department').value.value}</p>}
        {mappedValues.has('nickName') && <p className='font-italic my-0'>"{mappedValues.get('nickName').value.value}"</p>}
      </div>
    </div>
  )
}

function DefaultPreview ({ value }) {
  const property = value.value
  return (
    <CommonPreviewWrapper showName={value.showName}>
      <p>{property.value}</p>
    </CommonPreviewWrapper>
  )
}

function CommonPreviewWrapper ({ showName, children }) {
  return (
    <div className='m-3'>
      <p className='m-0 text-secondary'>{showName}</p>
      {children}
    </div>
  )
}

function UrlAddressesPreview ({ values }) {
  return (
    <div>
      {values.map(value => (
        <CommonPreviewWrapper showName={value.showName} key={value.value.objectId}>
          <p className='m-0 badge badge-secondary'>{value.value.parameters[0].value}</p>
          <p className='m-0'><a href={value.value.parameters[1].value} target='_blank' rel='noopener noreferrer'>
            {value.value.parameters[1].value}
                             </a>
          </p>
        </CommonPreviewWrapper>
      ))}
    </div>
  )
}

function EmailAddressesPreview ({ values }) {
  return (
    <div>
      {values.map(value => (
        <CommonPreviewWrapper showName={value.showName} key={value.value.objectId}>
          <p className='m-0 badge badge-secondary'>{value.value.parameters[0].value}</p>
          <p className='m-0'><a href={`mailto:${value.value.parameters[1].value}`} target='_blank' rel='noopener noreferrer'>
            {value.value.parameters[1].value}
                             </a>
          </p>
        </CommonPreviewWrapper>
      ))}
    </div>
  )
}

function PhoneNumbersPreview ({ values }) {
  return (
    <div>
      {values.map(value => (
        <CommonPreviewWrapper showName={value.showName} key={value.value.objectId}>
          <p className='m-0 badge badge-secondary'>{value.value.parameters[0].value}</p>
          <p className='m-0'><a href={`tel:${value.value.parameters[1].value}`} target='_blank' rel='noopener noreferrer'>
            {value.value.parameters[1].value}
                             </a>
          </p>
        </CommonPreviewWrapper>
      ))}
    </div>
  )
}

function DatesPreview ({ values }) {
  console.log('DatesPreview values: ', values)
  return (
    <div>
      {values.map(value => (
        <CommonPreviewWrapper showName={value.showName} key={value.value.objectId}>
          <p className='m-0 badge badge-secondary'>{value.value.parameters[0].value}</p>
          <p className='m-0'>{value.value.parameters[4].value}</p>
        </CommonPreviewWrapper>
      ))}
    </div>
  )
}

function PostalAddressesPreview ({ values }) {
  return (
    <div>
      {values.map(value => (
        <CommonPreviewWrapper showName={value.showName} key={value.value.objectId}>
          <p className='m-0 badge badge-secondary'>{value.value.parameters[0].value}</p>
          {Object.entries(value.value.parameters).map(([i, param]) => param.showName &&
          param.name !== 'label' && param.value && (
            <p className='m-0' key={param.name}>{param.value}</p>
          ))}
        </CommonPreviewWrapper>
      ))}
    </div>
  )
}

function GroupPreview ({ group }) {
  const values = group.properties
  if (!values) {
    return null
  }
  switch (group.name) {
    case 'howisaymyname':
      return <h5 className='p-3 text-center font-italic m-0'>{values[0].value.value}</h5>
    case 'onmymind':
      return <h5 className='p-3 text-center font-italic m-0'>{values[0].value.value}</h5>
    case 'name':
      return <NamePreview values={values} />
    case 'urlAddresses':
      return <UrlAddressesPreview values={values} />
    case 'emailAddresses':
      return <EmailAddressesPreview values={values} />
    case 'phoneNumbers':
      return <PhoneNumbersPreview values={values} />
    case 'dates':
      return <DatesPreview values={values} />
    case 'postalAddresses':
      return <PostalAddressesPreview values={values} />
    default:
      return (
        <div>
          {values.map(value => <DefaultPreview value={value} key={value.value.objectId} />)}
        </div>
      )
  }
}

export default function CirclePreview ({ circle, profileLoading }) {
  const flatCircleProperties = useGroupFlatProperties(circle.properties)
  return (
    <div className='border text-break p-0'>
      {profileLoading && <Loading />}
      {flatCircleProperties.map(group => <GroupPreview group={group} key={group.name} />)}
    </div>
  )
}
