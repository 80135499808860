import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Container, Row
} from 'reactstrap'
import { Link, Redirect, withRouter } from 'react-router-dom'
import Layout from '../Layout'
import PhoneNotVerified from './PhoneNotVerified'
import EmailConfirmation from 'Components/SignUp/EmailConfirmation'

export class VerificationWrapper extends Component {
  render () {
    const {
      isAuthenticated,
      emailVerified,
      phoneVerified,
      component: WrappedComponent,
      primaryEmail,
      primaryPhone,
      otherProps } = this.props

    if (isAuthenticated && emailVerified && phoneVerified) return (<WrappedComponent {...otherProps} />)

    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: this.props.location }
          }}
        />
      )
    }

    if (!emailVerified) {
      if (!primaryEmail) {
        return (
          <Layout>
            <Container>
              <Row>
                <p>Your account doesn't have a primary email property. This is an impossible case, please <Link to='/support'>contact support</Link> to fix your case.</p>
              </Row>
            </Container>
          </Layout>
        )
      }
      return (
        <Layout>
          <Container>
            <Row className='d-block'>
              <EmailConfirmation h1='Please verify your email address' email={primaryEmail} />
            </Row>
            <Row>
              <p><Link to='/learn'>Learn more </Link> about HeresMyInfo while waiting for an email.</p>
            </Row>
          </Container>
        </Layout>
      )
    }

    // Phone not verified:
    return (
      <Layout>
        <Container>
          <PhoneNotVerified phone={primaryPhone} />
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  emailVerified: state.auth.currentUser && state.auth.currentUser.emailVerified,
  phoneVerified: state.auth.currentUser && state.auth.currentUser.phoneVerified,
  primaryEmail: state.auth.currentUser && state.auth.currentUser.primaryEmail,
  primaryPhone: state.auth.currentUser && state.auth.currentUser.primaryPhone
})

export default withRouter(connect(mapStateToProps)(VerificationWrapper))
