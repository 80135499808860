import React from 'react'
import AdminLayout from 'Components/Admin/AdminLayout'
import { Container } from 'reactstrap'

export default function Main () {
  return (
    <AdminLayout>
      <Container>
        <h2>HMI Administration Overview</h2>
        <hr />
        <h3>Total # users in system: </h3>
        <h3>Total # Businesses: </h3>
        <h3>New users today: </h3>
        <h3>Average daily signups: </h3>
        <h3>GCP Status: </h3>
        <h3>Helpdesk requests in queue: </h3>
      </Container>
    </AdminLayout>
  )
}
