import React, { useState, useRef } from 'react'
import { FormGroup, FormFeedback, Input } from 'reactstrap'
import { extractErrorMessage } from 'Modules/ParseAPI'
import Loading from 'Components/Loading'

export default function ImageBase64Input ({ onSubmit }) {
  console.log('onSubmit: ', onSubmit)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const fileEl = useRef(null)
  const onChange = event => {
    setError(null)
    const fileObject = event.target
    const file = fileObject.files[0]
    if (file.size > 1024 * 1024) {
      setError('Max file size is 1Mb')
      if (fileEl.current) {
        fileEl.current.value = ''
      }
      return false
    }
    setLoading(true)
    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      // removing beginning because it breaks on mobile
      const base64 = reader.result.replace(/^data:image\/\w+;base64,/, '')
      onSubmit(base64)
        .catch(err => {
          setError({ apiError: extractErrorMessage(err) })
        }).finally(() => {
          if (fileEl.current) {
            fileEl.current.value = ''
          }
          setLoading(false)
        })
    }
    reader.onerror = function (error) {
      setError('Error while reading the file, please try again or choose another file')
      setLoading(false)
      console.log(error)
      if (fileEl.current) {
        fileEl.current.value = ''
      }
    }
  }

  return (
    <FormGroup>
      {loading && <Loading />}
      <Input
        type='file' name='file' id='thumbnailImage'
        innerRef={fileEl}
        accept='image/png,image/jpeg,image/gif'
        onChange={onChange}
        disabled={loading}
        invalid={!!error}
      />
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  )
}
