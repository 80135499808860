import React, { useRef, useEffect } from 'react'
import { FormGroup, FormFeedback, Input } from 'reactstrap'

export default function SimpleInput ({ value, setValue, error, focus }) {
  const inputEl = useRef(null)

  useEffect(() => {
    if (focus && inputEl.current) {
      inputEl.current.focus()
    }
  }, [focus])

  return (
    <FormGroup>
      <Input
        type='string'
        name='property'
        placeholder='Enter the value'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        invalid={!!error}
        innerRef={inputEl}
      />
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  )
}
