// Text input to use Reactstrap with Formik
import React from 'react'
import { Label, Input, FormGroup, FormFeedback } from 'reactstrap'

export default function TextInput ({ type, id, label, error, value, onChange, className, ...props }) {
  return (
    <FormGroup>
      <Label for={id}>{label}</Label>
      <Input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
        invalid={!!error}
      />
      <FormFeedback>
        {error}
      </FormFeedback>
    </FormGroup>
  )
}
