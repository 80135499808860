import {
  LOAD_FAQS_REQUEST,
  LOAD_FAQS_SUCCESS,
  LOAD_FAQS_FAILURE,
  CREATE_FAQ_REQUEST,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_FAILURE,
  UPDATE_FAQ_REQUEST,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAILURE,
  REMOVE_FAQ_REQUEST,
  REMOVE_FAQ_SUCCESS,
  REMOVE_FAQ_FAILURE,
  CREATE_FAQ,
  UPDATE_FAQ,
  REMOVE_FAQ
} from 'Actions/FAQActions'

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  createError: null,
  createLoading: false,
  updateError: null,
  updateLoading: false,
  removeError: null,
  removeLoading: false,
  loading: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FAQS_REQUEST:
      return { ...state, listLoading: true, listError: null }
    case LOAD_FAQS_SUCCESS:
      return { ...state, listLoading: false, list: action.list }
    case LOAD_FAQS_FAILURE:
      return { ...state, listLoading: false, listError: action.error }
    case CREATE_FAQ_REQUEST:
      return { ...state, createLoading: true, createError: null }
    case CREATE_FAQ_SUCCESS:
      return { ...state, createLoading: false }
    case CREATE_FAQ_FAILURE:
      return { ...state, createLoading: false, createError: action.error }
    case UPDATE_FAQ_REQUEST:
      return { ...state, updateLoading: true, updateError: null }
    case UPDATE_FAQ_SUCCESS:
      return { ...state, updateLoading: false }
    case UPDATE_FAQ_FAILURE:
      return { ...state, updateLoading: false, updateError: action.error }
    case REMOVE_FAQ_REQUEST:
      return { ...state, removeLoading: true, removeError: null }
    case REMOVE_FAQ_SUCCESS:
      return { ...state, removeLoading: false }
    case REMOVE_FAQ_FAILURE:
      return { ...state, removeLoading: false, removeError: action.error }
    case CREATE_FAQ:
      return {
        ...state,
        list: [
          ...state.list,
          action.faq
        ]
      }
    case UPDATE_FAQ:
      const newFaqs = state.list
        .map(faq => faq.objectId && faq.objectId === action.faq.objectId ? action.faq : faq)
        .sort((a, b) => a.order - b.order)
      return { ...state, list: newFaqs }
    case REMOVE_FAQ:
      const subFaqs = state.list.filter(faq => !faq.objectId || faq.objectId !== action.objectId)
      return { ...state, list: subFaqs }
    default:
      return state
  }
}