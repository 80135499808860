var audioCtx = new (window.AudioContext || window.webkitAudioContext)()
var analyser = audioCtx.createAnalyser()

var AudioContext = {
  getAudioContext: function getAudioContext () {
    return audioCtx
  },
  getAnalyser: function getAnalyser () {
    return analyser
  },
  resetAnalyser: function resetAnalyser () {
    analyser = audioCtx.createAnalyser()
  },
  decodeAudioData: function decodeAudioData () {
    audioCtx.decodeAudioData(window.audioData).then(function (decodedData) {
      // use the decoded data here
      console.log('decodedData: ', decodedData)
    })
  }
}

export default AudioContext
