import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  CHANGE_USERS_PAGE,
  SELECT_USERS,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAILURE,
  SEARCH_USERS
} from 'Actions/AdminUserActions'

const initialState = {
  page: 0, // page number starts from 0
  perPage: 20,
  loading: false,
  total: 0,
  rows: [],
  selection: [],
  error: null,
  deleteError: null,
  search: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      })
    case GET_ALL_USERS_SUCCESS:
      let connectionsByUser = action.connections.reduce((res, connection) => {
        if (connection.fromPerson) {
          let fromId = connection.fromPerson.objectId
          if (res[fromId] === undefined) {
            res[fromId] = []
          }
          res[fromId].push(connection)
        }
        if (connection.toPerson) {
          let toId = connection.toPerson.objectId
          if (res[toId] === undefined) {
            res[toId] = []
          }
          res[toId].push(connection)
        }
        return res
      }, {})
      return Object.assign({}, state, {
        loading: false,
        rows: action.users.map(user => ({
          ...user,
          connections: connectionsByUser[user.objectId] || []
        })),
        total: action.total
      })
    case GET_ALL_USERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        rows: [],
        total: 0,
        error: action.error
      })
    case CHANGE_USERS_PAGE:
      return Object.assign({}, state, {
        page: action.page
      })
    case SELECT_USERS:
      return Object.assign({}, state, {
        selection: action.selection
      })
    case SEARCH_USERS:
      return Object.assign({}, state, {
        search: action.search
      })
    case DELETE_USERS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        deleteError: null
      })
    case DELETE_USERS_SUCCESS:
      return Object.assign({}, state, {
        selection: []
      })
    case DELETE_USERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        deleteError: action.error
      })

    default:
      return state
  }
}
