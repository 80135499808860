import React, { useCallback, useState } from 'react'
import { useAsync } from 'react-async'
import { Button } from 'reactstrap'
import { CSSTransition } from 'react-transition-group'
import HideCookieConsent from 'Components/General/HideCookieconsent'
import { connectionDeepLink } from 'Modules/DeepLink'
import logo from 'assets/logo_green_tm.png'
import 'css/staticpage.scss'

const loadConnectionData = ({ connectionId }) => new Promise(resolve => {
  // If user is authenticated we might make API request to get contact detils here
  setTimeout(() => resolve(), 500)
})

export default ({ match }) => {
  const connectionId = match.params.connectionId

  const { isLoading } = useAsync(loadConnectionData, { connectionId })

  const [showQrButton, setShowQrButton] = useState(false)

  const openInApp = useCallback(() => {
    // @todo on desktop implement different logic
    const url = connectionDeepLink(connectionId)
    window.location = url
  }, [connectionId])

  return (
    <>
      <HideCookieConsent />
      <CSSTransition
        in={isLoading}
        timeout={300}
        classNames='brand-background'
        unmountOnExit
        appear
        onExited={() => setShowQrButton(true)}
      >
        <div className='full-screen brand-background '>
          <p className='primary-text'>Loading Contact data...</p>
        </div>
      </CSSTransition>
      { showQrButton && (
        <div className='full-screen'>
          <div className='d-flex flex-column align-items-center'>
            <img src={logo} alt='HeresMyInfo' width='250' />
            <p className='p-3 pt-4 text-secondary text-center'>Install HeresMyInfo to see your contact details.</p>
            <Button color='primary' size='lg' onClick={openInApp}>Open contact in Mobile App</Button>
          </div>
        </div>
      )}
    </>
  )
}
