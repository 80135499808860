import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Main from './Main'
import New from './New'
import View from './View'
import PropertyStatus from './PropertyStatus'

class Business extends Component {
  render () {
    return (
      <>
        <Helmet>
          <title>My Businesses</title>
        </Helmet>
        <Switch>
          <Route exact path='/business' component={Main} />
          <Route exact path='/business/new' component={New} />
          <Route path='/business/:businessId/status/:propertyId' component={PropertyStatus} />
          <Route path='/business/:businessId' component={View} />
        </Switch>
      </>
    )
  }
}

export default Business
