import React, { useState, useRef, useEffect } from 'react'
import { FormGroup, FormFeedback } from 'reactstrap'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import 'react-day-picker/lib/style.css'

export const FORMAT = 'MMM do yyyy'

// Values to show month and year select dropdowns
const currentYear = new Date().getFullYear()
const fromMonth = new Date(currentYear - 100, 0)
const toMonth = new Date()

// Method to show month and year select dropdowns
function YearMonthForm ({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths()

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleChange = (e) => {
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <div className='DayPicker-Caption'>
      <select name='month' onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name='year' onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  )
}

// Support for date format
export function parseDate (str, format, locale) {
  try {
    const parsed = dateFnsParse(str, format, toMonth, { locale })
    // Pass the date only if it is strictly equal to original. This will allow to edit date in place
    // Without this check it will replace 'Jun 5th 1' with 'Jun 5th 01' and you won't be able to easily change the year
    if (DateUtils.isDate(parsed) && formatDate(parsed, format, locale) === str) {
      return parsed
    }
  } catch (e) {
    return undefined
  }
  return undefined
}

function formatDate (date, format, locale) {
  return dateFnsFormat(date, format, { locale })
}

export default function DateInput ({ value, setValue, error }) {
  const [captionMonth, handleYearMonthChange] = useState(toMonth)
  const hasError = !!error

  const dateEl = useRef(null)

  useEffect(() => {
    if (dateEl.current) {
      dateEl.current.getInput().focus()
    }
  }, [])

  return (
    <FormGroup>
      <DayPickerInput
        format={FORMAT}
        onDayChange={date => {
          if (DateUtils.isDate(date)) {
            setValue(dateFnsFormat(date, FORMAT))
          }
        }}
        inputProps={{ className: hasError ? 'form-control is-invalid' : 'form-control' }}
        value={value}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder='Choose date'
        dayPickerProps={{
          disabledDays: {
            after: new Date()
          },
          month: captionMonth,
          captionElement: ({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={handleYearMonthChange}
            />
          )
        }}
        ref={dateEl}
      />
      { hasError && <FormFeedback className='d-block'>{error}</FormFeedback>}
    </FormGroup>
  )
}
