import React, { useMemo } from 'react'

export default ({ codes, title = 'Download', filename = 'qrcodes' }) => {
  const csv = useMemo(() => {
    let str = `code,url,label\r\n`
    codes.forEach(({ QRCode, label }) => {
      str += `${QRCode},https://hmiqr.com/qr/${QRCode},${label}\r\n`
    })
    return str
  }, [codes])

  const url = useMemo(() => {
    /* global Blob */
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    return URL.createObjectURL(blob)
  }, [csv])

  const download = useMemo(() => {
    return filename.replace(/ /g, '_').replace(/\W/gi, '') + '.csv'
  }, [filename])

  return (
    <a href={url} download={download}>{title}</a>
  )
}
