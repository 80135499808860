import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { connect } from 'react-redux'
import { acceptCookieConsent } from 'Actions/Settings'

const cookieConsent = function ({ cookieConsent, acceptCookieConsent }) {
  return cookieConsent !== true && (
    <CookieConsent
      style={{ background: '#373A40' }}
      disableButtonStyles
      buttonClasses='btn btn-primary btn-lg m-3'
      onAccept={acceptCookieConsent}
      cookieName='CookieConsent'
    >
      To make HeresMyInfo work, we log user data and share it with processors.{' '}
      To use HeresMyInfo, you must agree to our{' '}
      <a href='/privacy' target='_blank'>Privacy Policy</a>
      , including cookie policy.
    </CookieConsent>
  )
}

const mapStateToProps = (state) => ({
  cookieConsent: state.settings.cookieConsent
})

const mapDispatchToProps = {
  acceptCookieConsent
}

export default connect(mapStateToProps, mapDispatchToProps)(cookieConsent)
