import { Cloud, File } from 'Modules/ParseModule'

export const CREATE_BUSINESS_REQUEST = 'CREATE_BUSINESS_REQUEST'
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS'
export const CREATE_BUSINESS_FAILURE = 'CREATE_BUSINESS_FAILURE'

export const LOAD_BUSINESSES_REQUEST = 'LOAD_BUSINESSES_REQUEST'
export const LOAD_BUSINESSES_SUCCESS = 'LOAD_BUSINESSES_SUCCESS'
export const LOAD_BUSINESSES_FAILURE = 'LOAD_BUSINESSES_FAILURE'

export const LOAD_ONE_BUSINESS_REQUEST = 'LOAD_ONE_BUSINESS_REQUEST'
export const LOAD_ONE_BUSINESS_SUCCESS = 'LOAD_ONE_BUSINESS_SUCCESS'
export const LOAD_ONE_BUSINESS_FAILURE = 'LOAD_ONE_BUSINESS_FAILURE'

export const CREATE_BUSINESS_PROPERTY = 'CREATE_BUSINESS_PROPERTY'
export const UPDATE_BUSINESS_PROPERTY = 'UPDATE_BUSINESS_PROPERTY'

export const MENU_COLLAPSE = 'MENU_COLLAPSE'

// Convert linked name property to flat string
export const flatName = (business) => business.get('name').get('value')

export const load = () => dispatch => {
  dispatch({ type: LOAD_BUSINESSES_REQUEST })

  return Cloud.run('getBusinesses')
    .then(({ businesses }) => {
      console.log('BusinessActions getBusinesses:', businesses.map(b => ({ ...b.toJSON(), name: flatName(b) })))
      dispatch({
        type: LOAD_BUSINESSES_SUCCESS,
        list: businesses.map(b => ({ ...b.toJSON(), name: flatName(b) }))
      })
    })
    .catch(error => dispatch({ type: LOAD_BUSINESSES_FAILURE, error }))
}

export const loadOne = id => dispatch => {
  dispatch({ type: LOAD_ONE_BUSINESS_REQUEST })

  return Cloud.run('getBusiness', { id })
    .then(({ business, templatePath }) => {
      dispatch({
        type: LOAD_ONE_BUSINESS_SUCCESS,
        business: { ...business.toJSON(), name: flatName(business), templatePath }
      })
    })
    .catch(error => dispatch({ type: LOAD_ONE_BUSINESS_FAILURE, error }))
}

export const create = (name, domain) => dispatch => {
  dispatch({ type: CREATE_BUSINESS_REQUEST })

  return Cloud.run('createBusiness', { name, domain })
    .then(({ business }) => {
      business = { ...business.toJSON(), name: flatName(business) }
      dispatch({
        type: CREATE_BUSINESS_SUCCESS,
        business
      })
      return business
    })
    .catch(error => {
      dispatch({ type: CREATE_BUSINESS_FAILURE, error })
      throw error
    })
}

export const createProperty = (businessId, name, value, rest) => dispatch => {
  return Cloud.run('newBusinessProperty', { businessId, name, value, rest })
    .then(({ property }) => {
      property = property.toJSON()
      dispatch({ type: CREATE_BUSINESS_PROPERTY, property, businessId })
      return property
    })
}

export const editProperty = (id, value, rest) => dispatch => {
  return Cloud.run('editBusinessProperty', { id, value, rest })
    .then(({ property }) => {
      property = property.toJSON()
      dispatch({ type: UPDATE_BUSINESS_PROPERTY, property })
      return property
    })
}

export const verifyProperty = (propertyId, code) => dispatch => {
  return Cloud.run('verifyBusinessProperty', { id: propertyId, code })
    .then(({ property, success }) => {
      property = property.toJSON()
      dispatch({ type: UPDATE_BUSINESS_PROPERTY, property })
      return { property, success }
    })
}

export const initPropertyVerification = (propertyId) => dispatch => {
  return Cloud.run('dispatchBusinessPropertyVerification', { id: propertyId })
    .then(({ property }) => {
      property = property.toJSON()
      dispatch({ type: UPDATE_BUSINESS_PROPERTY, property })
      return property
    })
}

export const uploadLogo = (businessId, name, file) => dispatch => {
  return Cloud.run('getBusiness', { id: businessId })
    .then(() => {
      const parseFile = new File(name, file)
      return parseFile.save()
    })
    .then(parseFile => Cloud.run('storeBusinessLogo', { id: businessId, parseFile }))
    .then(({ business, templatePath }) => {
      dispatch({
        type: LOAD_ONE_BUSINESS_SUCCESS,
        business: { ...business.toJSON(), name: flatName(business), templatePath }
      })
      return business
    })
}

export const setIsOpen = (close) => dispatch => {
  dispatch({ type: MENU_COLLAPSE, close })
}
