import React from 'react'
import { Button } from 'reactstrap'

import useOAuthPopup from './useOAuthPopup'

export default ({ provider, title = null }) => {
  const { startOauth } = useOAuthPopup(provider)
  return (
    <div className='auth-button'>
      <Button onClick={startOauth} className='my-1 social-login-buttons'>
        { title || `Login with ${provider}` }
      </Button>
    </div>
  )
}
