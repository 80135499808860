import { COOKIE_CONSENT, LOCAL_FLAG_COOKIE_CONSENT } from 'Actions/Settings'
import { Cookies } from 'react-cookie-consent'

const initialState = {
  cookieConsent: Cookies.get('CookieConsent') === 'true',
  localHideCookieConsent: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COOKIE_CONSENT:
      return { ...state, cookieConsent: true }
    case LOCAL_FLAG_COOKIE_CONSENT:
      return { ...state, localHideCookieConsent: action.hide || false }
    default:
      return state
  }
}
