import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE
} from 'Actions/PersonalActions'

const initialState = {
  loading: false,
  error: null,
  connections: {
    outgoing: null,
    incoming: null
  }

}

export default (state = initialState, action) => {
  let names
  let connectionsByDirection
  switch (action.type) {
    case GET_USER_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      })
    case GET_USER_SUCCESS:
      // Map names to user ids
      names = new Map(action.names.map(user => [
        user.objectId,
        user.profile.properties
          .filter(property => property.name === 'fn')
          .map(property => property.value)
          .join('; ')
      ]))

      connectionsByDirection = action.connections.reduce((res, connection) => {
        if (connection.fromPerson && connection.fromPerson.objectId === action.user.objectId) {
          if (connection.toPerson && names.has(connection.toPerson.objectId)) {
            connection.fullName = names.get(connection.toPerson.objectId)
          }
          res.outgoing.push(connection)
        } else {
          if (names.has(connection.fromPerson.objectId)) {
            connection.fullName = names.get(connection.fromPerson.objectId)
          }
          res.incoming.push(connection)
        }
        return res
      }, { outgoing: [], incoming: [] })
      return Object.assign({}, state, {
        loading: false,
        error: null,
        connections: connectionsByDirection
      })
    case GET_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      })
    default:
      return state
  }
}
