import React, { useCallback, useState, useMemo } from 'react'
import { useAsync } from 'react-async'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import queryString from 'query-string'
import { verifyEmail } from 'Actions/PersonalActions'
import HideCookieConsent from 'Components/General/HideCookieconsent'
import logo from 'assets/logo_green_tm.png'
import { lastLink } from 'Modules/DeepLink'
import 'css/staticpage.scss'

const VerifyEmail = ({ location, verifyEmail }) => {
  const qs = useMemo(() => queryString.parse(location.search), [location])

  const { error, isLoading } = useAsync(verifyEmail, { messageId: qs.id, token: qs.token })

  const [showContent, setShowContent] = useState(false)

  const [clickedOpenInApp, setClickedOpenInApp] = useState(false)

  const openInApp = useCallback(() => {
    window.location = lastLink()
    setClickedOpenInApp(true)
  }, [])

  return (
    <>
      <HideCookieConsent />
      <CSSTransition
        in={isLoading}
        timeout={300}
        classNames='brand-background'
        unmountOnExit
        appear
        onExited={() => setShowContent(true)}
      >
        <div className='full-screen brand-background '>
          <p className='primary-text'>Activating email...</p>
        </div>
      </CSSTransition>
      { showContent && (
        <div className='full-screen'>
          <div className='d-flex flex-column align-items-center'>
            <img src={logo} alt='HeresMyInfo' width='250' />
            { !error ? (
              <div>
              <p className='p-3 pt-4 text-secondary text-center'>Congratulations! Your email is activated.</p>
              <p className='p-3 pt-4 text-secondary text-center'>You can now send invitations, as well as receive them.</p>
              </div>
            ) : (
              <p className='p-3 pt-4 text-secondary text-center'>Ooops, there was some error while activating your email. Please go back to the app and try again.</p>
            )}
            <div className='d-block d-md-none'>
              <Button color='primary' size='lg' onClick={openInApp}>Open Mobile App</Button>
            </div>
            { clickedOpenInApp && (
              <p className='p-3 pt-4 text-secondary text-center'>Opening in the app... In nothing happens, try to go to <Link to='/'>web version</Link>.</p>
            )}
            <div className='d-none d-md-block'>
              <Link to='/'><Button color='primary' size='lg'>Open the site</Button></Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  verifyEmail: ({ messageId, token }) => {
    if (!messageId || !token) {
      return Promise.reject(new Error('Missing message id or token'))
    }
    return dispatch(verifyEmail(messageId, token))
  }
})

export default connect(null, mapDispatchToProps)(VerifyEmail)
