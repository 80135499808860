import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react'
import initFaLibrary from 'Modules/FALibrary'
import * as Sentry from '@sentry/browser'
import AuthGate from 'Components/AuthGate'

//import 'css/toggle-bootstrap.min.css'
//import 'css/bootstrap-dark.css'
import 'css/dist/css/bootstrap.css'
import './css/hmi.css'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://6dc2099365d9492fb82e1c60762486fb@sentry.io/1460557',
    environment: process.env.NODE_ENV
  })
}

initFaLibrary()
const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthGate>
        <App />
      </AuthGate>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
