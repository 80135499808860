import React from 'react'
import {
  Col, Label, Input, Form,
  FormGroup, FormFeedback, Button
} from 'reactstrap'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { useAsync } from 'react-async'
import './SignUpForm.scss'
import TextInput from '../Form/TextInput'
import PhoneInput from '../Form/PhoneInput'

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required.'),
    lastName: Yup.string()
      .required('Last name is required.'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
    password: Yup.string()
      .min(4, 'Password has to be longer than 4 characters!')
      .required('Password is required!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords are not the same!')
      .required('Password confirmation is required!'),
    consent: Yup.boolean()
      .oneOf([true], 'You have to agree with our Terms and Conditions!')
      .required('You have to agree with our Terms and Conditions!')
  }),

  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    countryCode: '',
    consent: false
  }),
  handleSubmit: (payload, { setSubmitting, props, setStatus, setFieldError }) => {
    const phone = payload.phone ? '+' + payload.phone.replace(/[^\d]/g, '') : ''
    const userinfo = { ...payload, phone }
    setStatus({ })
    props.signUp(userinfo)
      .then((user) => {
        setSubmitting(false)
      })
      .catch(e => {
        const err = e.message
        if (err === 'Account already exists for this email address.') {
          setFieldError('email', err)
        } else if (err === 'Account already exists for this email/username.') {
          setFieldError('email', 'Account already exists for this email address.')
        } else if (err === 'Account already exists for this phone number.') {
          setFieldError('phone', err)
        } else if (typeof err !== 'string') {
          setStatus({ apiError: 'Internal server error. Please try again later.' })
        } else {
          setStatus({ apiError: err })
        }
        setSubmitting(false)
      })
  },
  displayName: 'SignUpForm'
})

const SignUpForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    status,
    loading,
    getCountryByIP,
    setIsOpen
  } = props

  const { data: countryCodeByIp } = useAsync(getCountryByIP)
  const defaultCountry = values.countryCode || (countryCodeByIp || {}).countryCode || ''

  // Capitalize first and last name first letter
  const handleChangeUcFirst = (event) => {
    let val = event.target.value
    if (val.length === 0) return handleChange(event)
    val = val.charAt(0).toUpperCase() + val.slice(1)
    if (val !== event.target.value) {
      event.target.value = val
    }
    return handleChange(event)
  }

  return (
    <Form className='form' onSubmit={handleSubmit} onFocus={() => setIsOpen(false)}>
      <Col>
        <TextInput
          id='firstName'
          type='text'
          label='First Name'
          placeholder='First Name'
          error={touched.firstName && errors.firstName}
          value={values.firstName}
          onChange={handleChangeUcFirst}
          onBlur={handleBlur}
        />
      </Col>

      <Col>
        <TextInput
          id='lastName'
          type='text'
          label='Last Name'
          placeholder='Last Name'
          error={touched.lastName && errors.lastName}
          value={values.lastName}
          onChange={handleChangeUcFirst}
          onBlur={handleBlur}
        />
      </Col>

      <Col>
        <TextInput
          id='email'
          type='email'
          label='Email'
          placeholder='Enter your email'
          error={touched.email && errors.email}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Col>

      <Col>
        <TextInput
          id='password'
          type='password'
          label='Password'
          placeholder='Enter the password'
          error={touched.password && errors.password}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Col>

      <Col>
        <TextInput
          id='confirmPassword'
          type='password'
          label='Confirm password'
          placeholder='Repeat the password'
          error={touched.confirmPassword && errors.confirmPassword}
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Col>

      <Col>
        <PhoneInput
          id='phone'
          label='Enter mobile phone number'
          placeholder='Enter phone number'
          error={touched.phone && errors.phone}
          value={values.phone}
          country={defaultCountry}
          onChange={val => setFieldValue('phone', val)}
          onCountryChange={country => setFieldValue('countryCode', country)}
          onBlur={handleBlur}
        />
      </Col>

      <Col>
        <FormGroup check>
          <Label check>
            <Input
              type='checkbox'
              onChange={handleChange}
              id='consent'
              checked={values.consent}
              invalid={touched.consent && errors.consent}
            />{' '}
            I agree to <a href='/legal/terms_and_conditions.pdf' target='_blank'>Terms and Conditions</a>, <a href='/privacy' target='_blank'>Privacy Policy</a> and <a href='/legal/end_user_license_agreement.pdf' target='_blank'>User License Agreement</a>
            <FormFeedback>
              {errors.consent}
            </FormFeedback>
          </Label>
        </FormGroup>
      </Col>

      { status && status.apiError && (
        <Col>
          <FormGroup>
            <FormFeedback className='d-block'>{status.apiError}</FormFeedback>
          </FormGroup>
        </Col>
      )}
      <Col className='text-center mt-3'>
        <Button color='primary' type='submit' size='lg' disabled={isSubmitting || loading} className='px-5'>Next</Button>
      </Col>
    </Form>
  )
}

export default formikEnhancer(SignUpForm)
