import { Cloud } from 'Modules/ParseModule'

export const CREATE_FAQ_REQUEST = 'CREATE_FAQ_REQUEST'
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS'
export const CREATE_FAQ_FAILURE = 'CREATE_FAQ_FAILURE'

export const LOAD_FAQS_REQUEST = 'LOAD_FAQS_REQUEST'
export const LOAD_FAQS_SUCCESS = 'LOAD_FAQS_SUCCESS'
export const LOAD_FAQS_FAILURE = 'LOAD_FAQS_FAILURE'

export const UPDATE_FAQ_REQUEST = 'UPDATE_FAQ_REQUEST'
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS'
export const UPDATE_FAQ_FAILURE = 'UPDATE_FAQ_FAILURE'

export const REMOVE_FAQ_REQUEST = 'REMOVE_FAQ_REQUEST'
export const REMOVE_FAQ_SUCCESS = 'REMOVE_FAQ_SUCCESS'
export const REMOVE_FAQ_FAILURE = 'REMOVE_FAQ_FAILURE'

export const CREATE_FAQ = 'CREATE_FAQ'
export const UPDATE_FAQ = 'UPDATE_FAQ'
export const REMOVE_FAQ = 'REMOVE_FAQ'

export const load = () => dispatch => {
  dispatch({ type: LOAD_FAQS_REQUEST })

  return Cloud.run('getFaqs')
    .then(({ faqs }) => {
      console.log('getFaqs:', faqs)
      if (faqs.length < 1) {
        console.log('faqs are empty')
      }
      // console.log("getFaqs: ",faqs)
      const faqsJson = faqs.map(code => code.toJSON()).sort((a, b) => a.order - b.order)
      // console.log("faqs: ",faqsJson)
      dispatch({
        type: LOAD_FAQS_SUCCESS,
        list: faqsJson
      })
    })
    .catch(error => dispatch({ type: LOAD_FAQS_FAILURE, error }))
}

export const createFaq = (title, contentObj, order) => dispatch => {
  dispatch({ type: CREATE_FAQ_REQUEST })

  const content = JSON.stringify(contentObj)
  return Cloud.run('createFAQ', { title, content, order })
    .then(({ result }) => {
      // console.log('createFAQ: ',result)
      const faq = result.toJSON()
      
      // console.log('faq: ',faq)
      dispatch({ type: CREATE_FAQ, faq })
      dispatch({ type: CREATE_FAQ_SUCCESS })
    })
    .catch(error => dispatch({ type: CREATE_FAQ_FAILURE, error }))
}

// const FAQsample = {
//   title: '[Edit to Change Question]',
//   content: '["[Placeholder]"]',
//   order: 1
// }

export const updateFaq = (objectId, title, contentObj, strOrder) => dispatch => {
  dispatch({ type: UPDATE_FAQ_REQUEST })
  let newContent = contentObj.split('\n')
  newContent = newContent.filter(data => data !== '')
  const content = JSON.stringify(newContent)
  const order = parseInt(strOrder)
  return Cloud.run('editFaq', { objectId, title, content, order })
    .then(({ result }) => {
      // console.log('editFaq: ',result)
      const faq = result.toJSON()
      dispatch({ type: UPDATE_FAQ, faq })
      dispatch({ type: UPDATE_FAQ_SUCCESS })
    })
    .catch(error => dispatch({ type: UPDATE_FAQ_FAILURE, error }))
}

export const removeFaq = (objectId) => dispatch => {
  dispatch({ type: REMOVE_FAQ_REQUEST })
  return Cloud.run('removeFaq', { objectId })
    .then(({ result }) => {
      console.log('removeFaq: ', result)
      dispatch({ type: REMOVE_FAQ, objectId })
      dispatch({ type: REMOVE_FAQ_SUCCESS })
    })
    .catch(error => dispatch({ type: REMOVE_FAQ_FAILURE, error }))
}
