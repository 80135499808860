import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { localHideCookieConsent } from 'Actions/Settings'
import 'css/staticpage.scss'

const HideCookieConsent = ({ localHideCookieConsent }) => {
  useEffect(() => {
    localHideCookieConsent(true)
    return () => {
      localHideCookieConsent(false)
    }
  }, [localHideCookieConsent])

  return <></>
}

const mapDispatchToProps = {
  localHideCookieConsent
}

export default connect(null, mapDispatchToProps)(HideCookieConsent)
