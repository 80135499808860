import React, { Component } from 'react'
import { Input, FormGroup, FormFeedback, Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PhoneInput from 'react-phone-number-input'

class SinglePropertyForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      propertyValue: props.value || '',
      propertyIsInvalid: false,
      loading: false,
      errorMsg: '',
      rest: props.rest || {}
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  handleChange (event) {
    this.setState({ propertyValue: event.target.value })
  }

  handleKeyUp (event) {
    if (event.key === 'Enter') {
      this.submit()
    }
    if (event.key === 'Escape') {
      this.props.cancel()
    }
  }

  handleSubmit () {
    this.setState(
      {
        loading: true,
        propertyIsInvalid: false
      },
      () => {
        this.props
          .submit(this.state.propertyValue, this.state.rest)
          .then()
          .catch((error) => {
            const errorMsg =
              error.message && typeof error.message === 'string'
                ? error.message
                : 'Invalid property value'
            this.setState({ propertyIsInvalid: true, errorMsg, loading: false })
          })
      }
    )
  }

  render () {
    const { propertyValue, propertyIsInvalid, errorMsg, loading } = this.state
    const { cancel, type } = this.props
    console.log('type', type)
    return (
      <FormGroup>
        <Row>
          <Col xs={9}>
            {type !== 'phoneNumbers' && type !== 'theme' && type !== 'businessDescription' && (
              <Input
                type='string'
                name='property'
                id='property'
                placeholder='Enter the value'
                value={propertyValue}
                invalid={propertyIsInvalid}
                onChange={this.handleChange}
                onKeyUp={this.handleKeyUp}
              />
            )}
            {type === 'businessDescription' && (
              <Input
                type='textarea'
                name='property'
                id='propertytextarea'
                placeholder='Enter the value'
                value={propertyValue}
                invalid={propertyIsInvalid}
                onChange={this.handleChange}
                onKeyUp={this.handleKeyUp}
              />
            )}
            {type === 'phoneNumbers' && (
              <>
                <PhoneInput
                  placeholder='Enter phone number'
                  id='property'
                  value={propertyValue}
                  onChange={(value) => this.setState({ propertyValue: value })}
                  inputClassName={
                    propertyIsInvalid
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  onCountryChange={(country) => {
                    this.setState((state) => ({
                      rest: { ...state.rest, countryCode: country }
                    }))
                  }}
                  onKeyUp={this.handleKeyUp}
                />
                <div className='pt-2'>
                  <Input
                    type='select'
                    name='type'
                    value={this.state.rest.type || 'mobile'}
                    onChange={(e) => {
                      const type = e.target.value
                      this.setState((state) => ({
                        rest: { ...state.rest, type }
                      }))
                    }}
                  >
                    <option value='' disabled>
                      -- Choose type --
                    </option>
                    <option value='mobile'>mobile</option>
                    <option value='landline'>landline</option>
                  </Input>
                </div>
              </>
            )}
          </Col>
          <Col xs={3} className='pl-0'>
            <Button
              color='link'
              aria-label='Save'
              onClick={this.handleSubmit}
              disabled={loading}
            >
              <FontAwesomeIcon icon='check' />
            </Button>
            <Button
              color='link'
              aria-label='Cancel'
              onClick={cancel}
              className='text-muted'
              disabled={loading}
            >
              <FontAwesomeIcon icon='times' />
            </Button>
          </Col>
        </Row>
        <FormFeedback className={propertyIsInvalid ? 'd-block' : ''}>
          {errorMsg || 'Invalid property value'}
        </FormFeedback>
      </FormGroup>
    )
  }
}

export default SinglePropertyForm
