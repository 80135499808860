import React, { useState, useEffect } from 'react'
import { Row, Button, Form } from 'reactstrap'
import Loading from 'Components/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FAQInputGroup from 'Components/FAQ/FAQInputGroup'

export default function FAQForm ({ initValue, faq, onSubmit, closeForm }) {
  const focus = true
  const [title, setTitle] = useState(initValue.title)
  const [content, setContent] = useState('')
  const [order, setOrder] = useState(initValue.order)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})

  useEffect(() => {
    const contentObj = JSON.parse(faq.content)
    const allcontent = contentObj.reduce((all, content) => {
      return all + content + '\n\n'
    }, [faq.content])
    setContent(allcontent)
  },[faq.content])

  console.log('FAQForm faq: ', faq)
  console.log('FAQForm title: ', title)
  console.log('FAQForm content: ', content)
  console.log('FAQForm order: ', order)

  const submit = () => {
    console.log('SUBMIT: ', faq)
    setLoading(true)
    setError({})
    onSubmit(title, content, order)
  }

  return (
    <Form onSubmit={e => {
      e.preventDefault()
      submit()
    }}
    >
      <Row>
        {loading && <Loading />}
        <div className='flex-grow-1 pl-2'>
          <FAQInputGroup
            title={title}
            content={content}
            order={order}
            setTitle={setTitle}
            setContent={setContent}
            setOrder={setOrder}
            error={error[faq.title]}
            focus={focus}
          />
        </div>
        <div className='align-self-end mb-2'>
          <Button
            color='link' aria-label='Save'
            disabled={loading}
            onClick={submit}
          >
            <FontAwesomeIcon icon='check' />
          </Button>
          <Button
            color='link' aria-label='Cancel'
            className='text-muted'
            onClick={closeForm}
            disabled={loading}
          >
            <FontAwesomeIcon icon='times' />
          </Button>
        </div>
      </Row>
    </Form>
  )
}
