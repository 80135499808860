import React, { Component } from 'react'
import VerificationWrapper from './VerificationWrapper'

export default function withVerification (WrappedComponent) {
  return class extends Component {
    render () {
      return <VerificationWrapper component={WrappedComponent} otherProps={this.props} />
    }
  }
}
