import { Cloud } from 'Modules/ParseModule'
import debounce from 'lodash.debounce'

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE'

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'

export const CHANGE_USERS_PAGE = 'CHANGE_USERS_PAGE'
export const SELECT_USERS = 'SELECT_USERS'
export const SEARCH_USERS = 'SEARCH_USERS'

export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE'

export const DELETE_CONNECTIONS_REQUEST = 'DELETE_CONNECTIONS_REQUEST'
export const DELETE_CONNECTIONS_SUCCESS = 'DELETE_CONNECTIONS_SUCCESS'
export const DELETE_CONNECTIONS_FAILURE = 'DELETE_CONNECTIONS_FAILURE'

export const TOGGLE_USER_REQUEST = 'TOGGLE_USER_REQUEST'
export const TOGGLE_USER_SUCCESS = 'TOGGLE_USER_SUCCESS'
export const TOGGLE_USER_FAILURE = 'TOGGLE_USER_FAILURE'

const getAllUsersSuccess = (users, total, connections) => ({
  type: GET_ALL_USERS_SUCCESS,
  users,
  total,
  connections
})

const getUserSuccess = (user, connections, names) => ({
  type: GET_USER_SUCCESS,
  user,
  connections,
  names
})

export const changePage = page => dispatch => {
  dispatch({ type: CHANGE_USERS_PAGE, page })

  dispatch(getAllUsers())
}

export const getAllUsers = () => (dispatch, getState) => {
  dispatch({ type: GET_ALL_USERS_REQUEST })
  const { admin: { users: { perPage, page, search } } } = getState()

  let users, total, connections

  return Cloud.run('getUsersWithProperties', { perPage, page, search })
    .then(res => {
      total = res.total
      users = res.users.map(u => u.toJSON())
      return Cloud.run('getConnectionsForUsers', { users: users.map(u => u.objectId) })
    })
    .then(res => {
      connections = res.connections.map(c => c.toJSON())
      dispatch(getAllUsersSuccess(users, total, connections))
    })
    .catch(error => dispatch({ type: GET_ALL_USERS_FAILURE, error }))
}

const getAllUsersDebounced = debounce(dispatch => dispatch(getAllUsers()), 500)

export const select = selection => ({
  type: SELECT_USERS,
  selection
})

export const searchUsers = search => dispatch => {
  dispatch({ type: SEARCH_USERS, search })
  // Don't make API call if search term is shorter than 3 symbols
  // If search is empty - make API call to "reset" the filter
  if (search === '' || search.length >= 3) {
    getAllUsersDebounced(dispatch)
  }
}

export const deleteUsers = users => dispatch => {
  dispatch({ type: DELETE_USERS_REQUEST })

  return Cloud.run('deleteUsers', { users })
    .then(() => dispatch({ type: DELETE_USERS_SUCCESS }))
    .catch(error => dispatch({ type: DELETE_USERS_FAILURE, error }))
    .then(() => dispatch(getAllUsers()))
}

export const getSingleUser = (userId) => dispatch => {
  dispatch({ type: GET_USER_REQUEST })

  let user
  return Cloud.run('getUserWithProperties', { user: userId })
    .then(res => {
      user = res.user.toJSON()
      return Cloud.run('getConnectionsForUsers', { users: [userId], properties: ['fn'] })
    })
    .then(res => {
      let connections = res.connections.map(c => c.toJSON())
      let names = res.properties.map(c => c.toJSON())
      dispatch(getUserSuccess(user, connections, names))
    })
    .catch(error => dispatch({ type: GET_USER_FAILURE, error }))
}

export const deleteConnections = (userId, connections) => dispatch => {
  dispatch({ type: DELETE_CONNECTIONS_REQUEST })
  return Cloud.run('deleteConnections', { connections })
    .then(() => dispatch({ type: DELETE_CONNECTIONS_SUCCESS }))
    .catch(error => dispatch({ type: DELETE_CONNECTIONS_FAILURE, error }))
    .then(() => dispatch(getSingleUser(userId)))
}

export const toggleUser = (userId, makeActive) => dispatch => {
  dispatch({ type: TOGGLE_USER_REQUEST })

  let route = makeActive ? 'activateUser' : 'inactivateUser'
  return Cloud.run(route, { id: userId })
    .then(() => dispatch({ type: TOGGLE_USER_SUCCESS }))
    .catch((error) => dispatch({ type: TOGGLE_USER_FAILURE, error }))
}
