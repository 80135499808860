import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { load, createFaq, updateFaq, removeFaq } from '../../Actions/FAQActions'
import FAQItem from '../../Components/FAQ/FAQItem'

// load has no data

function FAQ ({ isAdmin, load, list, createFaq, updateFaq, removeFaq }) {
  useEffect(() => {
    load()
  },[load])

  const addfaqhandler = () => {
    const title = '[Edit to Change Question]'
    const content = ['[Placeholder]']

    let newOrder = 1

    if (list.length > 0) {
      newOrder = list[list.length - 1].order + 1
    }
    createFaq(title, content, newOrder)
  }
  const editfaqhandler = (id, title, content, order) => {
    updateFaq(id, title, content, order)
  }

  const removefaqhandler = (id) => {
    removeFaq(id)
  }
  return (
    <div>
      <div>
        {

          list.map((question) => {
            console.log('question: ', question)
            return (
              <FAQItem key={question.objectId} transitionTime={400} faqitem={question} setFAQ={editfaqhandler} deleteFAQ={removefaqhandler} isAdmin={isAdmin} />
            )
          })
        }
      </div>
      {isAdmin ? (
        <button className='btn btn-primary hmiBtn' onClick={addfaqhandler}>Add Question</button>
      ) : (<></>)}
    </div>
  )
}

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAuthenticated && state.auth.currentUser && state.auth.currentUser.isAdmin === true,
  error: state.faq.listError,
  loading: state.faq.listLoading,
  list: state.faq.list,
  faqTop: state.faq
})

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(load()),
  createFaq: (title, content, order) => dispatch(createFaq(title, content, order)),
  updateFaq: (id, title, content, order) => dispatch(updateFaq(id, title, content, order)),
  removeFaq: (id) => dispatch(removeFaq(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(FAQ)
