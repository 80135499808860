export const PROPERTY_EMAIL = 'emailAddresses'
export const PROPERTY_PHONE = 'phoneNumbers'
export const PROPERTY_NAME = 'company'
export const PROPERTY_DOMAIN = 'urlAddresses'
export const PROPERTY_THEME = 'theme'
export const PROPERTY_OPT_IN = 'businessOptIn'
export const BUSINESS_TAGLINE = 'businessTagline'
export const BUSINESS_DESCRIPTION = 'businessDescription'
export const BUSINESS_STREET_NUMBER = 'StreetNumber'
export const BUSINESS_STREET_NAME = 'StreetName'
export const BUSINESS_CITY = 'businessCity'
export const BUSINESS_STATE = 'businessState'
export const BUSINESS_ZIPCODE = 'businessZipcode'

export const propTitles = new Map([
  [PROPERTY_NAME, 'Name'],
  [PROPERTY_EMAIL, 'Email'],
  [PROPERTY_PHONE, 'Phone number'],
  [PROPERTY_DOMAIN, 'Domain'],
  [PROPERTY_THEME, 'Theme'],
  [PROPERTY_OPT_IN, 'businessOptIn'],
  [BUSINESS_TAGLINE, 'Tagline'],
  [BUSINESS_DESCRIPTION, 'Description'],
  [BUSINESS_STREET_NUMBER, 'Street Number'],
  [BUSINESS_STREET_NAME, 'Street Name'],
  [BUSINESS_CITY, 'City'],
  [BUSINESS_STATE, 'State'],
  [BUSINESS_ZIPCODE, 'Zipcode']
])
