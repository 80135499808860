import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { setProfileProperty } from 'Actions/PersonalActions'
import { sendVerificationEmail } from 'Actions/AuthActions'
import PropertyForm from 'Components/Personal/Property/PropertyForm'
import ImageBase64Input from 'Components/Personal/Property/propertyForm/ImageBase64Input'

function NewProperty ({ property, setProfileProperty, sendVerificationEmail }) {
  const initValue = useCallback(() => !property.fields || property.name === 'birthday'
    ? ''
    // Generating object dictionary from array with index keys (BC solution)
    // Used to fill in 'properties' in BE
    : Object.assign({},
      ...Object.entries(property.fields)
        .map(([name, field], index) => ({
          [index]: {
            ...field,
            name,
            // For labels and phone types preselect 1st option
            value: ['label', 'type'].includes(name) && field.types ? field.types[0] : ''
          }
        }))
    ),
  [property])

  const [showForm, setShowForm] = useState(false)
  const closeForm = useCallback(() => setShowForm(false), [])

  const onSubmit = useCallback((name, value) => setProfileProperty(name, value, null)
    .then(input => {
      if (name === 'emailAddresses') {
        sendVerificationEmail(value[1].value)
      }
      if (property.multiple) {
        setShowForm(false)
      }
      return input
    }),
  [setProfileProperty, property.multiple, sendVerificationEmail])

  // Custom logic for thumbnailImage - showing file upload button right away
  if (property.name === 'thumbnailImage') {
    return (
      <ImageBase64Input
        property={property}
        onSubmit={base64 => onSubmit(property.name, base64)}
      />
    )
  }

  if (!showForm) {
    return (
      <Button
        color='link' className='font-italic p-0'
        onClick={() => setShowForm(true)}
      >
        {property.multiple ? `(Add ${property.showName})` : '(Set value)'}
      </Button>
    )
  }
  // Show form
  return (
    <PropertyForm
      initValue={initValue}
      property={property}
      onSubmit={onSubmit}
      closeForm={closeForm}
    />
  )
}

const mapDispatchToProps = dispatch => ({
  setProfileProperty: (name, value, id) => dispatch(setProfileProperty(name, value, id)),
  sendVerificationEmail: email => dispatch(sendVerificationEmail(email))
})

export default connect(null, mapDispatchToProps)(NewProperty)
