import React, { useState, useCallback } from 'react'
import FAQcomp from '../FAQComp/FAQcomp'
import { Button, Row, Col } from 'reactstrap'
import FAQForm from 'Components/FAQ/FAQForm'

function FAQItem ({ isAdmin, faqitem, transitionTime, setFAQ, deleteFAQ }) {
  const id = faqitem.objectId
  const [showForm, setShowForm] = useState(false)
  const closeForm = useCallback(() => setShowForm(false), [])
  const onSubmit = useCallback(
    (localtitle, localcontent, localorder) => {
      setFAQ(faqitem.objectId, localtitle, localcontent, localorder)
      setShowForm(false)
    },
    [setFAQ, faqitem.objectId]
  )

  let xs = '8'
  let mdPlus = '9'
  if (!isAdmin) {
    xs = '12'
    mdPlus = '12'
  }

  const content = JSON.parse(faqitem.content)
  return (
    <div>
      {!showForm ? (
        <>
          <Row className='faq-items d-flex justify-content-between'>
            <Col xs={xs} md={mdPlus} lg={mdPlus} xl={mdPlus}>
              <FAQcomp transitionTime={transitionTime} trigger={faqitem.title}>
                {content.map((answer) => {
                  return <p key={answer}>{answer}</p>
                })}
              </FAQcomp>
            </Col>
            {isAdmin ? (
              <Col xs='4' md='3' lg='3' xl='3'>
                <Button color='link' onClick={() => setShowForm(true)}>
                  (Edit)
                </Button>
                <Button color='link' onClick={() => deleteFAQ(id)}>
                  (Delete)
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </>
      ) : (
        <FAQForm
          initValue={faqitem}
          faq={faqitem}
          onSubmit={onSubmit}
          closeForm={closeForm}
        />
      )}
    </div>
  )
}

export default FAQItem
