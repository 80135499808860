import React from 'react'
import { connect  } from 'react-redux'
import { Helmet } from 'react-helmet'
import Layout from 'Components/Layout'
import ProfileList from 'Components/Personal/ProfileList'
import { setIsOpen } from 'Actions/BusinessActions'
import PageHeader from 'Components/PageHeader/PageHeader'
import './Personal.css'

function Main({ profile, setIsOpen }) {
  const title = "Profile"
  const description = "This information is never shared with anyone until you put it in a Card."
 
  return (
    <Layout>
      <Helmet>
        <title>Profile - HeresMyInfo.com</title>
      </Helmet>
      <PageHeader title={title} description = {description}/>
      <ProfileList profile={profile} setIsOpen={setIsOpen}/>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  profile: state.auth.currentUser.profile,
  parse: state.auth.parse
})

const mapDispatchToProps = dispatch => ({
  setIsOpen : () => dispatch(setIsOpen(false))
})


export default connect(mapStateToProps,mapDispatchToProps)(Main)
