import React from 'react'

function ComplexValue ({ fields, value }) {
  const mapped = Object.assign({}, ...Object.entries(value.parameters).map(([_, item]) => ({ [item.name]: item.value })))
  return (
    <div>
      {Object.entries(fields)
        .filter(([key, field]) => !!field.showName)
        .map(([key, field]) => (
          <div key={key}>{field.showName}:{' '}
            {mapped[key]
              ? (key === 'url'
                ? <a href={mapped[key]} target='_blank' rel='noopener noreferrer'>{mapped[key]}</a>
                : mapped[key]
              )
              : key === 'type' ? 'mobile' : '-'}
          </div>
        ))}
    </div>
  )
}

export default function PropertyValue ({ propertyMeta, value }) {
  // console.log('PropertyValue propertyMeta: ', propertyMeta)
  switch (propertyMeta.name) {
    case 'thumbnailImage':
      console.log('CASE THUMBNAIL')
      let src = propertyMeta.value.value
      console.log('PropertyValue src: ', src)
      return <img src={src} alt={propertyMeta.showName} style={{ maxWidth: '200px' }} />

    // case 'howisaymyname':
    //   console.log('value', value)
    //   if (value != 'NONE') {
    //     src = value.value
    //     // console.log('howisaymyname src: ', src)
    //     // refactor to audio version
    //     if (!src.startsWith('data:text/html') && !src.startsWith('http')) {
    //       src = `data:text/html;base64,${src}`
    //     }
    //     // needs to return the audio file
    //     return src
    //   } else return ''

    case 'birthday':
      return <div>{value.value}</div>
    default:
      return propertyMeta.fields ? <ComplexValue fields={propertyMeta.fields} value={value} /> : <div>{value.value}</div>
  }
}
