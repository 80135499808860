import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Layout from 'Components/Layout'
import CircleList from 'Components/Personal/CircleList'
import PageHeader from 'Components/PageHeader/PageHeader'
import './Personal.css'


function Cards ({ profile, profileLoading }) {
  const title = "My Cards";
  const description = "You can share more than one card. Shared cards are merged together.";
  return (
    <Layout>
      <Helmet>
        <title>My Cards</title>
      </Helmet>
      <div className="cardPage">
      <PageHeader title={title} description = {description}/>
      <CircleList profile={profile} profileLoading={profileLoading} />
      </div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  profile: state.auth.currentUser.profile,
  profileLoading: state.auth.loading,
  parse: state.auth.parse
})

export default connect(mapStateToProps)(Cards)
