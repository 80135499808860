import { useMemo } from 'react'
import isMobile from 'ismobilejs'

// Wrapper for https://github.com/kaimallea/isMobile
function useIsMobile () {
  const userAgent = (typeof navigator !== 'undefined' ? navigator.userAgent : '')

  const memoizedIsMobile = useMemo(() => {
    return isMobile(userAgent).any
  }, [userAgent])

  return memoizedIsMobile
}

export default useIsMobile
