import React, { PureComponent } from 'react'
// import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Row, Col } from 'reactstrap'
import Layout from 'Components/Layout/Layout'

export default class BusinessLanding extends PureComponent {
  render () {
    return (
      <Layout>
        <Helmet>
          <title>HeresMyInfo is what a business cards would have been if they were invented today</title>
        </Helmet>
        <Row className='d-flex justify-content-center'>
          <Col
            className='d-flex flex-column flex-grow-1 justify-content-center align-items-center'
            col-md='8'
            sm='10'
            xs='12'
          >

            <h3 style={{ textAlign: 'center', marginTop: 30 }}>HeresMyInfo is what a business cards would have been if they were invented today.</h3>
            <h3 style={{ textAlign: 'center' }}>Put your business contact in everyone's phone.</h3>
            <h2 style={{ textAlign: 'center', textTransform: 'none', color: 'blue' }}>Check out our 'Learn' section about our business products.</h2>

          </Col>
        </Row>
      </Layout>
    )
  }
}
