import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Button, Row, Col } from 'reactstrap'
import { setProfileProperty } from 'Actions/PersonalActions'
import PropertyForm from 'Components/Personal/Property/PropertyForm'
// import ImageBase64Input from 'Components/Personal/Property/propertyForm/ImageBase64Input'
import AudioBase64Input from 'Components/Personal/Property/propertyForm/AudioBase64Input'
import DeleteProperty from 'Components/Personal/Property/propertyForm/DeleteProperty'
import PropertyValue from 'Components/Personal/Property/PropertyValue'
import { contactMetadata } from 'Modules/ContactModule'
import { Link } from 'react-router-dom'
import { ReactMic } from '../../../Modules/react-mic/es/components/ReactMic'
// react-mic-plus is pro version for $199 for all browser support
import HowISayMyName from '../../../assets/img/howisaymyname_wht.png'

const PropertyValueWithControl = ({ propertyMeta, value, setProfileProperty }) => {
  const [showForm, setShowForm] = useState(false)
  const initValue = () => {
    switch (propertyMeta.name) {
      case 'howisaymyname':
        return value.value
      case 'thumbnailImage':
        return value.value
      case 'birthday':
        return value.value
      case 'phoneNumbers':
        // Add default phone type value if it is not specified
        return Object.assign({},
          { 3: { ...contactMetadata.phoneNumbers.fields.type, value: 'mobile' } },
          value.parameters
        )
      default:
        return propertyMeta.fields ? value.parameters : value.value
    }
  }
  const [record, setRecord] = useState(false)
  const [blob, setBlob] = useState({})
  // const [blobURL, setBlobURL] = useState({})
  const [audioURL, setAudioURL] = useState({})

  const fetchIt = (blobURL) => {
    fetch(blobURL)
      .then(function (res) {
        res.blob().then(function (blob) {
          var reader = new window.FileReader()
          reader.addEventListener('loadend', function () {
            // Serialize the data to localStorage and read it back then play...
            var base64FileData = reader.result.toString()

            var mediaFile = {
              fileUrl: blobURL,
              size: blob.size,
              type: blob.type,
              src: base64FileData
            }
            window.localStorage.setItem('myBlob', JSON.stringify(mediaFile))
            // read out the file info from localStorage again
            var reReadItem = window.localStorage.getItem('myBlob')

            // save file in state as object
            setBlob(JSON.parse(reReadItem))
          })
          reader.readAsDataURL(blob)
        })
      })
  }

  const startRecording = () => {
    console.log('startRecording')
    setRecord(true)
  }

  const stopRecording = () => {
    console.log('stopRecording')
    setRecord(false)
    console.log('stopRecording blob: ', blob)
    // update value.value here?
  }

  const onData = (recordedBlob) => {
    // console.log('chunk of real-time data is: ', recordedBlob)
    fetchIt(recordedBlob.blobURL)
  }

  const onStop = (recordedBlob) => {
    console.log('recordedBlob is: ', recordedBlob)
    // const myBlob = fetch(recordedBlob.blobURL)
    console.log('recordedBlob.blobURL: ', recordedBlob.blobURL)
    // setBlobURL(recordedBlob.blobURL)
    setAudioURL(recordedBlob)
  }

  // const howISayMyName = (user) => {
  //   const propertiesArray = user.profile.properties
  //   const profileArray = Object.keys(propertiesArray).map(i => propertiesArray[i])

  //   function getSayName (item) {
  //     return item.name === 'howisaymyname'
  //   }
  //   const howISayMyNameString = profileArray.filter(getSayName)
  //   console.log('howISayMyNameString', howISayMyNameString)

  //   if (howISayMyNameString.length === 0) {
  //     window.localStorage.setItem('myAudioBlob', null)
  //   } else {
  //     console.log('howISayMyNameString', howISayMyNameString[0].value)
  //     window.localStorage.setItem('myAudioBlob', howISayMyNameString[0].value)
  //   }
  // }

  const onPlay = () => {
    const audio = new window.Audio(audioURL.blobURL)
    audio.play()
  }

  const closeForm = useCallback(() => setShowForm(false), [])

  const onSubmit = useCallback((name, localValue) => setProfileProperty(name, localValue, value.objectId)
    .then(input => {
      setShowForm(false)
      return input
    }),
  [setProfileProperty, value.objectId])

  if
  (propertyMeta.name === 'thumbnailImage') {
    // console.log('PropertyValueWithControl thumbnail property: ', property)
    console.log('RUN propertyValueWithControl if ThumbNailImage')
    console.log('PropertyValueWithControl propertyMeta: ', propertyMeta)
    console.log('PropertyValueWithControl value: ', value)

    return (
      <div>
        {!showForm ? (
          <Row>
            <Col xs='12' md='3'>{propertyMeta.showName}</Col>
            <Col xs='8' md='7'><PropertyValue propertyMeta={propertyMeta} value={value} /></Col>
            <Col xs='4' md='2'>
              <Button color='link' onClick={() => setShowForm(true)}>
              (Edit)
              </Button>
              <DeleteProperty value={value} propertyMeta={propertyMeta} />
            </Col>
          </Row>
        ) : (
          <PropertyForm
            isThumbnail
            initValue={initValue}
            propertyMeta={propertyMeta}
            property={propertyMeta}
            onSubmit={onSubmit}
            closeForm={closeForm}
          />
        )}
      </div>
    )
  }

  if (propertyMeta.name === 'howisaymyname') {
    console.log('PropertyValueWithControl howisaymyname propertyMeta: ', propertyMeta)
    return (
      <div>
        {!showForm ? (
          <div>
            <p className='mb-1'>{propertyMeta.showName} <img style={{ margin: 20 }} alt='' src={HowISayMyName} height='40' width='40' /></p>
            <Row>
              <Col>
                <ReactMic
                  height={60}
                  width={180}
                  record={record}
                  onStop={onStop}
                  onData={onData}
                  strokeColor='#FFFFFF'
                  backgroundColor='blue'
                />
              </Col>
              <Col style={{ width: 50 }}>
                <button style={{ width: 50, height: 30 }} onClick={startRecording} type='button'>Start</button>
                <button style={{ width: 50, height: 30 }} onClick={stopRecording} type='button'>Stop</button>
                <button style={{ width: 50, height: 30 }} onClick={onPlay} type='button'>Play</button>
              </Col>
            </Row>
            <div>
              <AudioBase64Input
                property={propertyMeta}
                onSubmit={base64 => onSubmit(propertyMeta.name, base64)}
              />
            </div>
            <PropertyValue propertyMeta={propertyMeta} value={value} />
            <Button color='link' onClick={() => setShowForm(true)}>
              (Edit)
            </Button>
            {propertyMeta.multiple && <DeleteProperty value={value} propertyMeta={propertyMeta} />}
          </div>
        ) : (
          <PropertyForm
            initValue={initValue}
            property={propertyMeta}
            onSubmit={onSubmit}
            closeForm={closeForm}
          />
        )}
      </div>
    )
  }

  return (
    <div>
      {!showForm ? (
        <>

          <Row className='d-flex justify-content-between'>
            <Col xs='12' md='3'>{propertyMeta.showName}
              {propertyMeta.verifiable && (
                <div>
                  <div>
                    <small className={value.verified ? 'text-primary' : 'text-danger'}>
                      {value.verified ? 'Verified' : 'Not verified'}
                    </small>
                  </div>
                  {!value.verified && (
                    <Link to={`/personal/property/${value.objectId}`} className='badge badge-primary'>Verify</Link>
                  )}
                </div>
              )}
            </Col>
            <Col xs='8' md='7'><PropertyValue propertyMeta={propertyMeta} value={value} /></Col>
            <Col xs='4' md='2'><Button color='link' onClick={() => setShowForm(true)}>
              (Edit)
            </Button>
              {(propertyMeta.name === 'familyName' || propertyMeta.name === 'givenName')
              ? null
              : <DeleteProperty
                value={value}
                propertyMeta={propertyMeta}
                />}

            </Col>
          </Row>
          {/* <div>
            <PropertyValue propertyMeta={propertyMeta} value={value} />
            <Button color='link' onClick={() => setShowForm(true)}>
              (Edit)
            </Button>
            {propertyMeta.multiple && <DeleteProperty value={value} propertyMeta={propertyMeta} />}
          </div> */}
        </>
      ) : (
        <PropertyForm
          initValue={initValue}
          property={propertyMeta}
          onSubmit={onSubmit}
          closeForm={closeForm}
        />
      )}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setProfileProperty: (name, value, id) => dispatch(setProfileProperty(name, value, id))
})

export default connect(null, mapDispatchToProps)(PropertyValueWithControl)
