import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'
import Layout from 'Components/Layout'
import HideCookieConsent from 'Components/General/HideCookieconsent'

export default () => {
  return (
    <Layout>
      <HideCookieConsent />
      <Helmet>
        <title>Privacy Policy - HeresMyInfo.com</title>
      </Helmet>
      <Container>
        <Row>
          <Col>
            <h1>Privacy Policy</h1>
            <p>Protecting your private information is our priority. This Statement of Privacy applies to www.heresmyinfo.com and HeresMyInfo, Inc. and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to HeresMyInfo, Inc. include www.heresmyinfo.com and HMI. The HMI website is a HeresMyInfo Portal site. By using the HMI website, you consent to the data practices described in this statement.</p>

            <h3>Collection of your Personal Information</h3>

            <p>In order to better provide you with products and services offered on our Site, HMI may collect personally identifiable information, such as your:</p>
            <ul>
              <li>First and Last Name</li>
              <li>Mailing Address</li>
              <li>E-mail Address</li>
              <li>Phone Number</li>
              <li>Employer</li>
              <li>Job Title</li>
              <li>Various profile data, not limited to and including social networking accounts, contact information of all types</li>
            </ul>

            <p>If you purchase HMI's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.</p>
            <p>HMI may also collect anonymous demographic information, which is not unique to you, such as your:</p>
            <ul>
              <li>Age</li>
              <li>Gender</li>
              <li>Race</li>
              <li>Political Affiliation</li>
              <li>location, personal or business data</li>
            </ul>

            <p>We do not collect any personal information about you unless you voluntarily provide it to us.
              However, you may be required to provide certain personal information to us when you elect to use
              certain products or services available on the Site. These may include: (a) registering for an account
              on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c)
              signing up for special offers from selected third parties; (d) sending us an email message; (e)
              submitting your credit card or other payment information when ordering and purchasing products
              and services on our Site. To wit, we will use your information for, but not limited to,
              communicating with you in relation to services and/or products you have requested from us. We
              also may gather additional personal or non-personal information in the future.
            </p>

            <h3>Use of your Personal Information </h3>
            <p>HMI collects and uses your personal information to operate its website(s) and deliver the services you have requested. </p>
            <p>HMI may also use your personally identifiable information to inform you of other products or services available from HMI and its affiliates. </p>

            <h3>Sharing Information with Third Parties</h3>
            <p>HMI does not sell, rent or lease its customer lists to third parties.</p>
            <p>HMI may share data with trusted partners to help perform statistical analysis, send you email or
              postal mail, provide customer support, or arrange for deliveries. All such third parties are
              prohibited from using your personal information except to provide these services to HMI, and they
              are required to maintain the confidentiality of your information.
            </p>
            <p>HMI may disclose your personal information, without notice, if required to do so by law or in the
              good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply
              with legal process served on HMI or the site; (b) protect and defend the rights or property of
              HMI; and/or (c) act under exigent circumstances to protect the personal safety of users of HMI,
              or the public
            </p>

            <h3>Tracking User Behavior</h3>
            <p>HMI may keep track of the websites and pages our users visit within HMI, in order to determine
              what HMI services are the most popular. This data is used to deliver customized content and
              advertising within HMI to customers whose behavior indicates that they are interested in a
              particular subject area.
            </p>

            <h3>Automatically Collected Information</h3>
            <p>Information about your computer hardware and software may be automatically collected by HMI.
              This information can include: your IP address, browser type, domain names, access times and
              referring website addresses. This information is used for the operation of the service, to maintain
              quality of the service, and to provide general statistics regarding use of the HMI website.
            </p>

            <h3>Use of Cookies</h3>
            <p>The HMI website may use "cookies" to help you personalize your online experience. A cookie is a
              text file that is placed on your hard disk by a web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only
              be read by a web server in the domain that issued the cookie to you.
            </p>

            <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The
              purpose of a cookie is to tell the Web server that you have returned to a specific page. For
              example, if you personalize HMI pages, or register with HMI site or services, a cookie helps HMI
              to recall your specific information on subsequent visits. This simplifies the process of recording
              your personal information, such as billing addresses, shipping addresses, and so on. When you
              return to the same HMI website, the information you previously provided can be retrieved, so you
              can easily use the HMI features that you customized.
            </p>

            <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept
              cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you
              choose to decline cookies, you may not be able to fully experience the interactive features of the
              HMI services or websites you visit.
            </p>

            <h3>Links</h3>
            <p>This website contains links to other sites. Please be aware that we are not responsible for the
              content or privacy practices of such other sites. We encourage our users to be aware when they
              leave our site and to read the privacy statements of any other site that collects personally
              identifiable information.
            </p>

            <h3>Security of your Personal Information</h3>
            <p>HMI secures your personal information from unauthorized access, use, or disclosure. HMI uses
              the following methods for this purpose:
            </p>
            <ul><li>SSL Protocol</li></ul>

            <p>When personal information (such as a credit card number) is transmitted to other websites, it is
              protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.
            </p>

            <p>We strive to take appropriate security measures to protect against unauthorized access to or
              alteration of your personal information. Unfortunately, no data transmission over the Internet or any
              wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect
              your personal information, you acknowledge that: (a) there are security and privacy limitations
              inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any
              and all information and data exchanged between you and us through this Site cannot be
              guaranteed.
            </p>

            <h3>Children Under Thirteen</h3>
            <p>HMI does not knowingly collect personally identifiable information from children under the age of
              thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission
              to use this website.
            </p>

            <h3>Disconnecting your HMI Account from Third Party Websites</h3>
            <p>You will be able to connect your HMI account to third party accounts. BY CONNECTING
              YOUR HMI ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE
              AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF
              INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR
              PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT
              INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING
              INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE.
              You may disconnect your account from a third party account at any time. Deleting any particular
              profile removes all references to that data. No data is retained by HMI. Only users that were
              explicitly defined in a circle will receive or have access specific profile data of any kind. Users may
              contact us through our help section or email address.
            </p>

            <h3>E-mail Communications</h3>
            <p>From time to time, HMI may contact you via email for the purpose of providing announcements,
              promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to
              improve our Services, we may receive a notification when you open an email from HMI or click
              on a link therein.
            </p>

            <p>If you would like to stop receiving marketing or promotional communications via email from HMI,
              you may opt out of such communications by Users do not receive promotional information,
              just verification emails that prove ownership of accounts.
            </p>

            <h5>Cancelling</h5>
            <p>If you would like to stop receiving marketing and/or promotional communications via email from HMI,
              you may opt out of such communications by sending an email to <a href='mailto:admin@heresmyinfo.com' target='_blank' rel='noopener noreferrer'>admin@heresmyinfo.com</a>.
            </p>

            <h3>External Data Storage Sites</h3>
            <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>

            <h3>Changes to this Statement</h3>
            <p>HMI reserves the right to change this Privacy Policy from time to time. We will notify you about
              significant changes in the way we treat personal information by sending a notice to the primary
              email address specified in your account, by placing a prominent notice on our site, and/or by
              updating any privacy information on this page. Your continued use of the Site and/or Services
              available through this Site after such modifications will constitute your: (a) acknowledgment of the
              modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
            </p>

            <h3>Contact Information</h3>
            <p>HMI welcomes your questions or comments regarding this Statement of Privacy. If you believe
              that HMI has not adhered to this Statement, please contact HMI at:
            </p>

            <p>
              HeresMyInfo, Inc.<br />
              30 Martin Lane<br />
              Woodside, California 94062<br />
            </p>

            <p>
              Email Address:<br />
              <a href='mailto:admin@heresmyinfo.com' target='_blank' rel='noopener noreferrer'>admin@heresmyinfo.com</a>
            </p>

            <p>
              Telephone number:<br />
              +1650-530-0149
            </p>

            <p>
              Effective as of Aug 07, 2019
            </p>

            <p>&nbsp;</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
