import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap'
import { STATE_PENDING, STATE_VERIFIED, STATE_DISABLED } from 'Constants/Business'
import { сhangeState } from 'Actions/AdminBusinessActions'
import Loading from '../../Loading'

function BusinessState ({ business, сhangeState }) {
  const { objectId, state } = business
  const getColorClass = state => {
    switch (state) {
      case STATE_PENDING:
        return 'text-warning'
      case STATE_VERIFIED:
        return 'text-primary'
      case STATE_DISABLED:
        return 'text-danger'
      default:
        return 'text-body'
    }
  }

  const [isOpen, setOpen] = useState(false)

  const toggle = () => setOpen(!isOpen)

  const formatState = state => state.slice(0, 1).toUpperCase() + state.slice(1)

  const [loading, setLoading] = useState(false)
  const updateState = newState => {
    setLoading(true)
    сhangeState(objectId, newState)
      .catch(err => console.log(err))
      .finally(() => {
        setLoading(false)
        setOpen(false)
      })
  }

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret color='link'
        className={`${getColorClass(state)} p-0`}
        onClick={(e) => {
          e.stopPropagation()
          toggle()
        }}
      >
        {formatState(state)}
      </DropdownToggle>

      <DropdownMenu>
        <DropdownItem header>New state</DropdownItem>
        {[STATE_VERIFIED, STATE_PENDING, STATE_DISABLED]
          .map(s => <DropdownItem
            key={`dropdown-${s}-${objectId}`}
            disabled={loading || s === state}
            toggle={false}
            onClick={e => {
              e.stopPropagation()
              updateState(s)
            }}
          >{formatState(s)}</DropdownItem>)
        }
        {loading && <Loading />}
      </DropdownMenu>
    </ButtonDropdown>
  )
}

const mapDispatchToProps = dispatch => ({
  сhangeState: (id, state) => dispatch(сhangeState(id, state))
})

export default connect(null, mapDispatchToProps)(BusinessState)
