import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Row, Col, Label, Input, Form,
  FormGroup, FormFeedback, Button
} from 'reactstrap'

import { finishUserDeletion } from 'Actions/AuthActions'

export default ({ messageId }) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleCodeChange = ({ target }) => {
    setCode(target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (code.trim() === '') {
      setError('Enter the code')
      return
    }

    setError(null)
    setLoading(true)

    try {
      await dispatch(finishUserDeletion(messageId, code))
      history.push('/')
      // @todo show toast
    } catch (error) {
      setError(error.message)
    }

    setLoading(false)
  }

  return (
    <div>
      <Form className='form' onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <Label for='code'>Enter the code from the email to delete the account.</Label>

              <Input
                type='code'
                name='code'
                id='code'
                placeholder='Enter the code'
                value={code}
                onChange={handleCodeChange}
              />
            </FormGroup>
          </Col>
        </Row>

        {error && (
          <Row>
            <Col>
              <FormGroup>
                <FormFeedback className='d-block'>{error}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Button color='danger' type='submit' disabled={loading}>Confirm delete</Button>
          </Col>
        </Row>
      </Form>

    </div>
  )
}
