import React, { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Form, FormFeedback, Alert } from 'reactstrap'
import formatISO from 'date-fns/formatISO'

import { generateQRCodes } from 'Actions/AdminActions'
import { extractErrorMessage } from 'Modules/ParseAPI'
import TextInput from 'Components/Form/TextInput'
import QRCodesDownload from './QRCodesDownload'

const initialState = {
  showForm: false,
  amount: null,
  label: `Bunch ${formatISO(new Date(), { representation: 'date' })}`,
  error: null,
  codes: [],
  loading: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'show':
      // Reset everything on show form
      return { ...initialState, showForm: true }
    case 'reset':
      return initialState
    case 'amount':
      return { ...state, amount: Number(action.amount) }
    case 'label':
      return { ...state, label: action.label }
    case 'request':
      return { ...state, loading: true, error: null }
    case 'error':
      return { ...state, loading: false, error: action.error }
    case 'successs':
      // Reset everything on success and show created codes
      return { ...initialState, codes: action.codes }
    case 'clearCodes':
      return { ...state, codes: [] }
    default:
      throw new Error(`Unsupported action ${action.type}`)
  }
}

export default ({ onSubmit }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { showForm, amount, label, error, loading, codes } = state

  const reduxDispatch = useDispatch()

  const submit = async (e) => {
    e.preventDefault()
    dispatch({ type: 'request' })
    if (amount <= 0) {
      dispatch({ type: 'error', error: 'Invalid amount' })
      return
    }
    if (label === '' || !label) {
      dispatch({ type: 'error', error: 'Invalid label' })
      return
    }
    try {
      const codes = await reduxDispatch(generateQRCodes(amount, label))
      dispatch({ type: 'successs', codes })
      onSubmit()
    } catch (e) {
      dispatch({ type: 'error', error: extractErrorMessage(e) })
    }
  }

  return (
    <div>
      { codes.length > 0 && (
        <Alert
          color='success'
          isOpen={codes.length > 0}
          toggle={() => dispatch({ type: 'clearCodes' })}
        >
          {codes.length} QR Codes have been created.{' '}
          <QRCodesDownload codes={codes} filename={`qr_codes_${codes[0].label}`} />
        </Alert>
      )}
      {!showForm
        ? (
          <Button
            onClick={() => dispatch({ type: 'show' })}
            color='primary'
            className='mb-3'
          >
              Add new QR Codes bunch
          </Button>
        ) : (
          <Row className='mb-5'>
            <Col xs={12} md={6}>
              <h3>Add new QR Codes bunch</h3>
              <Form className='form'
                onSubmit={submit}
                onReset={() => dispatch({ type: 'reset' })}
              >
                <TextInput
                  id='amount'
                  type='number'
                  label='Amount to create'
                  placeholder='0'
                  value={amount}
                  onChange={e => dispatch({ type: 'amount', amount: e.target.value })}
                />

                <TextInput
                  id='label'
                  type='text'
                  label='Label'
                  placeholder={`Any string identified`}
                  value={label}
                  onChange={e => dispatch({ type: 'label', label: e.target.value })}
                />

                { !!error && (
                  <FormFeedback className='d-block'>{error}</FormFeedback>
                )}

                <div className='d-flex justify-content-between'>
                  <Button color='primary' type='submit' size='lg'
                    disabled={loading}
                    className='px-5'>Save</Button>
                  <Button color='light' type='reset' size='lg'
                    disabled={loading}
                    className='px-5'>Cancel</Button>
                </div>
              </Form>
            </Col>
          </Row>
        )
      }
    </div>
  )
}
