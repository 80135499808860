// Reducers related to personal user actions
import {
  QR_CODE_LIST, QR_CODE_UPDATE, 
  DELETE_CIRCLE_FAILURE, DELETE_CIRCLE_SUCCESS, 
  CIRCLE_QUERY_START, CIRCLE_QUERY_SUCCESS, CIRCLE_QUERY_FAILURE
} from 'Actions/PersonalActions'

const initialState = {
  qrCodes: [],
  collapseMenu: false
}

// Hiding Public circle for now
// to undo just remove calling hidePublicCircle from the code below, assign circles directly instead
const hidePublicCircle = circles => circles.filter(it => {
  // If circle is public and it's name is 'Public' - hide
  return !(
    it.public &&
    it.name === 'Public'
  )
})

export default function (state = initialState, action) {
  switch (action.type) {
    case QR_CODE_LIST:
      return {
        ...state,
        qrCodes: action.qrCodes.sort((a, b) => a.QRCode.localeCompare(b.QRCode))
      }
    case QR_CODE_UPDATE:
      const qrCode = action.qrCode
      return {
        ...state,
        qrCodes: state.qrCodes.map(_ => _.objectId === qrCode.objectId ? qrCode : _)
      }
    case DELETE_CIRCLE_SUCCESS:
      return Object.assign({}, state, {
        deleteCardMsg: null
      })
    case DELETE_CIRCLE_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        deleteCardMsg: action.error
      })
      case CIRCLE_QUERY_START:
      return Object.assign({}, state, {
        connectionError: null,
        connectedTo: null,
        loading: false,
        error: null
      })
    case CIRCLE_QUERY_SUCCESS:
      // console.log('XXXXXXXXXXXX', action.circles)
      return Object.assign({}, state, {
        loading: false,
        Circle: {
          circles: hidePublicCircle(action.circles),
          connections: action.connections
        }
      })
    case CIRCLE_QUERY_FAILURE:
      if (action.error.code === 209) {
        // [TODO] NEED TO IMPROVE THIS: IF A SESSION IS EXPIRED WE HAVE A PROBLEM!
        console.error('PARSE PROBLEM - HEADS UP - No session - ', action)
        return Object.assign({}, initialState)
      }
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      })
    default:
      return state
  }
}
