import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { setIsOpen } from 'Actions/BusinessActions'
import { Col } from 'reactstrap'
import Layout from 'Components/Layout/Layout'
import Vimeo from '@u-wave/react-vimeo'
import { useMediaQuery } from 'react-responsive'
import './Learn.css'

const videos = [
  { id: 337104218, name: 'What is HeresMyInfo' },
  { id: 190572218, name: 'HeresMyInfo Small Business' },
  { id: 352229782, name: '30 Second Connect' }
]

function Learn ({ setIsOpen }) {
  const [videoIndex, setVideoIndex] = useState(0)
  // const [volume, setVolume] = useState(1)
  const [paused, setPaused] = useState(false)

  useEffect(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const selectVideo = (index) => {
    setVideoIndex(index)
  }

  // const handlePause = (event) => {
  //   setPaused(event.target.checked)
  // }

  const handlePlayerPause = () => {
    setPaused(true)
  }

  const handlePlayerPlay = () => {
    setPaused(false)
  }

  // const handleVolume = (event) => {
  //   setVolume(parseFloat(event.target.value))
  // }

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' })
  // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  // const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const video = videos[videoIndex]

  return (
    <Layout>
      <Helmet>
        <title>Learn about HeresMyInfo</title>
      </Helmet>

      <h1 className='hmi-video'>Video Gallery</h1>
      <div className='video-pane'>
        {isDesktopOrLaptop
          ? <Vimeo
            video={video.id}
            width={640}
            height={320}
            paused={paused}
            onPause={handlePlayerPause}
            onPlay={handlePlayerPlay}
          />
          : <Vimeo
            video={video.id}
            responsive
            paused={paused}
            onPause={handlePlayerPause}
            onPlay={handlePlayerPlay}
          />}
      </div>
      {videos.map((choice, index) => (
        <Col style={{ textAlign: 'center', padding: 0 }} key={index}>

          <a
            className='videoButton btn btn-outline-secondary' href={`#!/video/${index}`}
            // className={`collection-item ${video === choice ? 'active' : ''}`}
            onClick={() => selectVideo(index)}
          >
            {choice.name}
          </a>

        </Col>
      ))}
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
})

const mapDispatchToProps = dispatch => ({
  setIsOpen: () => dispatch(setIsOpen(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(Learn)
