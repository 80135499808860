import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

function Logout ({ isAuthenticated }) {
  if (!isAuthenticated) {
    return null
  }
  return (
    <Link
      to={{
        pathname: '/logout',
        state: { logout: true }
      }}
      className='nav-link'
    >Logout</Link>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default withRouter(connect(mapStateToProps)(Logout))
