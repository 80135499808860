// Text input to use Reactstrap with Formik
import React, { useRef, useEffect } from 'react'
import { Label, FormGroup, FormFeedback } from 'reactstrap'
import PhoneInputLibrary from 'react-phone-number-input'
import SmartInput from 'react-phone-number-input/smart-input'
import 'react-phone-number-input/style.css'

const PhoneComponent = React.forwardRef(({ disabled, placeholder, ...props }, ref) => {
  const enabled = !!props.country || props.value !== ''
  return <SmartInput
    disabled={!enabled}
    placeholder={enabled ? placeholder : '<-- Choose country first...'}
    ref={ref} {...props} />
})

export default function PhoneInput ({
  id,
  label,
  error,
  value,
  onChange,
  onCountryChange,
  className,
  placeholder,
  focusOnMount,
  ...props
}) {
  const refEl = useRef(null)
  useEffect(() => {
    if (focusOnMount && refEl.current) {
      refEl.current.focus()
    }
  }, [focusOnMount])

  return (
    <FormGroup>
      {label && <Label for='phone'>{label}</Label>}
      <PhoneInputLibrary
        inputComponent={PhoneComponent}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onCountryChange={onCountryChange}
        inputClassName={error ? 'is-invalid form-control' : 'form-control'}
        ref={refEl}
        {...props}
      />
      { !!error && (
        <FormFeedback className='d-block'>{error}</FormFeedback>
      )}
    </FormGroup>
  )
}
