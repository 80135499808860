import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { logout } from 'Actions/AuthActions'
import Layout from 'Components/Layout'
import Loading from 'Components/Loading'

function Logout ({ logout, location }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (location.state && location.state.logout) {
      logout().catch(() => {}).finally(() => setLoading(false))
    }
  }, [location, logout])

  if (!location.state || !location.state.logout) {
    return <Redirect to='/' />
  }
  if (!loading) {
    return <Redirect to='/' />
  }
  return (
    <Layout>
      <Loading />
    </Layout>
  )
}

const mapDispatchToProps = {
  logout
}

export default withRouter(connect(null, mapDispatchToProps)(Logout))
