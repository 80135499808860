import React, { useRef, useEffect } from 'react'
import { FormGroup, FormFeedback, Input } from 'reactstrap'

export default function SelectInput ({ value, setValue, options, error, focus }) {
  const inputEl = useRef(null)

  useEffect(() => {
    if (inputEl.current && focus) {
      inputEl.current.focus()
    }
  }, [focus])
  return (
    <FormGroup>
      <Input
        type='select'
        name='property'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        invalid={!!error}
        innerRef={inputEl}
      >
        <option value=''>-- Choose value --</option>
        {options.map(op =>
          <option key={op} value={op}>{op}</option>
        ) }
      </Input>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  )
}
