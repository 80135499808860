import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export default class deleteResourcesButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      modal: false,
      submitted: false
    }

    this.toggle = this.toggle.bind(this)
    this.deleteResources = this.deleteResources.bind(this)
  }

  toggle () {
    this.setState(prevState => ({
      modal: !prevState.modal,
      submitted: !prevState.modal ? false : prevState.submitted // mark as not submitted when open the modal again
    }))
  }

  deleteResources () {
    this.setState({ submitted: true })
    this.props.deleteResources()
  }

  componentDidUpdate (prevProps) {
    if (this.state.modal && this.state.submitted) {
      if (!this.props.error && !this.props.loading) {
        this.toggle()
      }
    }
  }

  render () {
    const { rows, loading, error, resourceName } = this.props
    const { submitted } = this.state
    return (
      <div>
        <Button color='danger' onClick={this.toggle}>Delete</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Delete {resourceName}</ModalHeader>
          <ModalBody>
            You are going to delete {resourceName} with ids:
            <ul>
              {rows.map(id => (<li key={id}>{id}</li>))}
            </ul>
            {submitted && !loading && error && (
              <p className='text-danger'>Error:
                {error.message && typeof error.message === 'string' ? error.message : error.toString()}
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color='danger' onClick={this.deleteResources} disabled={loading}>Confirm delete</Button>{' '}
            <Button color='secondary' onClick={this.toggle} disabled={loading}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
