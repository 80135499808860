import {
  LOAD_BUSINESSES_REQUEST,
  LOAD_BUSINESSES_SUCCESS,
  LOAD_BUSINESSES_FAILURE,
  CREATE_BUSINESS_REQUEST,
  CREATE_BUSINESS_SUCCESS,
  CREATE_BUSINESS_FAILURE,
  LOAD_ONE_BUSINESS_REQUEST,
  LOAD_ONE_BUSINESS_SUCCESS,
  LOAD_ONE_BUSINESS_FAILURE,
  CREATE_BUSINESS_PROPERTY,
  UPDATE_BUSINESS_PROPERTY,
  MENU_COLLAPSE
} from 'Actions/BusinessActions'
import { PROPERTY_NAME } from 'Constants/Properties'

const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  createError: null,
  createLoading: false,
  loading: false,
  error: null,
  business: {},
  isOpen: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BUSINESSES_REQUEST:
      return { ...state, listLoading: true, listError: null }
    case LOAD_BUSINESSES_SUCCESS:
      return { ...state, listLoading: false, list: action.list }
    case LOAD_BUSINESSES_FAILURE:
      return { ...state, listLoading: false, listError: action.error }
    case CREATE_BUSINESS_REQUEST:
      return { ...state, createLoading: true, createError: null }
    case CREATE_BUSINESS_SUCCESS:
      return { ...state, createLoading: false }
    case CREATE_BUSINESS_FAILURE:
      return { ...state, createLoading: false, createError: action.error }
    case LOAD_ONE_BUSINESS_REQUEST:
      return { ...state, loading: true, error: null }
    case LOAD_ONE_BUSINESS_SUCCESS:
      let templatePath = action.business.templatePath
      if (state.business && state.business.objectId === action.business.objectId && !action.business.templatePath) {
        templatePath = state.business.templatePath
      }
      return { ...state, loading: false, business: { ...action.business, templatePath } }
    case LOAD_ONE_BUSINESS_FAILURE:
      return { ...state, loading: false, error: action.error, business: {} }
    case CREATE_BUSINESS_PROPERTY:
      if (state.business.objectId !== action.businessId) {
        return state
      }
      return {
        ...state,
        business: {
          ...state.business,
          properties: [
            ...state.business.properties,
            action.property
          ]
        }
      }
    case UPDATE_BUSINESS_PROPERTY:
      const newBusiness = state.business.properties ? {
        ...state.business,
        properties: state.business.properties.map(prop => prop.objectId === action.property.objectId ? action.property : prop),
        name: action.property.name === PROPERTY_NAME ? action.property.value : state.business.name
      } : Object.assign({}, state.busines)

      return { ...state, business: newBusiness }
    case MENU_COLLAPSE:
      return { ...state, isOpen: action.close }
    default:
      return state
  }
}
