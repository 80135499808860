import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  SelectionState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
  DataTypeProvider
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4'
import { Row, Col } from 'reactstrap'
import { getSingleUser, deleteConnections } from 'Actions/AdminUserActions'
import Loading from '../Loading'
import DeleteResourcesButton from './DeleteResourcesButton'

const PersonFormatter = ({ value, row }) => {
  let altName = row.fullName
  if (!value && !row.fullName) {
    if (row.name){
      altName = `${row.name}${row.email ? ' // ' + row.email : ''}`
    } else {
      altName = row.email ? row.email : '-'
    }
  }
  return (
    <>
      {value ? (<Link to={`/admin/user/${value.objectId}`}>{row.fullName || value.objectId}</Link>) : altName}
    </>
  )
}

export class User extends Component {
  constructor(props) {
    super(props)

    this.state = {
      outgoingColumns: [
        { name: 'objectId', title: 'Connection id' },
        { name: 'toPerson', title: 'To person' },
        { name: 'createdAt', title: 'Created at' }
      ],
      incomingColumns: [
        { name: 'objectId', title: 'Connection id' },
        { name: 'fromPerson', title: 'From person' },
        { name: 'createdAt', title: 'Created at' }
      ],
      outgoingSelection: [],
      incomingSelection: []
    }
    this.changeOutgoingSelection = outgoingSelection => this.setState({ outgoingSelection })
    this.changeIncomingSelection = incomingSelection => this.setState({ incomingSelection })
  }

  componentDidMount() {
    this.props.getSingleUser(this.props.userId)
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      this.setState({
        outgoingSelection: [],
        incomingSelection: []
      })
      this.props.getSingleUser(this.props.userId)
    } else {
      const { connections } = this.props.user
      const { connections: prevConnections } = prevProps.user
      if (connections.outgoing.length !== prevConnections.outgoing.length) {
        this.changeOutgoingSelection([])
      }
      if (connections.incoming.length !== prevConnections.incoming.length) {
        this.changeIncomingSelection([])
      }
    }
  }

  userFullName(user) {
    if (!user.profile || !user.profile.properties) {
      return ''
    }
    
    console.log('user.ObjectId', user.objectId)
    console.log('user.ACL', Object.keys(user.ACL)[0])
    console.log('user.connections.incoming', user.connections.incoming[0])
    return user.profile.properties
      .filter(property => property.name === 'fn')
      .map(property => property.value)
      .join('; ')
  }

  render() {
    const { loading, error, user, deleteConnections, deleteConnectionsError } = this.props
    const { outgoingColumns, incomingColumns, outgoingSelection, incomingSelection } = this.state

    if (loading && !user.objectId) {
      return <div className='position-relative py-5'><Loading /></div>
    }
    if (error) {
      return <h3>{error.message && typeof error.message === 'string' ? error.message : error.toString()}</h3>
    }

    return (
      <div className='position-relative'>
        {loading && (<Loading />)}
        <h2>User {this.userFullName(user)}</h2>
        <h3 className='pt-4'>Outgoing connections</h3>

        <Grid
          rows={user.connections.outgoing}
          columns={outgoingColumns}
          getRowId={row => row.objectId}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={5}
          />
          <SelectionState
            selection={outgoingSelection}
            onSelectionChange={this.changeOutgoingSelection}
          />

          <IntegratedPaging />
          <IntegratedSelection />

          <DataTypeProvider
            formatterComponent={PersonFormatter}
            for={['toPerson']}
          />

          <Table />  
          <TableHeaderRow />
          <TableSelection showSelectAll highlightRow selectByRowClick />
          <PagingPanel />
        </Grid>
        {outgoingSelection.length > 0 && (
          <Row>
            <Col>
              <p className='twice'>Selected: {outgoingSelection.length} <strong>outgoing</strong> connection{outgoingSelection.length !== 1 && 's'}</p>
              <DeleteResourcesButton
                resourceName='outgoing connections'
                rows={outgoingSelection}
                deleteResources={() => deleteConnections(user.objectId, outgoingSelection)}
                loading={loading}
                error={deleteConnectionsError}
              />
            </Col>
          </Row>
        )}

        <h3 className='pt-4'>Incoming connections</h3>
        <Grid
          rows={user.connections.incoming}
          columns={incomingColumns}
          getRowId={row => row.objectId}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={5}
          />
          <SelectionState
            selection={incomingSelection}
            onSelectionChange={this.changeIncomingSelection}
          />

          <IntegratedPaging />
          <IntegratedSelection />
          <DataTypeProvider
            formatterComponent={PersonFormatter}
            for={['fromPerson']}
          />

          <Table />
          <TableHeaderRow />
          <TableSelection showSelectAll highlightRow selectByRowClick />
          <PagingPanel />
        </Grid>
        {incomingSelection.length > 0 && (
          <Row>
            <Col>
              <p className='twice'>Selected: {incomingSelection.length} <strong>incoming</strong> connection{incomingSelection.length !== 1 && 's'}</p>
              <DeleteResourcesButton
                resourceName='incoming connections'
                rows={incomingSelection}
                deleteResources={() => deleteConnections(user.objectId, incomingSelection)}
                loading={loading}
                error={deleteConnectionsError}
              />
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.admin.user.user,
  loading: state.admin.user.loading,
  error: state.admin.user.error,
  deleteConnectionsError: state.admin.user.deleteConnectionsError
})

const mapDispatchToProps = dispatch => ({
  getSingleUser: user => dispatch(getSingleUser(user)),
  deleteConnections: (userId, connections) => dispatch(deleteConnections(userId, connections))
})

export default connect(mapStateToProps, mapDispatchToProps)(User)
