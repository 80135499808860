import React, { PureComponent } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Layout from 'Components/Layout'

export default class Page404 extends PureComponent {
  render () {
    return (
      <Layout>
        <Helmet>
          <title>Page not found - HeresMyInfo.com</title>
        </Helmet>
        <Container>
          <Row>
            <Col>
              <h1>Ooops. Page not found</h1>
              <p>Would you like to start with <Link to='/'>Main page</Link> or <Link to='learn'>Learn more</Link> about HeresMyInfo?</p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}
