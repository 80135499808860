import React from 'react'
import { connect } from 'react-redux'
import { Container, Button } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { useAsync } from 'react-async'
import { Link } from 'react-router-dom'

import AdminLayout from 'Components/Admin/AdminLayout'
import Loading from 'Components/Loading'
import { loadQRCodes } from 'Actions/AdminActions'
import QRCodesForm from 'Components/Admin/QRCode/QRCodesForm'
import QRStatTable from 'Components/Admin/QRCode/QRStatTable'

function QRCodes ({ loadQRCodes }) {
  const { data, isLoading, reload } = useAsync(loadQRCodes)

  return (
    <AdminLayout>
      <Button outline color='primary'><Link to='/admin'><h4>Back</h4></Link></Button><p />
      <Helmet>
        <title>QR Codes</title>
      </Helmet>
      <Container>
        <h2>QR Codes Management</h2>

        <QRCodesForm onSubmit={reload} />

        { isLoading ? <Loading /> : <QRStatTable data={data} /> }

      </Container>
    </AdminLayout>
  )
}

const mapDispatchToProps = {
  loadQRCodes
}

export default connect(null, mapDispatchToProps)(QRCodes)
