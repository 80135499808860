import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  Container, Row, Col
} from 'reactstrap'
import Layout from 'Components/Layout'
import { signUp, cleanError } from 'Actions/AuthActions'
import { getCountryByIP } from 'Actions/PersonalActions'
import { setIsOpen } from 'Actions/BusinessActions'
import './SignUp.scss'
import Player from 'Components/Player'
import { SignUpForm, EmailConfirmation } from 'Components/SignUp'
import OAuthButton from 'Components/OAuth/OAuthButton'

class SignUp extends Component {

  componentDidMount(){
    this.props.setIsOpen(false)
  }
  
  componentWillUnmount() {
    this.props.cleanError()
  }

  render() {
    const { isAuthenticated, loading, signUp, error, emailVerified, primaryEmail, getCountryByIP, setIsOpen } = this.props

    if (isAuthenticated) {
      if (!emailVerified) {
        return (
          <Layout>
            <Helmet>
              <title>Sign Up - HeresMyInfo.com</title>
            </Helmet>
            <Container>
              <EmailConfirmation h1='Signed up successfully!' email={primaryEmail} />
            </Container>
          </Layout>
        )
      }
      return <Redirect to='/' />
    }

    return (
      <Layout>
        <Helmet>
          <title>Sign Up - HeresMyInfo.com</title>
        </Helmet>
        <Container className='signup'>
          <Row>
            <Col xl='6' lg='12'>
              <h5>Getting started is easy. Your basic profile is just a primary email address (you’ll use this to log in), cell phone, first and last name.</h5>
              <h6>Your information is not just private, it's sacred. We NEVER share it with anyone. You do. </h6>
            </Col>
          </Row>
          <Row>
            <Col xl='6' lg='12'>
              <SignUpForm signUp={signUp} loading={loading} error={error} getCountryByIP={getCountryByIP} setIsOpen={setIsOpen}/>
              <Col>
                <p className='py-3'>Already have an account? <Link to='/login'>Sign In</Link></p>
              </Col>
              <Col>
                <OAuthButton provider='facebook' title='Sign up with Facebook' />
              </Col>
              <Col>
                <OAuthButton provider='google' title='Sign up with Google' />
              </Col>


            </Col>
            <Col xl='6' lg='12'>
              <p>Wait, I want to know more first!</p>
              <Player
                url='https://vimeo.com/337104218'
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
  loading: state.auth.loading,
  emailVerified: state.auth.currentUser && state.auth.currentUser.emailVerified,
  primaryEmail: state.auth.currentUser && state.auth.currentUser.primaryEmail
})

const mapDispatchToProps = dispatch => ({
  signUp: (userInfo) => dispatch(signUp(userInfo)),
  cleanError: () => dispatch(cleanError()),
  getCountryByIP: () => dispatch(getCountryByIP()),
  setIsOpen : () => dispatch(setIsOpen(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
