import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { useAsync } from 'react-async'
import Layout from 'Components/Layout'
import Loading from 'Components/Loading'
import { loadQRCodes } from 'Actions/PersonalActions'
import QRList from 'Components/Personal/QRCode/QRList'
import PageHeader from 'Components/PageHeader/PageHeader'

function QRCodes({ loadQRCodes, qrCodes }) {
  const { isLoading } = useAsync(loadQRCodes)
  const title = "My QR Codes";
  const description = `Qr Codes are a great way to invite people to connect with you, without the need to enter their email address.
  Just decide which card each QR code shares, and everyone that scans it will get the contact information in that card.
  You can change what this QR Code shares at any time! Download a QR code and use it wherever you like - shirts, stickers, product labels,
  practically anywhere you want others to have your up to date info.`

  return (
    <Layout>
      <Helmet>
        <title>My QR Codes</title>
      </Helmet>
      <PageHeader title={title} description = {description}/>
      <Container >
        {isLoading ? <Loading /> : (
          <Row>
            <Col md="12" lg="12" className="hideQRMobile"> <QRList items={qrCodes} /></Col>
          </Row>
        )}

      </Container>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  qrCodes: state.auth.qrCodes
})

const mapDispatchToProps = {
  loadQRCodes
}

export default connect(mapStateToProps, mapDispatchToProps)(QRCodes)
