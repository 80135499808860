import React, { Component } from 'react'
import { STATE_PENDING, STATE_VERIFIED, STATE_DISABLED } from 'Constants/Business'
import { UncontrolledTooltip } from 'reactstrap'

export default class BusinessState extends Component {
  getMessage () {
    switch (this.props.business.state) {
      case STATE_PENDING:
        return 'Your business is under verification. Our team will verify it after you verify the domain.'
      case STATE_VERIFIED:
        return 'Your business is verified and available for sharing.'
      case STATE_DISABLED:
        return 'Your business is not verified. Please contact support@heresmyinfo.com for details.'
      default:
        return ''
    }
  }

  getColor () {
    switch (this.props.business.state) {
      case STATE_PENDING:
        return 'text-warning'
      case STATE_VERIFIED:
        return 'text-primary'
      case STATE_DISABLED:
        return 'text-danger'
      default:
        return 'text-body'
    }
  }

  render () {
    return (
      <div className='d-inline'>
        <span
          className={this.getColor()}
          style={{ textDecoration: 'underline dotted', cursor: 'pointer' }}
          id='business-status-tooltip'
        >[
          {this.props.business.state}]
        </span>
        <UncontrolledTooltip placement='right' target='business-status-tooltip' autohide={false}>
          { this.getMessage() }
        </UncontrolledTooltip>
      </div>
    )
  }
}
