import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  DELETE_CONNECTIONS_REQUEST,
  DELETE_CONNECTIONS_SUCCESS,
  DELETE_CONNECTIONS_FAILURE,
  TOGGLE_USER_REQUEST,
  TOGGLE_USER_SUCCESS,
  TOGGLE_USER_FAILURE
} from 'Actions/AdminUserActions'

const initialState = {
  loading: false,
  error: null,
  deleteConnectionsError: null,
  toggleError: null,
  user: {
    connections: {
      outgoing: [],
      incoming: []
    }
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      })
    case GET_USER_SUCCESS:
      // Map names to user ids
      const names = new Map(action.names.map(user => [
        user.objectId,
        user.profile.properties
          .filter(property => property.name === 'fn')
          .map(property => property.value)
          .join('; ')
      ]))
      const connectionsByDirection = action.connections.reduce((res, connection) => {
        if (connection.fromPerson && connection.fromPerson.objectId === action.user.objectId) {
          if (connection.toPerson && names.has(connection.toPerson.objectId)) {
            connection.fullName = names.get(connection.toPerson.objectId)
          }
          res.outgoing.push(connection)
        } else {
          if (names.has(connection.fromPerson.objectId)) {
            connection.fullName = names.get(connection.fromPerson.objectId)
          }
          res.incoming.push(connection)
        }
        return res
      }, { outgoing: [], incoming: [] })
      return Object.assign({}, state, {
        loading: false,
        error: null,
        user: {
          ...action.user,
          connections: connectionsByDirection
        }
      })
    case GET_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        user: Object.assign({}, initialState.user),
        error: action.error
      })
    case DELETE_CONNECTIONS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        deleteConnectionsError: null
      })
    case DELETE_CONNECTIONS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        deleteConnectionsError: null
      })
    case DELETE_CONNECTIONS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        deleteConnectionsError: action.error
      })
    case TOGGLE_USER_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        toggleError: null
      })
    case TOGGLE_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false
      })
    case TOGGLE_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        toggleError: action.error
      })

    default:
      return state
  }
}
