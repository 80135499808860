import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Main from './Main'
import UserList from './UserList'
import User from './User'
import BusinessList from './BusinessList'
import Business from './Business'
import QRCodes from './QRCodes'

export default function Admin ({ match: { path } }) {
  return (
      <>
        <Helmet>
          <title>Admin pages - HeresMyInfo.com</title>
        </Helmet>
        <Switch>
          <Route exact path={`${path}`} component={Main} />
          <Route exact path={`${path}/user`} component={UserList} />
          <Route exact path={`${path}/business`} component={BusinessList} />
          <Route exact path={`${path}/qrcodes`} component={QRCodes} />
          <Route path={`${path}/user/:userId`} component={User} />
          <Route path={`${path}/business/:id`} component={Business} />
        </Switch>
      </>
  )
}
