import React, { Component, useState } from 'react'
import logo from '../../assets/logo_green_tm.png'
import userLogo from '../../assets/img/non_admin.png'
import {
  Navbar,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse
} from 'reactstrap'
import { Link, NavLink, withRouter } from 'react-router-dom'
import Logout from '../Header/Logout'
import './AdminLayout.css'

function Header (props) {
  const [isOpen, setIsOpen] = useState(false)
  return (<div>
    <Navbar style={{ marginBottom: 0 }} justify='true' expand='lg' sticky='top'>
      <div>
        <a href='/'><img src={logo} alt='HeresMyInfo' width='200' style={{ marginRight: 25 }} /></a>

      </div>
      <Nav className='nav-pills mr-auto'>
        <NavItem>
          <Link to='/' className='admin-link'><img src={userLogo} title='user mode' alt='user mode' className='adminLogo' /></Link>
        </NavItem>
      </Nav>
      <NavbarToggler className='navbar-light' onClick={() => setIsOpen(isOpen => !isOpen)} />
      <Collapse isOpen={isOpen} navbar style={{ justifyContent: 'flex-end', color: 'white' }}>
        <Nav navbar id='nav-header' className='nav-pills mr-auto' style={{ lineHeight: '1rem' }}>
          <NavItem>
            <NavLink to='/admin' className='nav-link'>Dashboard</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='/admin/user' className='nav-link'>Users</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='/admin/business' className='nav-link'>Businesses</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to='/admin/qrcodes' className='nav-link'>QR Codes</NavLink>
          </NavItem>
          <NavItem>
            <Logout className='ml-auto' />
          </NavItem>

        </Nav>
      </Collapse>

    </Navbar>
    <div className='badge badge-danger p-2 admin-badge'>ADMIN MODE</div>
  </div>
  )
}

class Layout extends Component {
  render () {
    return (
      <div className='d-flex min-vh-100 flex-column'>
        <Header />
        <div className='pt-4'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default withRouter(Layout)
