import React from 'react'
import FieldRouter from 'Components/Personal/Property/propertyForm/FieldRouter'

export default function ComplexInput ({ value, setValue, property, error }) {
  if (!error) {
    error = {}
  }
  const updateOneValue = (newValue, key) => setValue(state => {
    if (typeof newValue === 'function') {
      return newValue(state)
    }
    return {
      ...state,
      [key]: { ...state[key], value: newValue }
    }
  })

  return (
    <div>
      {Object.entries(value).map(([key, field]) => field.showName &&
      (
        <div key={`${property.name}-${key}`} className='mb-2'>
          <p className='mb-1 pl-2'>{ field.showName }</p>
          <FieldRouter
            value={value[key].value}
            setValue={newValue => updateOneValue(newValue, key)}
            row={{ ...field, parent: property.name }}
            groupValue={value}
            error={error[field.name]}
          />
        </div>
      ))}
    </div>
  )
}
