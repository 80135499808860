import React, { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import Layout from 'Components/Layout'
import { useDispatch } from 'react-redux'
import { validateEmail } from 'Modules/Utils'

import { initPasswordRecovery } from 'Actions/AuthActions'
import {
  Container, Row, Col, Label, Input, Form,
  FormGroup, FormFeedback, Button
} from 'reactstrap'

const ResetPasswordComponent = () => {
  // const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const dispatch = useDispatch()

  const handleEmailChange = ({ target }) => {
    setEmail(target.value)
  }

  const handleValidateEmail = useCallback(() => {
    setIsEmailValid(validateEmail(email))
  }, [email])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!validateEmail(email)) {
      return false
    }

    setError(null)
    setLoading(true)

    try {
      await dispatch(initPasswordRecovery(email))
      setSuccess(true)
    } catch (error) {
      setError(error.message)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (isEmailValid === false || isEmailValid === true) {
      handleValidateEmail()
    }
  }, [email, isEmailValid, handleValidateEmail])

  // if (isAuthenticated) {
  //   return <Redirect to='/' />
  // }

  return (
    <Layout>
      <Helmet
        title='Reset Password - HeresMyInfo.com'
      />

      <Container>
        <Row className='py-2'>
          <Col>
            <h2>Reset password</h2>
          </Col>
        </Row>

        {success && (
          <p>Password recovery details have been sent to email {email} if user with this email exists.</p>
        )}

        {!success && (
          <Form className='form' onSubmit={handleSubmit}>
            <Col>
              <FormGroup>
                <Label for='email'>Email</Label>

                <Input
                  type='email'
                  name='email'
                  id='email'
                  placeholder='Enter your email'
                  value={email}
                  invalid={isEmailValid === false}
                  onChange={handleEmailChange}
                  onBlur={handleValidateEmail}
                />

                <FormFeedback>
                  Please input a correct email.
                </FormFeedback>
              </FormGroup>
            </Col>

            {error && (
              <Col>
                <FormGroup>
                  <FormFeedback className='d-block'>{error}</FormFeedback>
                </FormGroup>
              </Col>
            )}

            <Col>
              <Button color='primary' type='submit' disabled={loading}>Recover password</Button>
            </Col>
          </Form>
        )}
      </Container>
    </Layout>
  )
}

export default ResetPasswordComponent
