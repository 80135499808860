import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setIsOpen } from 'Actions/BusinessActions'
import FAQItems from '../../Components/FAQ'
import './sass/main.scss'
import Layout from 'Components/Layout'
import { Helmet } from 'react-helmet'
import './FAQ.css'
import PageHeader from 'Components/PageHeader/PageHeader'

const FAQ = ({ setIsOpen }) => {
  useEffect(() => {
    setIsOpen(false)
  })

  const title = 'Frequently Asked Questions'
  const description = ''

  return (
    <Layout>
      <Helmet>
        <title>FAQs</title>
      </Helmet>
      <PageHeader title={title} description={description} />
      <FAQItems />
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = dispatch => ({
  setIsOpen: () => dispatch(setIsOpen(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(FAQ)
