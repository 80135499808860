import {
  GET_ALL_BUSINESSES_REQUEST,
  GET_ALL_BUSINESSES_SUCCESS,
  GET_ALL_BUSINESSES_FAILURE,
  CHANGE_BUSINESSES_PAGE,
  SELECT_BUSINESSES,
  SEARCH_BUSINESSES,
  UPDATE_BUSINESS_SUCCESS,
  DELETE_BUSINESSES_REQUEST,
  DELETE_BUSINESSES_SUCCESS,
  DELETE_BUSINESSES_FAILURE
} from 'Actions/AdminBusinessActions'

const initialState = {
  page: 0, // page number starts from 0
  perPage: 20,
  loading: false,
  total: 0,
  rows: [],
  selection: [],
  error: null,
  deleteError: null,
  search: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BUSINESSES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      })
    case GET_ALL_BUSINESSES_SUCCESS:
      const { owners } = action
      const getOwner = businessId => {
        const owner = owners.find(owner => {
          if (!owner.businesses) {
            return false
          }
          return owner.businesses.some(business => business.objectId === businessId)
        })
        if (!owner) {
          return {}
        }
        return {
          objectId: owner.objectId,
          fullName: owner.profile.properties.find(property => property.name === 'fn').value
        }
      }
      const businesses = action.businesses.map(business => ({
        ...business,
        owner: getOwner(business.objectId)
      }))
      return Object.assign({}, state, {
        loading: false,
        rows: businesses,
        total: action.total
      })
    case GET_ALL_BUSINESSES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        rows: [],
        total: 0,
        error: action.error
      })
    case CHANGE_BUSINESSES_PAGE:
      return Object.assign({}, state, {
        page: action.page
      })
    case SELECT_BUSINESSES:
      return Object.assign({}, state, {
        selection: action.selection
      })
    case SEARCH_BUSINESSES:
      return Object.assign({}, state, {
        search: action.search
      })
    case UPDATE_BUSINESS_SUCCESS:
      return Object.assign({}, state, {
        rows: state.rows.map(row => row.objectId !== action.business.objectId ? row : {
          ...row,
          ...action.business
        })
      })
    case DELETE_BUSINESSES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        deleteError: null
      })
    case DELETE_BUSINESSES_SUCCESS:
      return Object.assign({}, state, {
        selection: []
      })
    case DELETE_BUSINESSES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        deleteError: action.error
      })

    default:
      return state
  }
}
