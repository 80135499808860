import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import Applet from './Applet'
import dateFnsParseISO from 'date-fns/parseISO'
import dateFnsFormat from 'date-fns/format'
import { getSingleUser } from 'Actions/PersonalActions'

const Overview = ({ user, getSingleUser, connections }) => {
  useEffect(() => {
    getSingleUser(user.objectId)
  }, [getSingleUser, user.objectId])

  const signUpDate = useMemo(() => user && dateFnsParseISO(user.createdAt), [user])
  const activeConnections = connections && connections.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter((conn, i) => conn.status === 'connected' && i < 5)

  const title = `Account Overview -  member since ${dateFnsFormat(signUpDate, 'PP')}`
  return (
    <Applet title={title} size={6}>
      <p>Primary login: <strong>{user.primaryEmail.parameters[1].value}</strong></p>
      {activeConnections && activeConnections.length > 0 ? <p>I have <strong>{activeConnections.length && activeConnections.length}</strong> Connection{activeConnections && activeConnections.length !== 1 ? 's' : ''}.</p>
        : activeConnections && activeConnections.length === 0 ? 'I have 0 connections' : ''}

      {activeConnections && activeConnections.length > 0 ? <p>My recent connections:</p> : ''}
      <ol>{activeConnections && activeConnections.map(({ objectId, fullName, email, createdAt }) => {
        const isodate = dateFnsParseISO(createdAt)
        const date = `${dateFnsFormat(isodate, 'PP')}`
        return (<li key={objectId}><strong>{fullName || email}</strong> - connected {date}</li>)
      })}
      </ol>
      <hr />
      <p>Use our mobile app to manage connections and see your invitations.</p>
    </Applet>
  )
}

const mapStateToProps = state => ({
  user: state.auth.currentUser,
  connections: state.parse.connections.outgoing
})

const mapDispatchToProps = dispatch => ({
  getSingleUser: (user) => dispatch(getSingleUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
