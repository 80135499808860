import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom'

const GAWithConsent = function ({ cookieConsent, location: { pathname }, children }) {
  // Init GA counter when received cookie consent
  const [gaInit, setGaInit] = useState(false)
  useEffect(() => {
    if (cookieConsent && !gaInit) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID)
      setGaInit(true)
    }
  }, [cookieConsent, gaInit])

  // Track page view on page change or when init Ga
  useEffect(() => {
    if (gaInit) {
      ReactGA.pageview(pathname)
    }
  }, [gaInit, pathname])

  return children
}

const mapStateToProps = (state) => ({
  cookieConsent: state.settings.cookieConsent
})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GAWithConsent))
