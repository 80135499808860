import React from 'react'
import { Jumbotron } from 'reactstrap'
import { contactMetadata } from 'Modules/ContactModule'
import PropertyValue from 'Components/Personal/Property/PropertyValue'
import VerifyPersonalPhone from 'Components/Personal/Property/VerifyPersonalPhone'
import SendAgain from 'Components/SignUp/SendAgain'

function VerifyProperty ({ property }) {
  if (property.verified) {
    return null
  }
  switch (property.name) {
    case 'emailAddresses':
      return (
        <div className='pt-3'>
          <p>Please click the link in the <strong>verificaiotn email.</strong></p>
          Haven't received the email? <SendAgain email={property.parameters[1].value} />
        </div>
      ) 
    case 'phoneNumbers':
      return <div className='pt-3'><VerifyPersonalPhone phoneObject={property} /></div>
    default:
      return null
  }
}

export default function Verification ({ property }) {
  const meta = { ...contactMetadata[property.name], name: property.name }
  return (
    <Jumbotron>
      <h3>{meta.showName} is{' '}
        {meta.verifiable && !property.verified
          ? <span className='text-danger'>not verified</span>
          : <span className='text-primary'>verified</span>
        }
      </h3>
      <div>
        Details:
        <PropertyValue propertyMeta={meta} value={property} />
      </div>
      <VerifyProperty property={property} />
    </Jumbotron>
  )
}
