import React, { Component } from 'react'
import { Container } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Layout from 'Components/Layout'
import NewBusinessForm from 'Components/Business/NewBusinessForm'
import { create } from 'Actions/BusinessActions'

class New extends Component {
  constructor (props) {
    super(props)

    this.create = this.create.bind(this)
  }

  create (name, domain) {
    return this.props.create(name, domain)
      .then(business => {
        setImmediate(() => this.props.history.push(`/business/${business.objectId}`))
        return business
      })
  }

  render () {
    const { error, loading } = this.props
    console.log('this.props', this.props)
    return (
      <Layout>
        <Helmet>
          <title>Create new business - HeresMyInfo.com</title>
        </Helmet>
        <Container>
          <h1>Create new business</h1>
          <NewBusinessForm create={this.create} error={error} loading={loading} />
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.business.createError,
  loading: state.business.createLoading
})

const mapDispatchToProps = dispatch => ({
  create: (name, domain) => dispatch(create(name, domain))
})

export default connect(mapStateToProps, mapDispatchToProps)(New)
