import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getAllUsers,
  changePage,
  select,
  deleteUsers,
  toggleUser,
  searchUsers
} from 'Actions/AdminUserActions'
import {
  PagingState,
  SelectionState,
  IntegratedSelection,
  CustomPaging,
  DataTypeProvider
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4'
import Loading from '../Loading'
import { Row, Col, CustomInput, Input } from 'reactstrap'
import DeleteResourcesButton from './DeleteResourcesButton'

// Show connections field value
const ConnectionsFormatter = ({ value, row: { objectId } }) => (
  <>
    {value ? (
      <Link to={`/admin/user/${objectId}`}>Connections: {value.length}</Link>
    ) : '-'}
  </>
)

// Show field value linking to user page
const UserLinkFormatter = ({ value, row: { objectId } }) => (
  <Link to={`/admin/user/${objectId}`}>{value}</Link>
)

// Show status field value and switcher
const StatusFormatter = ({ value, row: { objectId }, loading, toggleUser, getAllUsers }) => {
  const active = value !== false // active if value of field is not false (if true or undefined)
  return (
    <CustomInput type='switch'
      id={`switch-${objectId}`}
      label={active ? (
        <span className='text-primary' style={{ cursor: 'pointer' }}>Active</span>
      ) : (
        <span className='text-danger' style={{ cursor: 'pointer' }}>Inactive</span>
      )}
      checked={active}
      onChange={() => {
        toggleUser(objectId, !active).then(() => getAllUsers())
      }}
      disabled={loading}
    />
  )
}

const ConnnectedStatusFormatter = connect(
  state => ({
    loading: state.admin.user.loading
  }),
  dispatch => ({
    toggleUser: (userId, makeActive) => dispatch(toggleUser(userId, makeActive)),
    getAllUsers: () => dispatch(getAllUsers())
  }))(StatusFormatter)

// Main All Users table
export class AllUsers extends Component {
  constructor (props) {
    super(props)

    this.state = {
      columns: [
        { name: 'objectId', title: 'User id' },
        {
          name: 'fullname',
          title: 'Full name',
          getCellValue: this.userFullName
        },
        { name: 'username', title: 'Username' },
        { name: 'createdAt', title: 'Created At' },
        { name: 'connections', title: 'Connections' },
        { name: 'active', title: 'Status' }
      ]
    }

    this.deleteUsers = this.deleteUsers.bind(this)
  }

  componentDidMount () {
    this.props.getAllUsers()
  }

  deleteUsers () {
    return this.props.deleteUsers(this.props.data.selection)
  }

  userFullName (user) {
    if (!user.profile || !user.profile.properties) {
      return ''
    }
    return user.profile.properties
      .filter(property => property.name === 'fn')
      .map(property => property.value)
      .join('; ')
  }

  render () {
    const {
      loading,
      rows,
      page: currentPage,
      perPage: pageSize,
      total,
      selection,
      deleteError,
      search,
      error
    } = this.props.data
    const {
      changeCurrentPage,
      onSelectionChange,
      toggleUser,
      searchUsers,
      toggleError
    } = this.props
    const { columns } = this.state
    return (
      <>
        <Row>
          <Col>
            <h1>List of {loading && !total ? '' : total} users</h1>
          </Col>
          <Col>
            <Input type='text' name='search' id='filterUsers'
              placeholder='Filter users...'
              value={search}
              onChange={e => {
                onSelectionChange([])
                searchUsers(e.target.value)
              }}
            />
          </Col>
        </Row>
        {(error || toggleError) && (
          <Row>
            <Col>
              <p className='text-danger twice'>
                {error && error.message}
                {toggleError && toggleError.message}
              </p>
            </Col>
          </Row>
        )}
        <div className='position-relative'>
          <Grid
            rows={rows}
            columns={columns}
            getRowId={row => row.objectId}
          >
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={changeCurrentPage}
              pageSize={pageSize}
            />
            <SelectionState
              selection={selection}
              onSelectionChange={onSelectionChange}
            />

            <CustomPaging
              totalCount={total}
            />
            <IntegratedSelection />

            <DataTypeProvider
              formatterComponent={ConnectionsFormatter}
              for={['connections']}
            />

            <DataTypeProvider
              formatterComponent={ConnnectedStatusFormatter}
              for={['active']}
              toggleUser={toggleUser}
            />

            <DataTypeProvider
              formatterComponent={UserLinkFormatter}
              for={['objectId']}
            />

            <Table />

            <TableHeaderRow />
            <PagingPanel />
            <TableSelection showSelectAll highlightRow selectByRowClick />
          </Grid>
          {loading && <Loading />}
        </div>
        { selection.length > 0 && (
          <Row>
            <Col>
              <p className='twice'>Selected: {selection.length} row{selection.length !== 1 && 's'}</p>
              <DeleteResourcesButton
                resourceName='users'
                rows={selection}
                deleteResources={this.deleteUsers}
                loading={loading}
                error={deleteError}
              />
            </Col>
          </Row>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.admin.users,
  toggleError: state.admin.user.toggleError
})

const mapDispatchToProps = dispatch => ({
  getAllUsers: () => dispatch(getAllUsers()),
  changeCurrentPage: (currentPage) => dispatch(changePage(currentPage)),
  onSelectionChange: (selection) => dispatch(select(selection)),
  deleteUsers: (users) => dispatch(deleteUsers(users)),
  searchUsers: (search) => dispatch(searchUsers(search))
})

export default connect(mapStateToProps, mapDispatchToProps)(AllUsers)
