import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Landing from 'Pages/Landing'
import BusinessLanding from 'Pages/BusinessLanding'
import Index from 'Pages/Index'
import ResetPassword from 'Pages/Auth/ResetPassword'
import Recovery from 'Pages/Auth/Recovery'
import Login from 'Pages/Auth/Login'
import OAuthSuccess from 'Pages/Auth/OAuthSuccess'
import Logout from 'Pages/Auth/Logout'
import SignUp from 'Pages/Auth/SignUp'
import Learn from './Pages/Learn/Learn'
import Admin from 'Pages/Admin'
import Verified from 'Pages/Verified'
import Business from 'Pages/Business'
import Page404 from 'Pages/Page404'
import withVerification from 'Components/WithVerification'
import CookieConsent from 'Components/General/CookieConsent'
import GAWithConsent from 'Components/General/GAWithConsent'
import VerifyBusinessEmail from 'Pages/Business/Verify'
import Support from 'Pages/Support'
import Personal from 'Pages/Personal'
import QRCode from 'Pages/QRCode'
import Invite from 'Pages/Invite'
import HMIContact from 'Pages/HMIContact'
import VerifyEmail from 'Pages/VerifyEmail'
import Privacy from 'Pages/Static/Privacy'
import FAQ from './Pages/FAQ'
import PersonalPricing from './Pages/Pricing/PersonalPricing'
import BusinessPricing from './Pages/Pricing/BusinessPricing'

class App extends Component {
  
  render () {
    const { isAuthenticated, isAdmin, localHideCookieConsent } = this.props
    return (
      <>
        <Helmet>
          <title>HeresMyInfo.com</title>
        </Helmet>
        <Router>
          <GAWithConsent>
            <Switch>
              <Route path='/' exact component={!isAuthenticated ? Landing : withVerification(Index)} />
              <Route path='/login' exact component={Login} />
              <Route path='/login/oauth' exact component={OAuthSuccess} />
              <Route path='/logout' exact component={Logout} />
              <Route path='/resetPassword' exact component={ResetPassword} />
              <Route path='/recovery' exact component={Recovery} />
              <Route path='/signup' exact component={SignUp} />
              <Route path='/learn' exact component={Learn} />
              <Route path='/verified' component={Verified} />
              <Route path='/privacy' component={Privacy} />
              <Route path='/support' exact component={Support} />
              <Route path='/business/:businessId/verify/:propertyId' component={VerifyBusinessEmail} />
              <Route path='/business' exact component={!isAuthenticated ? BusinessLanding : withVerification(Business)} />
              <Route path='/business' component={withVerification(Business)} />
              <Route path='/personal' component={withVerification(Personal)} />
              <Route path='/qr/:code' component={QRCode} />
              <Route path='/verifyEmail' component={VerifyEmail} />
              <Route path='/hmiconnections/:connectionId' component={HMIContact} />
              <Route path='/invite/:inviteId' component={Invite} />
              <Route path='/faqs' exact component={FAQ} />
              <Route path='/personalpricing' exact component={PersonalPricing} />
              <Route path='/businesspricing' exact component={BusinessPricing} />
              

              {isAdmin && <Route path='/admin' component={Admin} />}
              <Route component={Page404} />
            </Switch>
          </GAWithConsent>
        </Router>
        {!localHideCookieConsent && <CookieConsent />}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.isAuthenticated && state.auth.currentUser && state.auth.currentUser.isAdmin === true,
    localHideCookieConsent: state.settings.localHideCookieConsent
  }
}

export default connect(mapStateToProps)(App)
