import React, { useState } from 'react'
import useTheme from './useTheme'
import { connect } from 'react-redux'
import './themes.css'
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import Switch from 'react-switch'
import PropertyItem from './PropertyItem'
import {
  PROPERTY_NAME,
  PROPERTY_DOMAIN,
  PROPERTY_PHONE,
  PROPERTY_EMAIL,
  BUSINESS_DESCRIPTION,
  PROPERTY_THEME,
  PROPERTY_OPT_IN,
  propTitles
} from 'Constants/Properties'
import Logo from './Logo'
import PreviewUx from './PreviewUx'
import { createProperty, editProperty } from 'Actions/BusinessActions'

const BusinessProperties = ({ business, editProperty, createProperty }) => {
  const optinProp = business.properties.filter(property => property.name === 'businessOptIn')
  const [checked, setChecked] = useState(optinProp && optinProp.length > 0 && optinProp[0].value === 'true')

  const [defaultProps] = useState(
    [
      PROPERTY_NAME,
      PROPERTY_DOMAIN,
      PROPERTY_PHONE,
      PROPERTY_EMAIL,
      BUSINESS_DESCRIPTION,
      PROPERTY_THEME,
      PROPERTY_OPT_IN
    ].map((key) => ({ key, title: propTitles.get(key) }))
  )

  const updateTheme = (value) => {
    const themeProp = business && business.properties ? business.properties.filter((p) => p.name === 'theme') : []
    console.log('themeProp', themeProp)
    console.log('themeProp[0].objectId', themeProp[0].objectId)
    console.log('value', value)
    return editProperty(themeProp[0].objectId, JSON.stringify(value)).then((property) => {
      return property
    })
  }

  const addTheme = (value) => {
    const businessId = business.objectId
    return createProperty(businessId, 'theme', JSON.stringify(value)).then((property) => {
      return property
    })
  }

  let themeOptions
  const themeProp = business.properties.filter(property => property.name === 'theme')
  console.log('themeProp', themeProp)
  if (!themeProp || themeProp.length === 0) {
    themeOptions = { theme: 'HMI', screen: 'Screen1', includes: {} }
    addTheme(themeOptions)
  } else {
    themeOptions = JSON.parse(themeProp[0].value)
  }

  console.log('userTheme', themeOptions.theme)
  useTheme(themeOptions.theme)

  if (!themeOptions.includes) {
    const includes = {}
    themeOptions.includes = includes
  }

  const handleThemeChange = (event) => {
    updateTheme({ ...themeOptions, theme: event.target.value })
  }

  const handleScreenChange = (event) => {
    updateTheme({ ...themeOptions, screen: event.target.value })
  }

  const onChange = (checked) => {
    setChecked(checked)

    const optinProp = business.properties.filter(property => property.name === 'businessOptIn')
    if (!optinProp || optinProp.length === 0) {
      const businessId = business.objectId
      createProperty(businessId, 'businessOptIn', checked.toString()).then((property) => {
      })
    } else {
      editProperty(optinProp[0].objectId, checked.toString()).then((property) => {
      })
    }
  }
  return (
    <>
      <Row>
        <Col lg={6} md={12}>
          <ListGroup>
            <ListGroupItem>
              <div className='d-flex w-100 justify-content-between'>
                <p className='mb-1'>Opt-in to include your business in HMI listing</p>
                <div className='pt-2 text-right'>
                  <Switch
                    onChange={onChange}
                    checked={checked}
                    onColor='#00A956'
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    height={20}
                    width={48}
                    className='react-switch'
                    disabled={false}
                  />
                </div>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <div className=''>
                <p className='mb-1'>Theme</p>
                <div className=''>
                  <div>
                    <select
                      className='form-control themeSelect'
                      id='theme-toggler'
                      defaultValue={themeOptions.theme}
                      onChange={handleThemeChange}
                    >
                      <option value='HMI'>HMI</option>
                      <option value='Dark'>Dark</option>
                      <option value='Light'>Light</option>
                    </select>
                  </div>
                  <div>
                    <select
                      className='form-control themeSelect'
                      id='screen-toggler'
                      defaultValue={themeOptions.screen}
                      onChange={handleScreenChange}
                    >
                      <option value='Screen1'>Screen 1</option>
                      <option value='Screen2'>Screen 2</option>
                      <option value='Screen3'>Screen 3</option>
                    </select>
                  </div>
                </div>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <Logo business={business} />
            </ListGroupItem>
            {defaultProps.map((prop) => (
              <PropertyItem
                propMeta={prop}
                business={business}
                key={prop.key}
              />
            ))}
          </ListGroup>
        </Col>

        <Col lg={6} md={12}>
          <p>
            Preview
          </p>
          <PreviewUx business={business} />
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  createProperty: (businessId, name, value, rest) => dispatch(createProperty(businessId, name, value, rest)),
  editProperty: (id, value, rest) => dispatch(editProperty(id, value, rest))
})

export default connect(mapStateToProps, mapDispatchToProps)(BusinessProperties)
