import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Jumbotron } from 'reactstrap'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Layout from 'Components/Layout'
import Loading from 'Components/Loading'
import { verifyProperty } from 'Actions/BusinessActions'

class Verify extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: null,
      loading: false,
      success: null
    }
  }

  componentDidMount () {
    let qs = queryString.parse(this.props.location.search)
    if (!qs.id) {
      this.setState({ error: 'Verification key not found in the link' })
      return
    }
    this.setState({ loading: true })
    this.props.verifyProperty(this.props.match.params.propertyId, qs.id)
      .then(res => this.setState({ success: res.success }))
      .catch(err => this.setState({ success: false, error: err.message }))
      .finally(() => this.setState({ loading: false }))
  }

  render () {
    const { loading, error, success } = this.state
    const { isAuthenticated } = this.props
    return (
      <Layout>
        <Helmet>
          <title>Email verification - HeresMyInfo.com</title>
        </Helmet>
        <Container>
          {(loading || success === null) ? (<div className='position-relative py-5'><Loading /></div>)
            : error ? (<p>Error while loading. Please try to reload the page</p>) : (
              <Jumbotron>
                { success === true && (
                  <>
                    <h1>Verified successfully!</h1>
                    { isAuthenticated
                      ? <Link to={`/business/${this.props.match.params.businessId}`}>Back to business page</Link>
                      : <p><Link to='/login'>Log In</Link> to see your business record.</p>
                    }
                  </>
                )}
                { success === false && (
                  <>
                    <h1>Verification failed</h1>
                    <p>Try to click the verification link again or repeat the validation{' '}
                      { isAuthenticated && <Link to={`/business/${this.props.match.params.businessId}/status/${this.props.match.params.propertyId}`}>here</Link> }
                    </p>
                  </>
                )}
              </Jumbotron>
            )}
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = dispatch => ({
  verifyProperty: (propertyId, code) => dispatch(verifyProperty(propertyId, code))
})

export default connect(mapStateToProps, mapDispatchToProps)(Verify)
