import React, { useState, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'reactstrap'
import { setProfileProperty } from 'Actions/PersonalActions'
import { sendVerificationEmail } from 'Actions/AuthActions'
import PropertyForm from 'Components/Personal/Property/PropertyForm'
import { contactMetadata } from 'Modules/ContactModule'
import SendAgain from './SendAgain'

const EmailConfirmation = ({ h1, email, setProfileProperty, sendVerificationEmail }) => {
  const [showForm, setShowForm] = useState(false)
  const closeForm = useCallback(() => setShowForm(false), [])
  const onSubmit = useCallback((name, localValue) => setProfileProperty(name, localValue, email.objectId)
    .then(input => {
      sendVerificationEmail(localValue[1].value)
      setShowForm(false)
      return input
    }),
  [setProfileProperty, email, sendVerificationEmail])

  const propertyMeta = useMemo(() => (email && {
    ...contactMetadata[email.name],
    name: email.name
  }), [email])

  if (!email) return null

  return (
    <>
      <Row>
        <Col>
          <h1>{h1}</h1>
          <p>We've sent verificaiton email to{' '}
            <span className='text-monospace px-1' style={{
              backgroundColor: 'rgba(86, 61, 124, 0.15)',
              border: '1px solid rgba(86,61,124,.15)'
            }}>{email.parameters[1].value}</span>
          </p>
          <p>Please check your inbox and click the link in the email</p>
          <p>(Be sure to check your spam or junk folders)</p>
          <p>Haven't received an email? <SendAgain /></p>
          { !showForm && (
            <p>Need to change your email? <Button color='link p-0' onClick={() => setShowForm(true)}>Click here</Button></p>
          )}
        </Col>
      </Row>
      { showForm && (
        <Row>
          <Col md='6'>
            <PropertyForm
              initValue={email.parameters}
              property={propertyMeta}
              onSubmit={onSubmit}
              closeForm={closeForm}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  setProfileProperty: (name, value, id) => dispatch(setProfileProperty(name, value, id)),
  sendVerificationEmail: email => dispatch(sendVerificationEmail(email))
})

export default connect(null, mapDispatchToProps)(EmailConfirmation)
