import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REFETCH_USER_REQUEST,
  REFETCH_USER_SUCCESS,
  REFETCH_USER_FAILURE,
  SEND_VERIFICATION_EMAIL_REQUEST,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  SEND_VERIFICATION_EMAIL_FAILURE,
  CLEAN_ERROR,
  TRIGGER_LOADING
} from 'Actions/AuthActions'

import { PROPERTY_EMAIL, PROPERTY_PHONE } from 'Constants/Properties'

export const initialState = {
  currentUser: null,
  isAuthenticated: false,
  loading: false,
  qrLoading: false,
  defaultCircle: 'Work',
  sortContacts: 'Family Name',
  entryData: null,
  qrCircleName: 'Work',
  verificationEmail: {
    loading: false,
    sent: false,
    error: null
  }
}

const emailAndPhoneVerified = primaryFields => ({
  emailVerified: primaryFields.primaryEmail &&
                  primaryFields.primaryEmail.verified === true,
  phoneVerified: primaryFields.primaryPhone &&
                  primaryFields.primaryPhone.verified === true
})

const getPrimaryFields = user => ({
  primaryEmail: user.profile.properties.find(property =>
    property.name === PROPERTY_EMAIL &&
    property.parameters[1] !== undefined &&
    property.parameters[1].value === user.email
  ),
  primaryPhone: user.profile.properties.find(property =>
    property.name === PROPERTY_PHONE &&
    property.parameters[1] !== undefined &&
    property.parameters[1].value === user.phone
  )
})

const setUser = user => {
  const primaryFields = getPrimaryFields(user)
  return {
    loading: false,
    error: null,
    currentUser: {
      ...user,
      ...primaryFields,
      ...emailAndPhoneVerified(primaryFields)
    },
    isAuthenticated: true
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_LOADING:
      return state.loading ? state : { ...state, loading: true }
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        isAuthenticated: false
      })
    case LOGIN_SUCCESS:
      return Object.assign({}, state, setUser(action.user))
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
        currentUser: null,
        isAuthenticated: false
      })

    case LOGOUT_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case LOGOUT_SUCCESS:
      return Object.assign({}, initialState)
    case LOGOUT_FAILURE:
      return Object.assign({}, initialState)

    case SIGNUP_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        isAuthenticated: false
      })
    case SIGNUP_SUCCESS:
      return Object.assign({}, state, setUser(action.user))
    case SIGNUP_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
        currentUser: null,
        isAuthenticated: false
      })

    case REFETCH_USER_REQUEST:
      return state.loading && state.error === null ? state : {
        ...state,
        loading: true,
        error: null
      }
    case REFETCH_USER_SUCCESS:
      return Object.assign({}, state, setUser(action.user))
    case REFETCH_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        // error: action.error,
        currentUser: null,
        isAuthenticated: false
      })

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return Object.assign({}, state, {
        verificationEmail: {
          loading: true,
          sent: false,
          error: null
        }
      })
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        verificationEmail: {
          loading: false,
          sent: true,
          error: null
        }
      })
    case SEND_VERIFICATION_EMAIL_FAILURE:
      return Object.assign({}, state, {
        verificationEmail: {
          loading: false,
          sent: false,
          error: action.error
        }
      })
    case CLEAN_ERROR:
      return state.error === null ? state : { ...state, error: null }

    default:
      return state
  }
}
