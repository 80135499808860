import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Main from 'Pages/Personal/Main'
import Cards from 'Pages/Personal/Cards'
import OneProperty from 'Pages/Personal/OneProperty'
import QRCodes from 'Pages/Personal/QRCodes'
import DeleteAccount from 'Pages/Personal/DeleteAccount'
import BackupDownload from 'Pages/Personal/BackupDownload'

function Personal () {
  return (
      <>
        <Helmet>
          <title>Personal account - HeresMyInfo.com</title>
        </Helmet>
        <Switch>
          <Route exact path='/personal' component={Main} />
          <Route exact path='/personal/delete' component={DeleteAccount} />
          <Route exact path='/personal/cards' component={Cards} />
          <Route exact path='/personal/qrCodes' component={QRCodes} />
          <Route exact path='/personal/backupDownload' component={BackupDownload} />
          <Route path='/personal/property/:propertyId' component={OneProperty} />
        </Switch>
      </>
  )
}

export default Personal
