import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spinner, Button } from 'reactstrap'
import { initPropertyVerification } from 'Actions/BusinessActions'

export class InitPropertyVerification extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      error: null,
      success: null
    }

    this.onClick = this.onClick.bind(this)
  }

  onClick (e) {
    e.preventDefault()
    this.setState({ loading: true })
    this.props.initPropertyVerification(this.props.propertyId)
      .then(() => this.setState({ success: true }))
      .catch(err => this.setState({ success: false, error: err.message }))
      .finally(() => this.setState({ loading: false }))
  }

  render () {
    const { title } = this.props
    const { loading, success, error } = this.state
    if (loading) {
      return <Spinner type='grow' color='primary' className='py-0' />
    }
    if (success) {
      return <span className='text-primary'>Sent!</span>
    }
    if (error) {
      return <p>Error while sending: {error}</p>
    }
    return (
      <Button color='link' onClick={this.onClick} className='pt-1'>{title}</Button>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  initPropertyVerification: propertyId => dispatch(initPropertyVerification(propertyId))
})

export default connect(mapStateToProps, mapDispatchToProps)(InitPropertyVerification)
