import React from 'react'
import {
  PROPERTY_DOMAIN,
  BUSINESS_DESCRIPTION,
  PROPERTY_THEME,
  PROPERTY_EMAIL,
  PROPERTY_PHONE

} from 'Constants/Properties'

const PreviewUx = ({ business }) => {
  const property = (verify, name) => {
    if (verify) {
      return business.properties.filter(p => p.verified && p.name === name)[0]
    } else {
      return business.properties.filter(p => p.name === name)[0]
    }
  }
  const logo = business.logo ? business.logo.url : ''

  const propDomain = property(true, PROPERTY_DOMAIN)
  // console.log('property(true, PROPERTY_DOMAIN)', property(true, PROPERTY_DOMAIN))
  const domain = propDomain ? propDomain.parameters[1].value : ''
  const domainValue = `https://${domain}`

  const propEmail = property(true, PROPERTY_EMAIL)
  // console.log('property(true, PROPERTY_DOMAIN)', property(true, PROPERTY_DOMAIN))
  const email = propEmail ? propEmail.parameters[1].value : ''
  const emailAddress = 'mailto:' + email

  const propPhone = property(true, PROPERTY_PHONE)
  // console.log('property(true, PROPERTY_DOMAIN)', property(true, PROPERTY_DOMAIN))
  const phone = propPhone ? propPhone.parameters[1].value : ''

  // console.log('domain', domain)
  const description = property(false, BUSINESS_DESCRIPTION) ? property(false, BUSINESS_DESCRIPTION).value : ''

  let theme
  const themeProp = property(false, PROPERTY_THEME)

  if (themeProp) {
    theme = JSON.parse(themeProp.value)
  } else {
    theme = { theme: 'HMI', screen: 'Screen1', includes: {} }
  }

  if (theme.screen === 'Screen1') {
    return (
      <div className='businessPreview'>
        <div className='flex-container'>
          {logo !== '' && (
            <div className='business-img'>
              <img src={logo} alt={business.name} />
            </div>
          )}
          {theme.includes.company && (
            <div className='business-name'>
              <h1>{business.name}</h1>
            </div>
          )}
          {theme.includes.businessDescription && (
            <div className='business-body'>
              <p>{description}</p>
            </div>
          )}
          {domain && theme.includes.urlAddresses && (
            <div className='business-web'>
              <a href={domainValue}>{domain}</a>
            </div>
          )}
          {email && theme.includes.emailAddresses && (
            <div className='business-web'>
              <a href={emailAddress}>{email}</a>
            </div>
          )}

          {phone && theme.includes.phoneNumbers && (
            <div className='business-web'>
              <a href={phone}>{phone}</a>
            </div>
          )}
        </div>
      </div>
    )
  } else if (theme.screen === 'Screen2') {
    return (
      <div className='businessPreview Screen2' style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0.88), rgba(255,255,255,0.88)), url(' + logo + ')', backgroundSize: '200%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
        {/* <div className='businessPreview Screen2'> */}
        <div className='flex-container'>
          <div className='flex-row'>
            {logo !== '' && (
              <div className='business-img'>
                <img src={logo} alt={business.name} />
              </div>
            )}
            {theme.includes.company && (
              <div className='business-name'>
                <h1>{business.name}</h1>
              </div>
            )}
          </div>
          {theme.includes.businessDescription && (
            <div className='business-body'>
              <p>{description}</p>
            </div>
          )}
          {domain && theme.includes.urlAddresses && (
            <div className='business-web'>
              <a href={domainValue}>{domain}</a>
            </div>
          )}
          {email && theme.includes.emailAddresses && (
            <div className='business-web'>
              <a href={emailAddress}>{email}</a>
            </div>
          )}

          {phone && theme.includes.phoneNumbers && (
            <div className='business-web'>
              <a href={phone}>{phone}</a>
            </div>
          )}

        </div>
      </div>
    )
  }
  return (
    <div className='businessPreview'>
      <div className='flex-container'>
        {theme.includes.company && (
          <div className='business-name'>
            <h1>{business.name}</h1>
          </div>
        )}
        {theme.includes.businessDescription && (
          <div className='business-body'>
            <p>{description}</p>
          </div>
        )}
        {domain && theme.includes.urlAddresses && (
          <div className='business-web'>
            <a href={domainValue}>{domain}</a>
          </div>
        )}
        {email && theme.includes.emailAddresses && (
          <div className='business-web'>
            <a href={emailAddress}>{email}</a>
          </div>
        )}

        {phone && theme.includes.phoneNumbers && (
          <div className='business-web'>
            <a href={phone}>{phone}</a>
          </div>
        )}

        {logo !== '' && (
          <div className='business-img'>
            <img src={logo} alt={business.name} />
          </div>
        )}

      </div>
    </div>
  )
}

export default PreviewUx
