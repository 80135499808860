import React, { useState, useEffect, useCallback } from 'react'
import {
  Container, Row, Col, Button, Carousel,
  CarouselItem, CarouselControl
} from 'reactstrap'
import { useContactMetadata } from 'Modules/ContactModule'
import PropertiesList from 'Components/Personal/Property/PropertiesList'
import ProfileListOptions from 'Components/Personal/ProfileListOptions'
// import { cold } from 'react-hot-loader'

export default function ProfileList ({ profile, setIsOpen }) {
  const data = useContactMetadata(profile.properties)
  const [groupIndex, setGroupIndex] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  // const [animating, setAnimating] = useState(false)
  const [isMobile, setIsmobile] = useState(true)

  

  const setActiveState = useCallback(() => {
    console.log('window.outerWidth', window.outerWidth)
    if (window.outerWidth < 800) {
      if (!isMobile) {
        setActiveIndex(0)
      }
      setIsmobile(true)
    } else {
      setIsmobile(false)
      setActiveIndex(0)
    }
  },[isMobile])

  const handleResize = useCallback(() => {
    setActiveState()
  },[setActiveState])

  useEffect(() => {
    window.addEventListener('resize', handleResize, { passive: true })

    setActiveState()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, setActiveState])

  
  const menuItemClick = (e, index) => {
    console.log(index)
    setGroupIndex(index)
    setIsOpen(false)
    if (isMobile) {
      next()
    }
  }

  

  const next = () => {
    console.log('next', 0)
    //   if (animating) return
    setActiveIndex(1)
  }

  const previous = () => {
    console.log('back', 0)
    ///   if (animating) return
    setActiveIndex(0)
  }

  if (isMobile) {
    const items = [{ pane: '1' }, { pane: '2' }]
    const panes = items.map((item) => {
      if (item.pane === '1' && isMobile === true) {
        return (
          <CarouselItem 
          // onExiting={() => setAnimating(false)} 
          // onExited={() => setAnimating(false)} 
          key={item.pane}>
            <ProfileListOptions data={data} groupIndex={groupIndex} handleMenuClick={menuItemClick} />
          </CarouselItem>
        )
      } else {
        return (
          <CarouselItem 
          // onExiting={() => setAnimating(false)} 
          // onExited={() => setAnimating(false)} 
          key={item.pane}>
            <Row>
              <Col xs='12' md='9'>
                <Button color='primary' onClick={previous} style={{ marginBottom: '1rem' }}>Back</Button>
                <PropertiesList profile={profile} groupIndex={groupIndex} />

              </Col>
            </Row>
          </CarouselItem>
        )
      }
    })
    console.log('panes', panes)
    const autochange = false

    return (

      <div>
        <Container className='profilelist fixed' fluid>
          <Row>
            <Col xs='12'>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={autochange}
              >

                {panes}
                <CarouselControl direction='prev' directionText='Previous' onClickHandler={previous} />
                <CarouselControl direction='next' directionText='Next' onClickHandler={next} />
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else {
    return (
      <div>
        <Container className='profilelist fixed' fluid>
          <Row>
            <Col xs='0' md='3' className='mobile'>
              <ProfileListOptions data={data} groupIndex={groupIndex} handleMenuClick={menuItemClick} />
            </Col>
            <Col xs='12' md='9'>
              <PropertiesList profile={profile} groupIndex={groupIndex} />
            </Col>
          </Row>
        </Container>
      </div>)
  }
}
