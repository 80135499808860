import { User, Cloud } from 'Modules/ParseModule'
import { PROPERTY_EMAIL }
  from 'Constants/Properties'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const REFETCH_USER_REQUEST = 'REFETCH_USER_REQUEST'
export const REFETCH_USER_SUCCESS = 'REFETCH_USER_SUCCESS'
export const REFETCH_USER_FAILURE = 'REFETCH_USER_FAILURE'

export const SEND_VERIFICATION_EMAIL_REQUEST = 'SEND_VERIFICATION_EMAIL_REQUEST'
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS'
export const SEND_VERIFICATION_EMAIL_FAILURE = 'SEND_VERIFICATION_EMAIL_FAILURE'

export const CLEAN_ERROR = 'CLEAN_ERROR'

export const TRIGGER_LOADING = 'TRIGGER_LOADING'

export function triggerLoading() {
  return { type: TRIGGER_LOADING }
}

export function loginSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    user
  }
}

export function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error
  }
}

export function cleanError() {
  return { type: CLEAN_ERROR }
}

export const login =  (userInfo) => dispatch => new Promise((resolve, reject) => {
  console.log("LOGIN:", userInfo)
  dispatch({ type: LOGIN_REQUEST })
  const { email, password } = userInfo
  return User.logOut()
    .catch((error) => { console.log("LOGOUT ERROR:", error) })
    .then(() => Cloud.run('anyLogin', { email, password }))
    .then(res => {
      console.log("LOGIN RES:", res.token)
      User.become(res.token)
    })
    .then(user => {
      console.log("USER SUCCESS:", user.toJSON())
      dispatch(loginSuccess(user.toJSON()))
      resolve(user)
    })
    .catch(error => dispatch(loginFailure(error)))
})

export const logout = () => dispatch => {
  dispatch({ type: LOGOUT_REQUEST })
  return User.logOut()
    .then(() => dispatch({ type: LOGOUT_SUCCESS }))
    .catch(error => dispatch({ type: LOGOUT_FAILURE, error }))
}

const getUsername = (userInfo) => userInfo.email

function signUpSuccess(user) {
  return {
    type: SIGNUP_SUCCESS,
    user
  }
}

function signUpFailure(error) {
  return {
    type: SIGNUP_FAILURE,
    error
  }
}

export const signUp = (userInfo) => dispatch => new Promise((resolve, reject) => {
  console.log('signUp userInfo', userInfo)
  dispatch({ type: SIGNUP_REQUEST })
  const username = getUsername(userInfo)
  return User.logOut()
    .catch(() => { })
    .then(() => Cloud.run('userExists', {
      email: userInfo.email
    }))
    .then(response => {
      if (response.resp === true) {
        throw new Error('Account already exists for this email address.')
      }
      if (userInfo.phone) {
        return Cloud.run('userExists', {
          E164: userInfo.phone
        })
      }
      return {}
    })
    .then(response => {
      if (response.resp === true) {
        throw new Error('Account already exists for this phone number.')
      }
    })
    .then(() => Cloud.run('createUserWithProfile', {
      username: username,
      password: userInfo.password,
      email: userInfo.email,
      phone: userInfo.phone,
      givenName: userInfo.firstName,
      familyName: userInfo.lastName,
      delimiter: ' ',
      countryCode: userInfo.countryCode
    }))
    .then((usr) => {
      console.log("SIGNUP USER:", usr)
      User.logIn(username, userInfo.password)
    })
    .then(user => {
      console.log("SIGNUP LOGIN USER:", user)
      user.fetchWithInclude(['profile.properties', 'profile.circles'])
  })
    .then(user => {
      console.log("SIGNUP SUCCESS:", user.toJSON())
      dispatch(signUpSuccess(user.toJSON()))
      // Sending validation email request in background
      Cloud.run('sendValidationFieldMessage', { data: userInfo.email, type: PROPERTY_EMAIL })
      resolve(user)
    })
    .catch(error => {
      dispatch(signUpFailure(error))
      reject(error)
    })
})

function refetchUserSuccess(user) {
  return {
    type: REFETCH_USER_SUCCESS,
    user
  }
}

function refetchUserFailure(error) {
  return {
    type: REFETCH_USER_FAILURE,
    error
  }
}

export const refetchUser = () => dispatch => new Promise((resolve, reject) => {
  if (User.current() === null) {
    resolve(null)
    return
  }
  dispatch({ type: REFETCH_USER_REQUEST })

  User.current().fetchWithInclude(['profile.properties', 'profile.circles'])
    .then(user => {
      dispatch(refetchUserSuccess(user.toJSON()))
      resolve(user)
    })
    .catch(error => {
      if (error.code === 209) {
        User.logOut().catch(() => { })
      }
      dispatch(refetchUserFailure(error))
      reject(error)
    })
})

export const sendVerificationEmail = (email) => dispatch => {
  dispatch({ type: SEND_VERIFICATION_EMAIL_REQUEST })

  return Cloud.run('sendValidationFieldMessage', { data: email, type: PROPERTY_EMAIL })
    .then(() => dispatch({ type: SEND_VERIFICATION_EMAIL_SUCCESS }))
    .catch(error => dispatch({ type: SEND_VERIFICATION_EMAIL_FAILURE, error }))
}

export const initPasswordRecovery = (email) => dispatch => {
  return Cloud.run('initPasswordRecovery', { email })
}

export const finishPasswordRecovery = (messageId, token, newPassword) => dispatch => {
  return Cloud.run('finishPasswordRecovery', { messageId, token, newPassword })
}

export const getParseTokenFromOAuth = () => dispatch => {
  return fetch(`${process.env.REACT_APP_API_URL}/oauth/gettoken`,
    { credentials: 'include', method: 'POST' }
  )
    .then(res => (res.ok ? res : Promise.reject(res)))
    .then(res => res.json())
}

export const setUserFromToken = (token) => dispatch => {
  return User.become(token)
    .then(() => dispatch(refetchUser()))
}

export const initUserDeletion = (email) => dispatch => {
  return Cloud.run('initUserDeletion', { email })
}

export const finishUserDeletion = (messageId, code) => dispatch => {
  return Cloud.run('finishUserDeletion', { messageId, code })
    .then(() => {
      User.logOut().catch(() => { })
      dispatch({ type: LOGOUT_SUCCESS })
    })
}
