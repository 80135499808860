import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendVerificationEmail } from 'Actions/AuthActions'
import { Spinner } from 'reactstrap'

export class SendAgain extends Component {
  render () {
    const { userEmail, sendVerificationEmail, loading, sent, error } = this.props
    if (loading) {
      return <Spinner type='grow' color='primary' />
    }
    if (error) {
      return <p>Error while sending: {error}</p>
    }
    return (
      <>
        {sent && <span className='text-primary'>Sent!{' '}</span>}
        <a href='/' onClick={(e) => {
          e.preventDefault()
          sendVerificationEmail(userEmail)
        }}>Send again</a>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  userEmail: ownProps.email ? ownProps.email : (state.auth.currentUser ? state.auth.currentUser.email : null),
  loading: state.auth.verificationEmail.loading || false,
  sent: state.auth.verificationEmail.sent || false,
  error: state.auth.verificationEmail.error
})

const mapDispatchToProps = dispatch => ({
  sendVerificationEmail: email => dispatch(sendVerificationEmail(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(SendAgain)
