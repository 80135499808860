import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import {
  Container, Row, Col, Label, Input, Form,
  FormGroup, FormFeedback, Button
} from 'reactstrap'
import Layout from 'Components/Layout'
import { finishPasswordRecovery } from 'Actions/AuthActions'

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .min(4, 'Password has to be longer than 4 characters!')
      .required('Password is required!'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords are not the same!')
      .required('Password confirmation is required!')
  }),

  mapPropsToValues: () => ({
    password: '',
    confirmPassword: ''
  }),
  handleSubmit: (payload, { setSubmitting, props, setStatus }) => {
    const { password } = payload
    setStatus({ })
    props.finishPasswordRecovery(password)
      .then(() => {
        setSubmitting(false)
      })
      .catch(e => {
        let err = e.message
        if (err === 'Object not found.') {
          err = 'Wrong token. Please try to click the link from the email again.'
        }
        setStatus({ apiError: err })

        setSubmitting(false)
      })
  },
  displayName: 'SignUpForm'
})

const TextInput = ({ type, id, label, error, value, onChange, className, ...props }) => {
  return (
    <FormGroup>
      <Label for={id}>{label}</Label>
      <Input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
        invalid={!!error}
      />
      <FormFeedback>
        {error}
      </FormFeedback>
    </FormGroup>
  )
}

const RecoveryFormRaw = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    status,
    loading
  } = props

  return (
    <Form className='form' onSubmit={handleSubmit}>
      <Col>
        <TextInput
          id='password'
          type='password'
          label='New password'
          placeholder='Enter new password'
          error={touched.password && errors.password}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Col>

      <Col>
        <TextInput
          id='confirmPassword'
          type='password'
          label='Confirm password'
          placeholder='Repeat new password'
          error={touched.confirmPassword && errors.confirmPassword}
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Col>

      { status && status.apiError && (
        <Col>
          <FormGroup>
            <FormFeedback className='d-block'>{status.apiError}</FormFeedback>
          </FormGroup>
        </Col>
      )}
      <Col className='text-center'>
        <Button color='primary' type='submit' size='lg' disabled={isSubmitting || loading} className='px-5'>Next</Button>
      </Col>
    </Form>
  )
}

const RecoveryForm = formikEnhancer(RecoveryFormRaw)

export class Recovery extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error: null,
      success: false,
      messageId: null,
      token: null
    }

    this.finishPasswordRecovery = this.finishPasswordRecovery.bind(this)
  }

  componentDidMount () {
    let qs = queryString.parse(this.props.location.search)
    if (!qs.id || !qs.token) {
      this.setState({ error: (<p>
        Recovery link is broken.
        Please go to <Link to='/resetPassword'>password recovery</Link> page and try again{' '}
        or contact <Link to='/support'>Support</Link>
      </p>) })
    }
    this.setState({ messageId: qs.id, token: qs.token })
  }

  finishPasswordRecovery (password) {
    const { messageId, token } = this.state
    return this.props.finishPasswordRecovery(messageId, token, password)
      .then((res) => {
        this.setState({ success: true })
        return res
      })
  }

  render () {
    const { success, error } = this.state
    const { isAuthenticated } = this.props

    if (isAuthenticated) return <Redirect to='/' />

    return (
      <Layout>
        <Helmet>
          <title>Reset Password - HeresMyInfo.com</title>
        </Helmet>
        <Container>
          <Row className='py-2'>
            <Col>
              <h2>Reset password</h2>
            </Col>
          </Row>

          {error ? <p>{error}</p> : (<>
            { success && (
              <p>New password set successfully. You can <Link to='/login'>Log in</Link> with new credentials now.</p>
            )}

            { !success && (
              <RecoveryForm finishPasswordRecovery={this.finishPasswordRecovery} />
            )}
          </>)}
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = dispatch => ({
  finishPasswordRecovery: (messageId, token, newPassword) => dispatch(finishPasswordRecovery(messageId, token, newPassword))
})

export default connect(mapStateToProps, mapDispatchToProps)(Recovery)
