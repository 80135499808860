import React from 'react'
import { Col, Toast, ToastBody, ToastHeader } from 'reactstrap'
import "./overview.css"

function Applet({ size, title, children }) {
    const colsize = size ? size : 6;
    return (
        <Col md={colsize} className="Applet">
            <Toast>
                <ToastHeader >
                    {title}
                </ToastHeader>
                <ToastBody>
                    {children}
                </ToastBody>
            </Toast>
        </Col>
    )
}

export default Applet