import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { verifyProperty } from 'Actions/BusinessActions'

export class VerifyDomain extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      error: null
    }

    this.verify = this.verify.bind(this)
  }

  verify () {
    this.setState({ loading: true, error: null })
    this.props.verifyProperty(this.props.property.objectId)
      .then(res => {
        if (!res.success) {
          this.setState({ error: res.error || 'Verification failed', loading: false })
        }
        // If success don't update the state because the component will be unmounted
      })
      .catch(err => this.setState({
        error: err.message || 'Verification failed',
        loading: false
      }))
  }

  render () {
    const { loading, error } = this.state
    const { property } = this.props

    return (
      <>
        <Row>
          <Col>
            <p>To verify domain {property.parameters[1].value} update your DNS records.</p>
            <p>Add <code>TXT</code> record with name <code>{property.meta.verification.name}</code> and value <code>{property.meta.verification.value}</code></p>
            <Button color='primary'
              disabled={loading}
              className='mb-3'
              onClick={this.verify}
            >
              Verify  {loading && <Spinner size='sm' />}
            </Button>
            { error && (
              <>
                <p className='text-danger'>{error}</p>
                <p>DNS could take up to 24-72 hours to update. Please check back later.</p>
              </>
            )}
          </Col>
        </Row>

      </>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => ({
  verifyProperty: (propertyId) => dispatch(verifyProperty(propertyId))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDomain)
