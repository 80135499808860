import React from 'react'
import logo from '../../assets/logo_green_tm.png'
import adminlogo from '../../assets/img/admin.png'
import {
  Navbar,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { connect } from 'react-redux'
import { Link, NavLink, withRouter } from 'react-router-dom'
import Logout from './Logout'
import { setIsOpen } from 'Actions/BusinessActions'
// import { cold } from 'react-hot-loader'

const links = [
  { to: '/', text: 'Home', className: 'nav-link' },
  { to: '/learn', text: 'Learn', className: 'nav-link' },
  { to: '/support', text: 'Support', className: 'nav-link' }
  // { to: '/business', text: 'Business', className: 'nav-link' }
]

const Authlinks = [
  { to: '/', text: 'Dashboard', className: 'nav-link' },
  { to: '/personal', text: 'My Profile', className: 'nav-link' },
  { to: '/personal/cards', text: 'Manage Cards', className: 'nav-link' },
  { to: '/business', text: 'Business Profile', className: 'nav-link' },
  { to: '/personal/qrCodes', text: 'QR Codes', className: 'nav-link' }
  // { to: '/faqs', text: 'FAQs', className: 'nav-link' },
  // { to: '/learn', text: 'Learn', className: 'nav-link' }
  // { to: '/personal/qrCodes', text: 'QR Codes', className: 'nav-link' }
]

const NoAuthlinks = [
  { to: '/login', text: 'Login', className: 'nav-link' },
  // { to: '/signup', text: 'Sign Up', className: 'nav-link' },
  { to: '/faqs', text: 'FAQs', className: 'nav-link' }
]

const createNavItem = ({ to, text, className }) => (
  <NavItem key={to}>
    <NavLink exact to={to} className={className}>{text}</NavLink>
  </NavItem>
)

function Header (props) {
//  const [isOpen, setIsOpen] = useState(false)
  const { isAuthenticated, isAdmin, isOpen, setIsOpen } = props
  console.log('isOpen: ' + isOpen)
  return (

    <Navbar style={{ marginBottom: 0 }} justify='true' expand='lg' sticky='top'>

      <a href='/'><img src={logo} alt='HeresMyInfo' width='200' style={{ marginRight: 25, marginLeft: 8 }} /></a>
      {isAdmin && (
        <Nav className='nav-pills mr-auto'>
          <NavItem>
            <Link to='/admin' className='admin-link'><img src={adminlogo} title='admin mode' alt='admin mode' className='adminLogo' /></Link>
          </NavItem>
        </Nav>
      )}
      {/*
        NavbarToggler and Collapse necessary for display of hamburger menu
      */}
      <NavbarToggler
        className='navbar-light ' onClick={() => setIsOpen(!isOpen)}
      />
      <Collapse isOpen={isOpen} navbar style={{ justifyContent: 'flex-end', color: 'white' }}>

        <Nav navbar id='nav-header' className='nav-pills mr-auto' style={{ lineHeight: '1rem' }}>

          {!isAuthenticated && (
            <>
              {links.map(createNavItem)}
            </>
          )}
          {isAuthenticated && (
            <>
              {Authlinks.map(createNavItem)}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                More
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header>Account</DropdownItem>
                  <DropdownItem>
                    <NavItem>
                      <NavLink exact to='/faqs' className='nav-link'>FAQs</NavLink>
                    </NavItem>
                  </DropdownItem>
                  <DropdownItem>
                    <NavItem>
                      <NavLink exact to='/learn' className='nav-link'>Learn</NavLink>
                    </NavItem>
                  </DropdownItem>
                  <DropdownItem>
                    <NavItem>
                      <NavLink exact to='/resetPassword' className='nav-link'>Reset Password</NavLink>
                    </NavItem>
                  </DropdownItem>
                  <DropdownItem header>Manage</DropdownItem>
                  <DropdownItem>
                    <NavItem>
                      <NavLink exact to='/personal/backupDownload' className='nav-link'>Download Contact Backups</NavLink>
                    </NavItem>
                  </DropdownItem>
                  <DropdownItem>
                    <NavItem>
                      <NavLink exact to='/personal/delete' className='nav-link'>Delete Account</NavLink>
                    </NavItem>
                  </DropdownItem>

                  <DropdownItem divider />
                  <DropdownItem>
                    <NavItem>
                      <Logout className='ml-auto' />
                    </NavItem>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
          {!isAuthenticated && (
            <>
              {NoAuthlinks.map(createNavItem)}
            </>
          )}

        </Nav>
      </Collapse>

    </Navbar>

  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.isAuthenticated && state.auth.currentUser && state.auth.currentUser.isAdmin === true,
    isOpen: state.business.isOpen
  }
}

const mapDispatchToProps = dispatch => ({
  setIsOpen: (toggle) => dispatch(setIsOpen(toggle))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
