import React from 'react'
import {
  Card, CardHeader, CardBody,
  CardTitle, CardFooter, Button
} from 'reactstrap'
import { QRCode as QRCodePNG } from 'react-qrcode-logo'
import QRName from './QRName'
import QRCircle from './QRCircle'
import QRLogo from 'assets/share_green_w_round.png'
import './QRCard.scss'

const downloadQRPNG = (thisQRCode) => {
  const canvas = document.getElementById(thisQRCode).firstElementChild
  const pngUrl = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream')
  let downloadLink = document.createElement('a')
  downloadLink.href = pngUrl
  downloadLink.download = thisQRCode + `.png`
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

const QRCard = ({ item }) => {
  return (
    <div className='qr-card-item align-self-stretch' key={item.QRCode}>
      <Card className='w-100 text-center h-100'>
        <CardHeader><QRCircle item={item} /></CardHeader>

        <CardBody>
          <div id={item.QRCode}>
            <QRCodePNG name={item.QRCode} className='QRCode' value={`https://hmiqr.com/qr/` + item.QRCode} logoImage={QRLogo} />
          </div>
          <CardTitle><QRName item={item} /></CardTitle>
        </CardBody>

        <p>Code: {item.QRCode}</p>

        <CardFooter><Button onClick={() => downloadQRPNG(item.QRCode)} color='primary' style={{ margin: '2px' }}>Download PNG File</Button></CardFooter>
      </Card>
    </div>
  )
}

export default QRCard
