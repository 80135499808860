import React, { useState } from 'react'

import InitDeleteAccount from './InitDeleteAccount'
import FinishDeleteAccount from './FinishDeleteAccount'

export default () => {
  const [init, setInit] = useState(false)
  const [messageId, setMessageId] = useState(null)

  return (
    <div>
      {!init ? (
        <InitDeleteAccount
          onFinish={(res) => {
            setInit(true)
            setMessageId(res.messageId)
          }}
        />
      ) : (
        <FinishDeleteAccount
          messageId={messageId}
        />
      )}

    </div>
  )
}
