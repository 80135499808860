import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setIsOpen } from 'Actions/BusinessActions'
import Header from '../Header'
import { Row, Col, Container } from 'reactstrap'
import { Helmet } from 'react-helmet'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputFocus: false
    }
  }

  SAFE_componentDidMount() {
    this.props.setIsOpen(false)
    document.body.classList.toggle('index-page')
  }

  SAFE_componentWillUnmount() {
    document.body.classList.toggle('index-page')
  }

  render() {
    // console.log('Layout props: ', this.props)
    return (
      <>
        <Header />
        <Helmet>
          <title>HeresMyInfo - Contact Sharing</title>
        </Helmet>
        {/* <Container style={{ border: '2px solid green' }}> */}
        <Container fluid={true}>
          {/* Background CSS can go here */}

          <Row>
            {/* Save for testing borders */}
            {/* style={{ border: '2px solid green' }} */}
            <Col>
                {this.props.children}
            </Col>

          </Row>

        </Container>
        <footer><h6 className='fixed-bottom'>All rights reserved, HeresMyInfo, Inc. 2020-2024</h6></footer>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({
  setIsOpen : () => dispatch(setIsOpen(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)