import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { User } from 'Modules/ParseModule'
import { refetchUser } from 'Actions/AuthActions'
import Loading from 'Components/Loading'

/**
 * On the initial app load check if user is authenticated
 * If user is authenticated - reload user data
 * If not - just render the app
 * BLOCK any further app rendering unless user is reloaded and updated into redux store
 */
function AuthGate ({ children, refetchUser, userLoading }) {
  const [loadedOnce, setLoadedOnce] = useState(false)
  const [loading, setLoading] = useState(() => User.current() !== null)
  useEffect(() => {
    if (User.current() !== null) {
      refetchUser().catch(() => {}).finally(setLoading(false))
    } else {
      setLoadedOnce(true)
    }
  }, [refetchUser])

  // Don't show loading indicator immediatelly to avoid "blinking"
  const [showLoading, setShowLoading] = useState(false)
  useEffect(() => {
    let timeout = setTimeout(() => setShowLoading(true), 50)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  // If user was already loaded once during app render - just render the children, because we need this gate only for initial render
  if (loadedOnce) {
    return children
  }

  // If user loaded and redux state updated
  if (!loading && !userLoading) {
    setLoadedOnce(true)
    return children
  }

  if (showLoading) {
    return <div><Loading /></div>
  }

  // If we are loading - initially just show blank screen, will start showing loading indicator after 50ms
  return null
}

const mapStateToProps = (state) => ({
  userLoading: state.auth.loading
})

const mapDispatchToProps = {
  refetchUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthGate)
