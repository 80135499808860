import { Cloud } from 'Modules/ParseModule'

export const loadQRCodes = () => dispatch => {
  return Cloud.run('getAllQRCodesWithoutUser')
}

export const generateQRCodes = (amount, label) => dispatch => {
  return Cloud.run('generateQRCodes', { amount, label })
    .then(data => data.list.map(code => code.toJSON()))
}
