import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'

import DeleteAccountFlow from './DeleteAccountFlow'

export default () => {
  const [sure, setSure] = useState(false)
  const history = useHistory()

  const handleClickDelete = () => setSure(true)

  const handleClickCancel = () => history.push('/')

  return (
    <div>
      {!sure ? (<>
        <p>
          Are you sure you want to delete your account?
          You will loose all your data and this action is unrecoverable.
          You can't delete your account if you have any businesses.
        </p>
        <Button color='danger' onClick={handleClickDelete}>Delete</Button>
        <Button className='mx-3' onClick={handleClickCancel}>Go back</Button>
      </>) : <DeleteAccountFlow />}

    </div>
  )
}
