import { useEffect } from 'react'

const themes = {
  HMI: {
    '--color-primary': 'white',
    '--color-background': '#00a859'
  },
  Light: {
    '--color-primary': '#282c34',
    '--color-background': 'white'
  },
  Dark: {
    '--color-primary': 'white',
    '--color-background': '#282c34'
  }
}

const useTheme = (selectedTheme) => {
  useEffect(() => {
    if (selectedTheme) {
      try {
        const theme = themes[selectedTheme] || themes.light
        Object.keys(theme).forEach((key) => {
          const value = theme[key]
          document.documentElement.style.setProperty(key, value)
        })
      } catch (error) {
        console.log('error', error)
      }
    }
  }, [selectedTheme])
}

export default useTheme
