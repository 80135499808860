import { useMemo } from 'react'
import { useContactMetadata } from 'Modules/ContactModule'

export const useGroupFlatProperties = (properties) => {
  const propertiesWithValues = useContactMetadata(properties)
  // Reduce propertiesWithValues:
  // 1. Filter out empty groups
  // 2. Extract grouped values into separate items (if we have 3 emails, transforming it into 3 properties)
  return useMemo(() => {
    return propertiesWithValues
      .map(group => {
        let resultProperties = []

        for (let propId in group.properties) {
          const property = group.properties[propId]
          if ((property.multiple && !property.values.length) || (!property.multiple && !property.value)) {
            continue
          }
          if (!property.multiple) {
            resultProperties.push(property)
            continue
          }
          property.values.forEach(value => {
            // Removing 'values' key from the resulting object
            const { values, ...result } = { ...property, value }
            resultProperties.push(result)
          })
        }

        return {
          ...group,
          properties: resultProperties
        }
      })
      .filter(group => group.properties.find(property => property.value))
  }, [propertiesWithValues])
}
