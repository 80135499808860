import { useMemo } from 'react'

export const groupsMetadata = {
  name: {
    showName: "Name",
    multiple: false,
    order: 0,
    editableProfile: true,
    editableNativeGroup: true,
  },
  phoneNumbers: {
    showName: "Phone Number",
    multiple: true,
    order: 1,
    editableProfile: true,
    editableNativeGroup: true,
  },
  onmymind: {
    showName: "On my mind",
    multiple: false,
    order: 2,
    editableProfile: true,
  },
  howisaymyname: {
    showName: "How I say my name",
    multiple: false,
    order: 3,
    editableProfile: true,
  },
  emailAddresses: {
    showName: "E-mail",
    multiple: true,
    order: 4,
    editableProfile: true,
    editableNativeGroup: true,
  },
  postalAddresses: {
    showName: "Address",
    multiple: true,
    order: 5,
    editableProfile: true,
    editableNativeGroup: true,
  },
  // company: { showName: 'Company', order: 6, multiple: false },  // This is a part of nam
  // birthday: {
  //   showName: "Birthday",
  //   multiple: false,
  //   order: 7,
  //   editableProfile: true,
  // },
  // general: { showName: 'General', multiple: true, order: 8, editableProfile: true, editableNativeGroup: true },
  dates: { showName: "Date", multiple: true, order: 9, editableProfile: true },
  urlAddresses: {
    showName: "URL Address",
    multiple: true,
    order: 10,
    editableProfile: true,
    editableNativeGroup: true,
  },
  // payments: { showName: 'Payments', editableProfile: true, description: 'Send and receive payments', order: 11, multiple: false },
  // videoConference: { showName: 'VideoConference', editableProfile: true, description: '', order: 12, multiple: false },
  // social: { showName: 'Social', multiple: false, order: 13, editableProfile: true, editableNativeGroup: false },
  // followme: { showName: 'Follow Me', multiple: false, order: 14, editableProfile: true, editableNativeGroup: false },
  additional: {
    showName: "Additional Fields",
    multiple: false,
    order: 15,
    editableProfile: true,
    editableNativeGroup: false,
  },
};

// for now it handles both contacts-connections and contacts-businesses
// When adding additional fields, add as last entry in object and order
// so as not to change index values
export const contactMetadata = {
  thumbnailImage: {
    types: ["Personal", "Professional", "Personal & Professional"],
    showName: "Photo",
    group: "name",
    vCard: "photo",
  },
  prefix: {
    types: ["Mr.", "Ms.", "Dr."],
    showName: "Name Prefix",
    group: "name",
    vCard: "namePrefix",
  },
  pronoun: {
    types: ["Him/He", "She/Her", "They", "Ze/Zir"],
    showName: "Personal Gender Pronouns",
    group: "additional",
    vCard: "Pronoun",
  },
  sexualOrientation: {
    types: [
      "HeteroSexual",
      "HomoSexual",
      "BiSexual",
      "Gay",
      "Lesbian",
      "Asexual",
    ],
    showName: "Sexual Orientation",
    group: "additional",
    vCard: "sexualOrientation",
  },
  religion: {
    types: [
      "Agnostic",
      "Christian",
      "Catholic",
      "Jewish",
      "Protestant",
      "Muslim",
      "Hindu",
      "Atheist",
      "Buddist",
      "Islamic",
      "Hindu",
      "Pagan",
      "Sikh",
      "Taoist",
    ],
    showName: "Religion",
    group: "additional",
    vCard: "religion",
  },
  political: {
    types: [
      "Apolitical",
      "Democrat",
      "Republican",
      "Libertarian",
      "Green",
      "Independent",
      "Democratic Socialist",
      "Green Party",
    ],
    showName: "Political",
    group: "additional",
    vCard: "political",
  },
  gender: {
    types: [
      "Male",
      "Female",
      "Agender",
      "Androgyne",
      "Bigender",
      "Cisgender",
      "Genderfluid",
      "Gender-nonconforming",
      "Genderqueer",
      "Intergender",
      "Intersex",
      "Omnigender",
      "Non-binary",
      "Questioning",
      "Transsexual",
      "Two-spirit",
    ],
    showName: "Gender",
    group: "additional",
    vCard: "gender",
  },
  astrological: {
    types: [
      "Aquarius",
      "Pisces",
      "Aries",
      "Taurus",
      "Gemini",
      "Cancer",
      "Leo",
      "Virgo",
      "Libra",
      "Scorpio",
      "Sagittarius",
      "Capricorn",
    ],
    showName: "Astrological Sign",
    group: "additional",
    vCard: "astrological",
  },
  chinesezodiac: {
    types: [
      "Rat",
      "Ox",
      "Tiger",
      "Rabbit",
      "Dragon",
      "Snak",
      "Horse",
      "Goat",
      "Monkey",
      "Rooster",
      "Dog",
      "Pig",
    ],
    showName: "Chinese Zodiac",
    group: "additional",
    vCard: "chinesezodiac",
  },
  givenName: {
    types: [],
    showName: "Given Name",
    group: "name",
    editableNativeField: true,
    vCard: "firstName",
  },
  middleName: {
    types: [],
    showName: "Middle Name",
    group: "name",
    editableNativeField: true,
    vCard: "middleName",
  },
  familyName: {
    types: [],
    showName: "Family Name",
    group: "name",
    editableNativeField: true,
    vCard: "lastName",
  },
  nickName: {
    types: [],
    showName: "Nickname",
    group: "name",
    vCard: "nickname",
  },
  emailAddresses: {
    fields: {
      label: {
        types: ["Choose One", "personal", "work", "personal & work"],
        name: "label",
      },
      email: {
        types: [],
        showName: "E-mail",
        name: "email",
      },
      localizedLabel: {
        types: [],
        name: "localizedLabel",
      },
      identifier: {
        types: [],
        name: "identifier",
      },
    },
    showName: "Email Address",
    group: "emailAddresses",
    multiple: true,
    editableNativeField: true,
    vCard: true,
    verifiable: true,
  },
  phoneNumbers: {
    fields: {
      type: {
        types: ["Choose One", "mobile", "landline"],
        showName: "Type",
        name: "type",
      },
      label: {
        types: ["Choose One", "personal", "work", "personal & work"],
        showName: "Label",
        name: "label",
      },
      number: {
        types: [],
        showName: "Number",
        name: "number",
      },
      identifier: {
        showName: null,
        types: [],
        name: "identifier",
      },
      stringValue: {
        types: [],
        showName: null,
        name: "stringValue",
      },
      localizedLabel: {
        types: [],
        showName: null,
        name: "localizedLabel",
      },
      countryCode: {
        showName: "Country",
        types: [],
        name: "countryCode",
      },
      countryISOCode: {
        showName: "CountryISOCode",
        types: [],
        name: "countryISOCode",
      },
      countryName: {
        showName: "CountryName",
        types: [],
        name: "countryName",
      },
      countryFlag: {
        showName: "CountryFlag",
        types: [],
        name: "countryFlag",
      },
    },
    showName: "Phone Number",
    group: "phoneNumbers",
    multiple: true,
    editableNativeField: true,
    vCard: true,
    verifiable: true,
  },
  suffix: {
    types: [],
    showName: "Name Suffix",
    group: "name",
    vCard: "nameSuffix",
  },
  domain: {
    types: [],
    showName: "Domain",
    group: "company",
  },
  onmymind: {
    showName: "On My Mind",
    group: "onmymind",
  },
  vCardVersion: {
    showName: null,
    parseOnly: true,
    vCard: "version",
  },
  objectId: {
    showName: "Parse Server Id",
    group: null,
  },
  company: {
    types: [],
    showName: "Company",
    group: "name",
    editableNativeField: true,
    vCard: "organization",
  },
  department: {
    showName: "Department",
    group: "name",
  },
  imageDataAvailable: {
    types: [],
    showName: "Image Data Available",
    group: null,
  },
  recordID: {
    types: [],
    showName: "Record Id",
    group: null,
    editableNativeField: true,
    vCard: "uid",
  },
  rawContactId: {
    types: [],
    showName: "Raw contact Id",
    group: null,
    editableNativeField: true,
    vCard: "rawContactId",
  },
  ownCircleShared: {},
  note: {
    types: [],
    vCard: "note",
    showName: "Note",
    group: "general",
    nativeConnectionEditableProp: true,
  },
  personalNote: {
    types: [],
    showName: "Remember from",
    group: "general",
    nativeConnectionEditableProp: true,
  },
  jobTitle: {
    showName: "Job Title",
    group: "name",
    editableNativeField: true,
    vCard: "title",
  },
  facebook: {
    types: [],
    showName: "Facebook",
    group: "social",
    editableNativeField: false,
  },
  linkedin: {
    types: [],
    showName: "LinkedIn",
    group: "social",
    editableNativeField: false,
  },
  twitter: {
    types: [],
    showName: "Twitter",
    group: "social",
    editableNativeField: false,
  },
  instagram: {
    types: [],
    showName: "Instagram",
    group: "social",
    editableNativeField: false,
  },
  dates: {
    fields: {
      label: {
        showName: "Label",
        types: ["Choose One", "Anniversary", "Birthday"],
        name: "label",
      },
      day: {
        showName: null,
        types: [],
        name: "day",
      },
      month: {
        showName: null,
        types: [],
        name: "month",
      },
      year: {
        showName: null,
        types: [],
        name: "year",
      },
      fullDate: {
        parseOnly: true,
        showName: "Date",
        types: [],
        name: "fullDate",
      },
      localizedLabel: {
        showName: null,
        types: [],
        name: "localizedLabel",
      },
      identifier: {
        showName: null,
        types: [],
        name: "identifier",
      },
    },
    showName: "Dates",
    group: "dates",
    multiple: true,
    editableNativeField: false,
  },
  general: {
    fields: {
      // label: {
      //  types: [],
      //  showName: 'General'
      // },
      general: {
        // types: [],
        showName: "General",
      },
      localizedLabel: {
        showName: null,
      },
      identifier: {
        showName: null,
      },
    },
    showName: "General",
    group: "general",
    multiple: true,
    editableNativeField: false,
  },
  urlAddresses: {
    fields: {
      label: {
        types: ["Choose One", "Homepage", "Business", "Other"],
        showName: "Label",
        name: "label",
      },
      url: {
        types: [],
        showName: "URL",
        name: "url",
      },
      localizedLabel: {
        showName: null,
        name: "localizedLabel",
      },
      identifier: {
        showName: null,
        name: "identifier",
      },
    },
    showName: "URL Addresses",
    group: "urlAddresses",
    multiple: true,
    editableNativeField: true,
    vCard: true,
  },
  postalAddresses_OLD: {
    fields: {
      label: {
        showName: "Addresses",
        types: ["Choose One", "Home", "Work", "Other"],
      },
      street: {
        showName: "Street",
      },
      city: {
        showName: "City",
      },
      state: {
        showName: "State",
      },
      postCode: {
        showName: "Postal Code",
      },
      country: {
        showName: "Country",
      },
      isoCountryCode: {
        showName: "ISO Country Code",
      },
      localized_label: {
        showName: null,
      },
      mailingAddress: {
        showName: "Mailing Address",
      },
      region: {
        showName: null,
      },
      formattedAddress: {
        showName: null,
      },
      identifier: {
        showName: null,
      },
    },
    showName: "Postal Addresses",
    group: "postalAddresses",
    multiple: true,
    editableNativeField: true,
    vCard: true,
  },
  postalAddresses: {
    fields: {
      label: {
        showName: "Address",
        types: ["Choose One", "Home", "Work", "Other"],
      },
      street_number: {
        showName: "Street Number",
      },
      route: {
        showName: "Street",
      },
      locality: {
        showName: "City",
      },
      administrative_area_level_3: {
        showName: "Township/Village",
      },
      administrative_area_level_2: {
        showName: "County/Region",
      },
      administrative_area_level_1: {
        showName: "State/Province",
      },
      post_code: {
        showName: "Postal Code",
      },
      post_code_suffix: {
        showName: "Postal Code Extension",
      },
      country: {
        showName: "Country",
      },
      isoCountryCode: {
        showName: "ISO Country Code",
      },
      localized_label: {
        showName: "Localized Label",
      },
      mailingAddress: {
        showName: "Mailing Address",
      },
      region: {
        showName: "region",
      },
      formattedAddress: {
        showName: "Formatted Address",
      },
      identifier: {
        showName: "identifier",
      },
    },
    showName: "Postal Addresses",
    group: "postalAddresses",
    multiple: true,
    editableNativeField: true,
    vCard: true,
  },
  templatePath: {
    types: [],
    showName: "Company",
    group: "company",
  },
  skype: {
    types: [],
    showName: "Skype",
    group: "videoConference",
    editableNativeField: false,
  },
  whereby: {
    types: [],
    showName: "Whereby (was Appear.in)",
    group: "videoConference",
    editableNativeField: false,
  },
  zoom: {
    types: [],
    showName: "Zoom",
    group: "videoConference",
    editableNativeField: false,
  },
  gotomeeting: {
    types: [],
    showName: "GoToMeeting",
    group: "videoConference",
    editableNativeField: false,
  },
  gotowebinar: {
    types: [],
    showName: "GoToWebinar",
    group: "videoConference",
    editableNativeField: false,
  },
  howisaymyname: {
    types: [],
    showName: "How I say my name",
    group: "howisaymyname",
    nativeConnectionEditableProp: false,
  },
  venmo: {
    types: [],
    showName: "Venmo",
    group: "payments",
    nativeConnectionEditableProp: false,
  },
  paypal: {
    types: [],
    showName: "PayPal",
    group: "payments",
    nativeConnectionEditableProp: false,
  },
  tiktok: {
    types: [],
    showName: "TikTok",
    group: "followme",
    editableNativeField: false,
  },
  twitch: {
    types: [],
    showName: "Twitch",
    group: "followme",
    editableNativeField: false,
  },
  medium: {
    types: [],
    showName: "Medium",
    group: "followme",
    editableNativeField: false,
  },
  reddit: {
    types: [],
    showName: "Reddit",
    group: "followme",
    editableNativeField: false,
  },
  github: {
    types: [],
    showName: "Github",
    group: "followme",
    editableNativeField: false,
  },
};

export const useContactMetadata = (properties = []) => useMemo(() => {
  // Take groups metadata
  const groups = Object.assign({}, groupsMetadata)
  // Add properties metadata to groups metadata
  for (const name in contactMetadata) {
    const property = { ...contactMetadata[name], name }
    if (property.showName === null || !groups[property.group]) {
      continue
    }

    if (groups[property.group].multiple) {
      property.values = []
    }

    if (!groups[property.group].properties) {
      groups[property.group].properties = {}
    }

    groups[property.group].properties[name] = property
  }

  // Add properties values to GroupsProperties metadata
  properties.forEach(p => {
    const meta = contactMetadata[p.name]
    if (!meta || !groups[meta.group] || !groups[meta.group].properties[p.name]) {
      return p
    }

    if (groups[meta.group].multiple) {
      groups[meta.group].properties[p.name].values.push(p)
    } else {
      groups[meta.group].properties[p.name].value = p
    }
  })

  // @todo you can hide/expose properties groups using this array
  const hiddenGroups = ['social', 'company', 'business', 'videoConference', 'cloudstorage']

  return Object.entries(groups)
    .map(el => ({ ...el[1], name: el[0] }))
    .filter(el => el.showName !== '')
    .filter(el => !hiddenGroups.includes(el.name))
    .sort((e1, e2) => e1.order - e2.order)
}, [properties])
